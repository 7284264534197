//lib
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import { Button, Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import StoreListTablePatientHistory from "./StoreListTablePatientHistory";
import { useStyles } from "../../../../../components/globalStyles";
import PageOnlyBackAction from "../../../../../components/PageOnlyBackAction";
//style//style
export default function PatientHistoryDialog({
  staffId,
  copyableMode = false,
  handleCopyOnClick = () => null,
  openPatientHistory = false,
  setOpenPatientHistory = () => null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item>
      <Link className={classes.boldMaroon075}>
        <Button
          className={classes.boldMaroon075}
          onClick={() => setOpenPatientHistory(true)}>
          {t("translation:VISIT HISTORY")}
        </Button>
      </Link>

      <Dialog
        open={openPatientHistory}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setOpenPatientHistory(false);
          }
        }}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="xl">
        <Close
          style={{ position: "absolute", right: "15px" }}
          onClick={() => setOpenPatientHistory(false)}
        />
        <DialogContent>
          <StoreListTablePatientHistory
            staffId={staffId}
            copyableMode={copyableMode}
            handleCopyOnClick={handleCopyOnClick}
          />
        </DialogContent>
        <DialogActions>
          <PageOnlyBackAction
            setView={() => {
              setOpenPatientHistory(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
