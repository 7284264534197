import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getGender,
  getMarital,
  getNationality,
  getRace,
  getPermission,
  getViewNeeds,
  getRight,
  getLanguage,
  getMedicineConsumeTime,
  getMedicineMeasureUnit,
  getGeneralStatus,
  getWaterAmount,
  getPaymentMethod,
  getPaymentTerm,
  getUserCreditType,
  getInOutType,
  getSystemSettings,
  getMfaType,
  getWorkOrderType,
  getPostalCodeDistrict,
} from "../actions/commonActions";
import * as types from "../_constants/commonConstants";
import * as notification from "../_constants/notificationConstants";
import commonApis from "../_apis/commonApis";
export default function* commonSagas() {
  yield all([
    takeLatest(types.GET_GENDER_ASYNC.PENDING, getGenderCall),
    takeLatest(types.GET_MARITAL_ASYNC.PENDING, getMaritalCall),
    takeLatest(types.GET_NATIONALITY_ASYNC.PENDING, getNationalityCall),
    takeLatest(types.GET_RACE_ASYNC.PENDING, getRaceCall),
    takeLatest(types.GET_PREMISSION_ASYNC.PENDING, getPermissionCall),
    takeLatest(types.GET_VIEWNEEDS_ASYNC.PENDING, getViewNeedsCall),
    takeLatest(types.GET_RIGHTS_ASYNC.PENDING, getRightCall),
    takeLatest(types.GET_LANGUAGE_ASYNC.PENDING, getLanguageCall),
    takeLatest(
      types.GET_MEDICINECONSUMETIME_ASYNC.PENDING,
      getMedicineConsumeTimeCall
    ),
    takeLatest(
      types.GET_MEDICINEMEASUREUNIT_ASYNC.PENDING,
      getMedicineMeasureUnitCall
    ),
    takeLatest(types.GET_GENERALSTATUS_ASYNC.PENDING, getGeneralStatusCall),
    takeLatest(types.GET_WATERAMOUNT_ASYNC.PENDING, getWaterAmountCall),
    takeLatest(types.GET_PAYMENTMETHOD_ASYNC.PENDING, getPaymentMethodCall),
    takeLatest(types.GET_PAYMENTTERM_ASYNC.PENDING, getPaymentTermCall),
    takeLatest(types.GET_USERCREDITTYPE_ASYNC.PENDING, getUserCreditTypeCall),
    takeLatest(types.GET_INOUTTYPE_ASYNC.PENDING, getInOutTypeCall),
    takeLatest(types.GET_SYSTEMSETTINGS_ASYNC.PENDING, getSystemSettingsCall),
    takeLatest(types.GET_MFATYPE_ASYNC.PENDING, getMfaTypeCall),
    takeLatest(types.GET_WORKORDERTYPE_ASYNC.PENDING, getWorkOrderTypeCall),
    takeLatest(
      types.GET_POSTALCODEDISTRICT_ASYNC.PENDING,
      getPostalCodeDistrictCall
    ),
  ]);
}

function* getGenderCall() {
  try {
    const data = yield call(commonApis.getGenderApi);
    yield put(getGender.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getGender.error(error.response?.data?.message));
    else yield put(getGender.error(notification.CONST_NOINTERNET));
  }
}

function* getMaritalCall() {
  try {
    const data = yield call(commonApis.getMaritalApi);
    yield put(getMarital.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMarital.error(error.response?.data?.message));
    else yield put(getMarital.error(notification.CONST_NOINTERNET));
  }
}

function* getNationalityCall() {
  try {
    const data = yield call(commonApis.getNationalityApi);
    yield put(getNationality.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getNationality.error(error.response?.data?.message));
    else yield put(getNationality.error(notification.CONST_NOINTERNET));
  }
}

function* getRaceCall() {
  try {
    const data = yield call(commonApis.getRaceApi);
    yield put(getRace.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRace.error(error.response?.data?.message));
    else yield put(getRace.error(notification.CONST_NOINTERNET));
  }
}

function* getRightCall() {
  try {
    const data = yield call(commonApis.getRightApi);
    yield put(getRight.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getRight.error(error.response?.data?.message));
    else yield put(getRight.error(notification.CONST_NOINTERNET));
  }
}

function* getPermissionCall() {
  try {
    const data = yield call(commonApis.getPermissionApi);
    yield put(getPermission.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPermission.error(error.response?.data?.message));
    else yield put(getPermission.error(notification.CONST_NOINTERNET));
  }
}

function* getViewNeedsCall() {
  try {
    const data = yield call(commonApis.getViewNeedsApi);
    yield put(getViewNeeds.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getViewNeeds.error(error.response?.data?.message));
    else yield put(getViewNeeds.error(notification.CONST_NOINTERNET));
  }
}

function* getLanguageCall() {
  try {
    const data = yield call(commonApis.getLanguageApi);
    yield put(getLanguage.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getLanguage.error(error.response?.data?.message));
    else yield put(getLanguage.error(notification.CONST_NOINTERNET));
  }
}

function* getMedicineConsumeTimeCall() {
  try {
    const data = yield call(commonApis.getMedicineConsumeTimeApi);
    yield put(getMedicineConsumeTime.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMedicineConsumeTime.error(error.response?.data?.message));
    else yield put(getMedicineConsumeTime.error(notification.CONST_NOINTERNET));
  }
}

function* getMedicineMeasureUnitCall() {
  try {
    const data = yield call(commonApis.getMedicineMeasureUnitApi);
    yield put(getMedicineMeasureUnit.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMedicineMeasureUnit.error(error.response?.data?.message));
    else yield put(getMedicineMeasureUnit.error(notification.CONST_NOINTERNET));
  }
}

function* getGeneralStatusCall() {
  try {
    const data = yield call(commonApis.getGeneralStatusApi);
    yield put(getGeneralStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getGeneralStatus.error(error.response?.data?.message));
    else yield put(getGeneralStatus.error(notification.CONST_NOINTERNET));
  }
}

function* getWaterAmountCall() {
  try {
    const data = yield call(commonApis.getWaterAmountApi);
    yield put(getWaterAmount.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWaterAmount.error(error.response?.data?.message));
    else yield put(getWaterAmount.error(notification.CONST_NOINTERNET));
  }
}

function* getPaymentMethodCall() {
  try {
    const data = yield call(commonApis.getPaymentMethodApi);
    yield put(getPaymentMethod.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPaymentMethod.error(error.response?.data?.message));
    else yield put(getPaymentMethod.error(notification.CONST_NOINTERNET));
  }
}

function* getPaymentTermCall() {
  try {
    const data = yield call(commonApis.getPaymentTermApi);
    yield put(getPaymentTerm.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPaymentTerm.error(error.response?.data?.message));
    else yield put(getPaymentTerm.error(notification.CONST_NOINTERNET));
  }
}

function* getUserCreditTypeCall() {
  try {
    const data = yield call(commonApis.getUserCreditTypeApi);
    yield put(getUserCreditType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getUserCreditType.error(error.response?.data?.message));
    else yield put(getUserCreditType.error(notification.CONST_NOINTERNET));
  }
}

function* getInOutTypeCall() {
  try {
    const data = yield call(commonApis.getInOutTypeApi);
    yield put(getInOutType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInOutType.error(error.response?.data?.message));
    else yield put(getInOutType.error(notification.CONST_NOINTERNET));
  }
}

function* getSystemSettingsCall(model) {
  try {
    const data = yield call(commonApis.getSystemSettingsApi, model.data);
    yield put(getSystemSettings.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getSystemSettings.error(error.response?.data?.message));
    if (error.response.status === 404)
      yield put(getSystemSettings.error(error.response?.data?.message));
    else yield put(getSystemSettings.error(notification.CONST_NOINTERNET));
  }
}

function* getMfaTypeCall(model) {
  try {
    const data = yield call(commonApis.getMfaTypeApi, model.data);
    yield put(getMfaType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getMfaType.error(error.response?.data?.message));
    if (error.response.status === 404)
      yield put(getMfaType.error(error.response?.data?.message));
    else yield put(getMfaType.error(notification.CONST_NOINTERNET));
  }
}

function* getWorkOrderTypeCall(model) {
  try {
    const data = yield call(commonApis.getWorkOrderTypeApi, model.data);
    yield put(getWorkOrderType.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getWorkOrderType.error(error.response?.data?.message));
    if (error.response.status === 404)
      yield put(getWorkOrderType.error(error.response?.data?.message));
    else yield put(getWorkOrderType.error(notification.CONST_NOINTERNET));
  }
}

function* getPostalCodeDistrictCall(model) {
  try {
    const data = yield call(commonApis.getPostalCodeDistrictApi, model.data);
    yield put(getPostalCodeDistrict.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getPostalCodeDistrict.error(error.response?.data?.message));
    if (error.response.status === 404)
      yield put(getPostalCodeDistrict.error(error.response?.data?.message));
    else yield put(getPostalCodeDistrict.error(notification.CONST_NOINTERNET));
  }
}
