//lib
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { Font } from "@react-pdf/renderer";
import { Button, Grid, Link } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
// import IconButton from "@mui/material/IconButton";
// import Alert from "@mui/material/Alert";
// import CloseIcon from "@mui/icons-material/Close";
//action

import { putVisitPrintDate } from "../../../actions/visitOrderActions";

import { getMedicineMeasureUnit } from "../../../actions/commonActions";
//import { getOrganisationLogo } from "../../../actions/curUserActions";
import { postCreatePresignedUrlOrganisationLogo } from "../../../actions/cloudStorageActions";
//components
import StyledCheckbox from "../../../components/StyledCheckbox";
import Panel from "../../../components/Panel";
import { PatientInfoTable } from "./PatientInfoTable";
import ViewMedicineListTable from "../../workOrder/Store/ViewMedicineListTable";
import * as generalConstants from "../../../_constants/generalConstants";
import Note from "../../workOrder/Store/components/addOrder/Note";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import ViewAllianceTotalPrice from "../../workOrder/Store/components/viewOrder/ViewAllianceTotalPrice";
import ViewBrewing from "../../workOrder/Store/components/viewOrder/ViewBrewing";
import ViewDelivery from "../../workOrder/Store/components/viewOrder/ViewDelivery";
import GroupOption from "../../workOrder/Store/components/editOrder/GroupOption";
import GroupTotal from "../../workOrder/Store/components/addOrder/GroupTotal";
import GroupMedicineInfo from "../../workOrder/Store/components/addOrder/GroupMedicineInfo";
import AcupunctureGroupOption from "./components/editOrder/AcupunctureGroupOption";
import AcupointListTable from "./components/editOrder/AcupointListTable";
import GroupAcupunctureInfo from "./components/editOrder/GroupAcupunctureInfo";
import TreatmentListTable from "./components/editOrder/TreatmentListTable";
import PaymentTotal from "./components/editOrder/PaymentTotal";
import MiscListTable from "./components/editOrder/MiscListTable";
import PaymentTable from "./components/editOrder/PaymentTable";
import EditVisitNotesDetails from "./components/editOrder/EditVisitNotesDetails";
import EditMcIssued from "./components/editOrder/EditMcIssued";
import EditMdIssued from "./components/editOrder/EditMdIssued";
import RequiredNote from "../../../components/RequiredNote";
import CopyAction from "./components/viewOrder/CopyAction";
import { useStyles } from "../../../components/globalStyles";
import VisitMcPDF from "../../../components/PDF/VisitMcPDF";
import VisitMdPDF from "../../../components/PDF/VisitMdPDF";
import EditVisitNotesDetailsDialog from "./components/editOrder/EditVisitNotesDetailsDialog";
import PatientPrescriptionPDF from "../../../components/PDF/PatientPrescriptionPDF";
//import SubmissionNotificationDialog from "../../../components/SubmissionNotificationDialog";
import PatientHistoryDialog from "./components/viewOrder/PatientHistoryDialog";
import DownloadVisitAttachment from "./components/editOrder/DownloadVisitAttachment";
import EditVisitAttachment from "./components/editOrder/EditVisitAttachment";
import ViewSharedDelivery from "../../workOrder/Store/components/viewOrder/ViewSharedDelivery";
import S3OrganisationLogoDialog, {
  setPrintingOrganisationLogo,
} from "../../../components/functions/S3OrganisationLogoDialog";
import EditVisitAcupointDialog from "./components/editOrder/EditVisitAcupointDialog";
import ViewProceedDay from "../../workOrder/Store//components/viewOrder/ViewProceedDay";

//style
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    "& > span": {
      width: "100%",
      backgroundColor: "rgba(240,240,240)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(240,240,240)",
    backgroundColor: "rgba(155,29,32,1)",
    borderRadius: "5px!important",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "rgba(240,240,240)",
      opacity: 1,
    },
    "&$selected": {
      color: "rgba(240,240,240)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(240,240,240)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const OrderDetailPage = ({
  setOrderDetailView,
  orderDetailInfo = {},
  setOrderDetailInfo = () => null,
  historyMode = false,
  copyableMode = false,
  editVisitNoteView = false,
  editVisitNoteViewOnly = false,
  handleCopyOnClick = () => null,
  visitNotesDetailsDialog = [],
  setVisitNotesDetailsDialog = () => null,
  uploadFileObjectsDialog = [],
  setUploadFileObjectsDialog = () => null,
  editVisitAcupointView = false,
  editVisitAcupointViewOnly = false,
  //edit acupuncturegroup when detail
  visitAcupointDialog = [],
  setVisitAcupointDialog = () => null,
}) => {
  //variables

  const prevSubmitWorkOrderStatus = useRef();
  const prevSaveWorkOrderStatus = useRef();
  const prevSetPrintDateOnStatus = useRef();
  const common = useSelector((state) => state.common);
  const visitOrder = useSelector((state) => state.visitOrder);
  const curUser = useSelector((state) => state.curUser);
  const userAuth = useSelector((state) => state.userAuth);
  const [showDelivery, setShowDelivery] = useState(true);
  const [showBrewing, setShowBrewing] = useState(true);
  const [isRawHerb, setIsRawHerb] = useState(false);
  const [editDiagnosisOpen, setEditDiagnosisOpen] = useState(false);
  const [editAcupointDiagnosisOpen, setEditAcupointDiagnosisOpen] =
    useState(false);
  const [openPatientHistory, setOpenPatientHistory] = useState(false);
  const [TCMLogo, setTCMLOGO] = useState(null);
  // const [logoError, setLogoError] = useState(false);
  // const [logoErrorMsg, setLogoErrorMsg] = useState(null);
  const [print, setPrint] = React.useState(0);
  const [printMc, setPrintMc] = React.useState(0);
  const [printMd, setPrintMd] = React.useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateOrderAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  //dont display deleted group as default
  const defaultMedicineGroup = orderDetailInfo.store_medicine_headers?.find(
    (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
  );
  const defaultAcupunctureGroup =
    orderDetailInfo.visit_acupuncture_headers?.find(
      (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
    );

  const defaultVisitNoteDetails = orderDetailInfo.visit_notes_details?.find(
    (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
  );

  const [price, setPrice] = useState({
    deliveryFee: orderDetailInfo.delivery_total_price_for_costing
      ? orderDetailInfo.delivery_total_price_for_costing
      : 0,
    brewingFee: orderDetailInfo.brewing_total_price_for_costing
      ? orderDetailInfo.brewing_total_price_for_costing
      : 0,
    consultationFee: orderDetailInfo.consultation_total_price
      ? orderDetailInfo.consultation_total_price
      : 0,
    medicineTab: orderDetailInfo.medicine_total_price
      ? orderDetailInfo.medicine_total_price
      : 0,
    medicineFee: orderDetailInfo.medicine_price_for_costing
      ? orderDetailInfo.medicine_price_for_costing
      : 0,
    medicineGroupTotal: defaultMedicineGroup ? defaultMedicineGroup.price : 0,
    acupuncturePrice: orderDetailInfo.acupuncture_total_price
      ? orderDetailInfo.acupuncture_total_price
      : 0,
    treatmentPrice: orderDetailInfo.treatment_total_price
      ? orderDetailInfo.treatment_total_price
      : 0,
    miscPrice: orderDetailInfo.misc_total_price
      ? orderDetailInfo.misc_total_price
      : 0,
  });

  const [discount, setDiscount] = useState({
    deliveryFee: orderDetailInfo.delivery_discount_amt
      ? orderDetailInfo.delivery_discount_amt
      : 0,
    brewingFee: orderDetailInfo.brewing_discount_amt
      ? orderDetailInfo.brewing_discount_amt
      : 0,
    consultationFee: orderDetailInfo.consultation_discount_amt
      ? orderDetailInfo.consultation_discount_amt
      : 0,
    medicineTab: orderDetailInfo.medicine_discount_amt
      ? orderDetailInfo.medicine_discount_amt
      : 0,
    acupuncturePrice: orderDetailInfo.acupuncture_discount_amt
      ? orderDetailInfo.acupuncture_discount_amt
      : 0,
    treatmentPrice: orderDetailInfo.treatment_discount_amt
      ? orderDetailInfo.treatment_discount_amt
      : 0,
    miscPrice: orderDetailInfo.misc_discount_amt
      ? orderDetailInfo.misc_discount_amt
      : 0,
  });

  const [extraAmt, setExtraAmt] = useState({
    deliveryFee: orderDetailInfo.delivery_extra_amt
      ? orderDetailInfo.delivery_extra_amt
      : 0,
    brewingFee: orderDetailInfo.brewing_extra_amt
      ? orderDetailInfo.brewing_extra_amt
      : 0,
    consultationFee: orderDetailInfo.consultation_extra_amt
      ? orderDetailInfo.consultation_extra_amt
      : 0,
    medicineTab: orderDetailInfo.medicine_extra_amt
      ? orderDetailInfo.medicine_extra_amt
      : 0,
    acupuncturePrice: orderDetailInfo.acupuncture_extra_amt
      ? orderDetailInfo.acupuncture_extra_amt
      : 0,
  });

  const [payment, setPayment] = useState({
    discountInPrecent: orderDetailInfo.discount_in_percent,
    paymentTerm: orderDetailInfo.payment_term,
    paymentMethod: orderDetailInfo.payment_method,
    referral_code: orderDetailInfo.referral_code,
  });

  const [visitNotesDetails, setVisitNotesDetails] = useState({
    id: defaultVisitNoteDetails ? defaultVisitNoteDetails.id : null,
    is_medical_diagnosis_issued: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.is_medical_diagnosis_issued
      : null,
    medical_diagnosis_reason: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.medical_diagnosis_reason
      : null,
    is_mc_issued: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.is_mc_issued
      : null,
    mc_number_of_days: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.mc_number_of_days
      : null,
    mc_reason: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.mc_reason
      : null,
    name: defaultVisitNoteDetails ? defaultVisitNoteDetails.name : null,
    physician_advice: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.physician_advice
      : null,
    physician_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.physician_diagnosis
      : null,
    pulse_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.pulse_diagnosis
      : null,
    remarks: defaultVisitNoteDetails ? defaultVisitNoteDetails.remarks : null,
    review_notes: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.review_notes
      : null,
    status_type: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.status_type
      : null,
    tongue_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.tongue_diagnosis
      : null,
    western_diagnosis: defaultVisitNoteDetails
      ? defaultVisitNoteDetails.western_diagnosis
      : null,
    others: defaultVisitNoteDetails ? defaultVisitNoteDetails.others : null,
    syndrome: defaultVisitNoteDetails ? defaultVisitNoteDetails.syndrome : null,
  });

  const [amountHeader, setAmountHeader] = useState({
    amt_after_discount: orderDetailInfo.amt_after_discount
      ? orderDetailInfo.amt_after_discount
      : 0,
    amt_before_discount: orderDetailInfo.amt_before_discount
      ? orderDetailInfo.amt_before_discount
      : 0,
    amt_with_gst: orderDetailInfo.amt_with_gst
      ? orderDetailInfo.amt_with_gst
      : 0,
    amt_wo_gst: orderDetailInfo.amt_wo_gst ? orderDetailInfo.amt_wo_gst : 0,
    gst_amt: orderDetailInfo.gst_amt ? orderDetailInfo.gst_amt : 0,
    amt_rounding: orderDetailInfo.amt_rounding
      ? orderDetailInfo.amt_rounding
      : 0,
  });

  const [tabValue, setTabValues] = useState("0");
  const [group, setGroup] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.name : null
  );
  const [groupId, setGroupId] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.id : null
  );

  const [groupOption, setGroupOption] = useState(
    orderDetailInfo.store_medicine_headers
  );

  const [costingHeader, setCostingHeader] = React.useState(
    orderDetailInfo.store_medicine_costing_headers
  );

  const [medicineList, setMedicineList] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.store_medicine_details : []
  );

  const [acupunctureGroup, setAcupunctureGroup] = useState(
    defaultAcupunctureGroup ? defaultAcupunctureGroup.name : ""
  );
  const [acupunctureGroupId, setAcupunctureGroupId] = useState(
    defaultAcupunctureGroup ? defaultAcupunctureGroup.id : null
  );
  const [acupunctureGroupOption, setAcupunctureGroupOption] = useState(
    orderDetailInfo.visit_acupuncture_headers
  );
  const [acupointList, setAcupointList] = useState(
    defaultAcupunctureGroup
      ? defaultAcupunctureGroup.visit_acupuncture_details
      : []
  );

  const [treatmentList, setTreatmentList] = useState(
    orderDetailInfo ? orderDetailInfo.visit_treatment_details : []
  );

  const [miscList, setMiscList] = useState(
    orderDetailInfo ? orderDetailInfo.visit_misc_details : []
  );

  const [uploadFileObjects, setUploadFileObjects] = React.useState(
    orderDetailInfo.visit_attachments.map((item) => {
      return { ...item, file: { name: item.original_filename } };
    })
  );
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);

  const [allowPrint, setAllowPrint] = useState(false);

  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);
  useEffect(() => {
    loadFonts();
  }, [loadFonts]);

  //function

  //set table data
  const setAcupointsData = (rowIndex, columnID, value) => {
    const elementsIndex = memoSelectedAcupunctureGroupIndex;
    //make a copy of group option
    let oldArray = [...visitAcupointDialog];
    let visitAcupunctureDetail =
      oldArray[elementsIndex].visit_acupuncture_details;
    if (columnID === "delete") {
      let result = [...visitAcupunctureDetail];

      const currentAcupointWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentAcupointVerified = currentAcupointWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentAcupointVerified) {
        const currentAcupointIndex = result.findIndex(
          (e) => e.id === currentAcupointVerified.id
        );
        result[currentAcupointIndex] = {
          ...currentAcupointVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddAcupointVerified = currentAcupointWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.acupoint_name !== newAddAcupointVerified.acupoint_name
        );
      }

      const checkActiveAcupoints = result.some(
        (e) => e.status_type === generalConstants.APPROVED_STATUS_TYPE
      );

      let resultWDelete = result.filter(
        (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
      );
      let resultWoDelete = result.filter(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      resultWDelete = resultWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      resultWoDelete = resultWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      if (checkActiveAcupoints) {
        oldArray[elementsIndex] = {
          ...oldArray[elementsIndex],
          visit_acupuncture_details: [...resultWoDelete, ...resultWDelete],
        };
      } else {
        oldArray[elementsIndex] = {
          ...oldArray[elementsIndex],
          visit_acupuncture_details: [...resultWoDelete, ...resultWDelete],
          price: null,
        };
      }

      setVisitAcupointDialog(oldArray);
    } else if (columnID === "up") {
      //check whether is a current existing medicine

      let currentWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex - 1]] = [
          currentWoDelete[currentIndex - 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified - 1]] =
          [
            currentWoDelete[newAddVerified - 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        visit_acupuncture_details: [...currentWoDelete, ...currentWDelete],
      };
      setVisitAcupointDialog(oldArray);
    } else if (columnID === "down") {
      //check whether is a current existing medicine

      let currentWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentWDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentVerified = currentWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentVerified) {
        const currentIndex = currentWoDelete.findIndex(
          (e) => e.id === currentVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [currentWoDelete[currentIndex], currentWoDelete[currentIndex + 1]] = [
          currentWoDelete[currentIndex + 1],
          currentWoDelete[currentIndex],
        ];
      } else {
        const newAddVerified = currentWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [currentWoDelete[newAddVerified], currentWoDelete[newAddVerified + 1]] =
          [
            currentWoDelete[newAddVerified + 1],
            currentWoDelete[newAddVerified],
          ];
      }
      currentWDelete = currentWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentWoDelete = currentWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        visit_acupuncture_details: [...currentWoDelete, ...currentWDelete],
      };
      setVisitAcupointDialog(oldArray);
    } else {
      const currentAcupointWoDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );
      const selectedAcupoint = currentAcupointWoDelete.map((item, index) => {
        if (index === rowIndex && columnID !== "delete") {
          return {
            ...item,
            [columnID]: value,
          };
        }
        return item;
      });

      const currentAcupointWDelete = visitAcupunctureDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      //need to merge back delete acupooint in order to update
      const mergeSelectedWDeletedAcupoint = [
        ...currentAcupointWDelete,
        ...selectedAcupoint,
      ];

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        visit_acupuncture_details: mergeSelectedWDeletedAcupoint,
      };

      setVisitAcupointDialog(oldArray);
    }
  };

  //set table data
  const setTreatmentData = (rowIndex, columnID, value) => {
    let visitTreatmenteDetail = [...treatmentList];

    if (columnID === "delete") {
      let result = [...visitTreatmenteDetail];
      //check whether is a current existing medicine

      const currentTreatmentneWoDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentTreatmentVerified = currentTreatmentneWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentTreatmentVerified) {
        const currentTreatmentIndex = result.findIndex(
          (e) => e.id === currentTreatmentVerified.id
        );
        result[currentTreatmentIndex] = {
          ...currentTreatmentVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddTreatmentVerified = currentTreatmentneWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.treatment_name !== newAddTreatmentVerified.treatment_name
        );
      }

      setTreatmentList(result);
    } else {
      const currentTreatmentWoDelete = visitTreatmenteDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );
      const selectedVisitTreatment = currentTreatmentWoDelete.map(
        (item, index) => {
          if (index === rowIndex && columnID !== "delete") {
            return {
              ...item,
              [columnID]: value,
            };
          }
          return item;
        }
      );
      setTreatmentList(selectedVisitTreatment);
    }
  };

  //set table data
  const setMiscData = (rowIndex, columnID, value) => {
    let visitMisceDetail = [...miscList];

    if (columnID === "delete") {
      let result = [...visitMisceDetail];
      //check whether is a current existing medicine

      const currentMiscneWoDelete = visitMisceDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentMiscVerified = currentMiscneWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMiscVerified) {
        const currentMiscIndex = result.findIndex(
          (e) => e.id === currentMiscVerified.id
        );
        result[currentMiscIndex] = {
          ...currentMiscVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddMiscVerified = currentMiscneWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter((e) => e.name !== newAddMiscVerified.name);
      }

      setMiscList(result);
    } else {
      const currentMiscWoDelete = visitMisceDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );
      const selectedVisitMisc = currentMiscWoDelete.map((item, index) => {
        if (index === rowIndex && columnID !== "delete") {
          return {
            ...item,
            [columnID]: value,
          };
        }
        return item;
      });
      setMiscList(selectedVisitMisc);
    }
  };

  //set table data

  const setPaymentData = (id, value) => {
    setDiscount((p) => ({
      ...p,
      [id]: value,
    }));
  };

  const handleAcupunctureNumInfo = (event, value) => {
    const elementsIndex = memoSelectedAcupunctureGroupIndex;
    //make a copy of group option
    let newArray = [...memoAcupunctureGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setAcupunctureGroupOption(newArray);
  };

  const handleMedicineGroupInfo = (event, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? event.type === "blur"
          ? event.target.value.trim()
          : event.target.value
        : null,
    };

    setGroupOption(newArray);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValues(newTabValue);
  };

  const handleMedicineGroupNumInfo = (event, value) => {
    const elementsIndex = groupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
    //make a copy of group option
    let newArray = [...groupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setGroupOption(newArray);
  };

  const setPrintingLogo = () => {
    setTCMLOGO(null);
    // const organisationId = orderDetailInfo.organisation.id;

    // dispatch(getOrganisationLogo.pending(organisationId));
    //dispatch(getOrganisation.pending(organisationId));

    setPrint(new Date().valueOf());
    setAllowPrint(false);
    const submitJson = setPrintingOrganisationLogo(
      orderDetailInfo.organisation.codename,
      "organisation",
      orderDetailInfo.organisation.logo
    );
    dispatch(postCreatePresignedUrlOrganisationLogo.pending(submitJson));
  };

  const setPrintingDate = () => {
    dispatch(
      putVisitPrintDate.pending({
        store_header: { id: orderDetailInfo.id },
      })
    );
  };
  //useMemo
  const groupOptionAll = () => {
    return groupOption;
  };

  const memoGroupOption = React.useMemo(groupOptionAll, [groupOption]);

  const selectedGroup = () => {
    return memoGroupOption.find(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroup = React.useMemo(selectedGroup, [
    memoGroupOption,
    group,
    groupId,
  ]);

  const selectedGroupIndex = () => {
    return memoGroupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroupIndex = React.useMemo(selectedGroupIndex, [
    memoGroupOption,
    group,
    groupId,
  ]);

  const acupunctureGroupOptionAll = () => {
    if (editVisitAcupointView) {
      return visitAcupointDialog;
    }
    return acupunctureGroupOption;
  };

  const memoAcupunctureGroupOption = React.useMemo(acupunctureGroupOptionAll, [
    acupunctureGroupOption,
    visitAcupointDialog,
    editVisitAcupointView,
  ]);

  const selectedAcupunctureGroupIndex = () => {
    return memoAcupunctureGroupOption.findIndex(
      (item) => item.name === acupunctureGroup && item.id === acupunctureGroupId
    );
  };

  const memoSelectedAcupunctureGroupIndex = React.useMemo(
    selectedAcupunctureGroupIndex,
    [memoAcupunctureGroupOption, acupunctureGroup, acupunctureGroupId]
  );

  const selectedAcupunctureGroup = () => {
    return memoAcupunctureGroupOption.find(
      (item) => item.name === acupunctureGroup && item.id === acupunctureGroupId
    );
  };

  const memoSelectedAcupunctureGroup = React.useMemo(selectedAcupunctureGroup, [
    memoAcupunctureGroupOption,
    acupunctureGroup,
    acupunctureGroupId,
  ]);

  const treatmentWODelete = () => {
    //filter deleted treatment
    return treatmentList.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
  };
  const memoTreatmentWODelete = React.useMemo(treatmentWODelete, [
    treatmentList,
  ]);

  const miscWODelete = () => {
    return miscList.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
  };
  const memoMiscWODelete = React.useMemo(miscWODelete, [miscList]);
  //useEffects
  useEffect(() => {
    prevSubmitWorkOrderStatus.current = false;
    prevSaveWorkOrderStatus.current = false;
    prevSetPrintDateOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      visitOrder.isLoadingPutVisitPrintDate !== prevSetPrintDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintDateOnStatus.current = visitOrder.isLoadingPutVisitPrintDate;

      if (
        visitOrder.putVisitPrintDateSuccess === true &&
        visitOrder.isLoadingPutVisitPrintDate === false
      ) {
        setAllowPrint(true);
        //setOrderDetailInfo(visitOrder.putVisitPrintDate.store_header);
      }
    }
  }, [visitOrder, setOrderDetailInfo]);

  useEffect(() => {
    if (allowPrint) {
      setOrderDetailInfo(visitOrder.putVisitPrintDate.store_header);
    }
  }, [visitOrder.putVisitPrintDate, allowPrint, setOrderDetailInfo]);

  useEffect(() => {
    if (!common.medicineMeasureUnitList && !common.isLoadingMedicineMeasureUnit)
      dispatch(getMedicineMeasureUnit.pending());
  }, [
    dispatch,
    common.medicineMeasureUnitList,
    common.isLoadingMedicineMeasureUnit,
  ]);

  // useEffect(() => {
  //   if (curUser.OrganisationLogo) {
  //     setTCMLOGO(curUser.OrganisationLogo.data);
  //   } else if (curUser.error?.organisationLogoError) {
  //     setLogoError(true);
  //     if (
  //       curUser.error?.organisationLogoError === "Missing Organisation Logo"
  //     ) {
  //       setLogoErrorMsg(t("translation:Missing Organisation Logo"));
  //     } else {
  //       setLogoErrorMsg(curUser.error?.organisationLogoError);
  //     }
  //   } else {
  //     setTCMLOGO(null);
  //   }
  // }, [curUser, t]);

  // useEffect(() => {
  //   if (
  //     visitOrder.isLoadingSaveStoreOrder !== prevSaveWorkOrderStatus.current
  //   ) {
  //     //check the previous ref with current state
  //     prevSaveWorkOrderStatus.current = visitOrder.isLoadingSaveStoreOrder;

  //     if (
  //       visitOrder.postVisitSaveStoreOrderIsSuccess === true &&
  //       visitOrder.isLoadingSaveStoreOrder === false
  //     ) {
  //       setSaveSuccessAlarm(true);
  //       setSaveErrorAlarm(false);
  //       setOrderSaveDetailInfo(visitOrder.saveStoreOrder.store_header);
  //       setOrderSaveDetailView(true);
  //       window.scrollTo(0, 0);
  //     }
  //     if (
  //       visitOrder.postVisitSaveStoreOrderIsSuccess === false &&
  //       visitOrder.isLoadingSaveStoreOrder === false
  //     ) {
  //       setSaveSuccessAlarm(false);
  //       setSaveErrorAlarm(true);
  //       setMsgAlarm(visitOrder.error.saveStoreOrderError);
  //     }
  //   } else {
  //     setSaveErrorAlarm(false);
  //     setSaveSuccessAlarm(false);
  //   }
  // }, [
  //   visitOrder.postVisitSaveStoreOrderIsSuccess,
  //   visitOrder.isLoadingSaveStoreOrder,
  //   visitOrder.error,
  //   visitOrder.saveStoreOrder,
  // ]);

  // useEffect(() => {
  //   if (visitOrder.currentOrganistion) {
  //     const currentOrganistion = visitOrder.currentOrganistion.organisation;

  //     //should not count deleted group
  //     const temAcupuncturePrice = memoAcupunctureGroupOption.reduce(
  //       (sum, li) => {
  //         if (
  //           li.status_type === generalConstants.APPROVED_STATUS_TYPE &&
  //           li.visit_acupuncture_details &&
  //           Object.keys(li.visit_acupuncture_details).length !== 0 &&
  //           li.visit_acupuncture_details.some(
  //             (item) =>
  //               item.status_type === generalConstants.APPROVED_STATUS_TYPE
  //           )
  //         ) {
  //           let temSubTotalRound = organisationPriceRounding(
  //             li.price,
  //             currentOrganistion.med_details_price_rmt,
  //             currentOrganistion.med_details_price_rpvt
  //           );

  //           return Number(sum) + Number(temSubTotalRound);
  //         } else {
  //           return Number(sum);
  //         }
  //       },
  //       0
  //     );
  //     const roundingTemAcupuncturePrice = organisationPriceRounding(
  //       Number(temAcupuncturePrice),
  //       currentOrganistion.order_cat_amt_rmt,
  //       currentOrganistion.order_cat_amt_rpvt
  //     );
  //     setPrice((p) => ({
  //       ...p,
  //       acupuncturePrice: roundingTemAcupuncturePrice,
  //     }));
  //   }
  // }, [memoAcupunctureGroupOption, visitOrder.currentOrganistion]);

  // useEffect(() => {
  //   //filter deleted treatment
  //   if (visitOrder.currentOrganistion) {
  //     const currentOrganistion = visitOrder.currentOrganistion.organisation;
  //     const temTreatmentPrice = memoTreatmentWODelete.reduce((sum, li) => {
  //       let temSubTotalRound = organisationPriceRounding(
  //         li.quantity * li.unit_selling_price,
  //         currentOrganistion.med_details_price_rmt,
  //         currentOrganistion.med_details_price_rpvt
  //       );

  //       return Number(sum) + Number(temSubTotalRound);
  //     }, 0);
  //     const roundingtemTreatmentPrice = organisationPriceRounding(
  //       Number(temTreatmentPrice),
  //       currentOrganistion.order_cat_amt_rmt,
  //       currentOrganistion.order_cat_amt_rpvt
  //     );
  //     setPrice((p) => ({
  //       ...p,
  //       treatmentPrice: roundingtemTreatmentPrice,
  //     }));
  //   }
  // }, [memoTreatmentWODelete, visitOrder.currentOrganistion]);

  // useEffect(() => {
  //   //filter deleted misc
  //   if (visitOrder.currentOrganistion) {
  //     const currentOrganistion = visitOrder.currentOrganistion.organisation;
  //     const temMiscPrice = memoMiscWODelete.reduce((sum, li) => {
  //       let temSubTotalRound = organisationPriceRounding(
  //         li.quantity * li.unit_selling_price,
  //         currentOrganistion.med_details_price_rmt,
  //         currentOrganistion.med_details_price_rpvt
  //       );

  //       return Number(sum) + Number(temSubTotalRound);
  //     }, 0);
  //     const roundingtemTreatmentPrice = organisationPriceRounding(
  //       Number(temMiscPrice),
  //       currentOrganistion.order_cat_amt_rmt,
  //       currentOrganistion.order_cat_amt_rpvt
  //     );
  //     setPrice((p) => ({
  //       ...p,
  //       miscPrice: roundingtemTreatmentPrice,
  //     }));
  //   }
  // }, [memoMiscWODelete, visitOrder.currentOrganistion]);

  // useEffect(() => {
  //   if (visitOrder.currentOrganistion) {
  //     const totalFee =
  //       Number(price.deliveryFee) +
  //       Number(price.brewingFee) +
  //       Number(price.consultationFee) +
  //       Number(price.medicineTab) +
  //       Number(price.acupuncturePrice) +
  //       Number(price.treatmentPrice) +
  //       Number(price.miscPrice);

  //     const totalDiscount =
  //       Number(discount.deliveryFee) +
  //       Number(discount.brewingFee) +
  //       Number(discount.consultationFee) +
  //       Number(discount.medicineTab) +
  //       Number(discount.acupuncturePrice) +
  //       Number(discount.treatmentPrice) +
  //       Number(discount.miscPrice);

  //     const priceWOdiscount = Number(totalFee) - Number(totalDiscount);
  //     const currentOrganistion = visitOrder.currentOrganistion.organisation;
  //     const amountHeader = organisationPriceRoundingWGst(
  //       Number(priceWOdiscount),

  //       currentOrganistion,
  //       Number(payment.discountInPrecent)
  //     );

  //     setAmountHeader(amountHeader);
  //   }
  // }, [price, discount, payment, visitOrder.currentOrganistion]);

  return (
    <>
      {/* <SubmissionNotificationDialog open={logoError && print ? true : false}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setLogoError(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {logoErrorMsg}
        </Alert>
      </SubmissionNotificationDialog> */}

      <S3OrganisationLogoDialog
        organisationCode={orderDetailInfo.organisation.codename}
        folderPath="organisation"
        setPhotoURL={setTCMLOGO}
      />
      <Grid className={classes.gridRoot} container>
        <Grid item xs={6}>
          <Typography display="block" variant="h4">
            {orderDetailInfo.order_number}
          </Typography>
        </Grid>
        {!editVisitAcupointView && !editVisitNoteView && !historyMode && (
          <Grid item xs={6}>
            <PageOnlyBackAction setView={() => setOrderDetailView(false)} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography display="block" variant="h4">
            {orderDetailInfo.physician_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Panel>
            <PatientInfoTable
              name={orderDetailInfo.staff_name}
              staff_contact_number={orderDetailInfo.staff_contact_number}
              date_of_birth={orderDetailInfo.staff_date_of_birth}
              gender_type={orderDetailInfo.staff_gender_type}
              marital_type={orderDetailInfo.staff_marital_type}
              occupation={orderDetailInfo.staff_occupation}
              medical_history={orderDetailInfo.staff_medical_history}
              allergy={orderDetailInfo.staff_allergy}
              t={t}
            />
          </Panel>
        </Grid>
        <br />
        {editVisitNoteView && (
          <PatientHistoryDialog
            staffId={orderDetailInfo.staff.id}
            openPatientHistory={openPatientHistory}
            setOpenPatientHistory={setOpenPatientHistory}
            copyableMode={false}
          />
        )}
        {!editVisitAcupointView &&
          !editVisitNoteView &&
          !copyableMode &&
          !historyMode && (
            <Grid item xs={12}>
              {visitNotesDetails.is_mc_issued && (
                <Link
                  onClick={() => {
                    setPrintMc(new Date().valueOf());
                  }}
                  className={classes.boldMaroon075}>
                  <Button className={classes.boldMaroon075}>
                    {t("translation:PRINT MC")}
                  </Button>
                  {printMc > 0 && (
                    <VisitMcPDF
                      t={t}
                      printMc={printMc}
                      key={printMc}
                      orderDetailInfo={orderDetailInfo}
                      visitNotesDetails={
                        editVisitNoteView
                          ? visitNotesDetailsDialog
                          : visitNotesDetails
                      }
                      organisationInfo={curUser.organisationInfo.user_organisations.find(
                        (item) =>
                          item.organisations.id ===
                          orderDetailInfo.organisation.id
                      )}
                    />
                  )}
                </Link>
              )}

              {visitNotesDetails.is_medical_diagnosis_issued && (
                <Link
                  onClick={() => {
                    setPrintMd(new Date().valueOf());
                  }}
                  className={classes.boldMaroon075}>
                  <Button className={classes.boldMaroon075}>
                    {t("translation:PRINT MEDICAL DIAGNOSIS")}
                  </Button>
                  {printMd > 0 && (
                    <VisitMdPDF
                      t={t}
                      printMd={printMd}
                      key={printMd}
                      orderDetailInfo={orderDetailInfo}
                      visitNotesDetails={
                        editVisitNoteView
                          ? visitNotesDetailsDialog
                          : visitNotesDetails
                      }
                      organisationInfo={curUser.organisationInfo.user_organisations.find(
                        (item) =>
                          item.organisations.id ===
                          orderDetailInfo.organisation.id
                      )}
                    />
                  )}
                </Link>
              )}
            </Grid>
          )}

        {copyableMode && historyMode && (
          <Grid item xs={12}>
            <CopyAction
              handleCopyOnClick={handleCopyOnClick}
              orderDetailInfo={orderDetailInfo}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {!editVisitAcupointView &&
            !editVisitNoteView &&
            !copyableMode &&
            !historyMode &&
            userAuth.systemUserInfo.id === orderDetailInfo.physician.id &&
            updateOrderAccess && (
              <>
                <Link className={classes.boldMaroon075}>
                  <Button
                    className={classes.boldMaroon075}
                    onClick={() => setEditDiagnosisOpen(true)}>
                    {t("translation:EDIT DIAGNOSIS")}
                  </Button>
                </Link>
                {editDiagnosisOpen && (
                  <EditVisitNotesDetailsDialog
                    setEditDiagnosisOpen={setEditDiagnosisOpen}
                    editDiagnosisOpen={editDiagnosisOpen}
                    visitNotesDetails={visitNotesDetails}
                    setVisitNotesDetails={setVisitNotesDetails}
                    storeHeaderId={orderDetailInfo.id}
                    setOrderDetailView={setOrderDetailView}
                    orderDetailInfo={orderDetailInfo}
                    setOrderDetailInfo={setOrderDetailInfo}
                    uploadFileObjects={uploadFileObjects}
                    setUploadFileObjects={setUploadFileObjects}
                  />
                )}
              </>
            )}

          {!editVisitAcupointView &&
            !editVisitNoteView &&
            !copyableMode &&
            !historyMode &&
            userAuth.systemUserInfo.id === orderDetailInfo.physician.id &&
            updateOrderAccess && (
              <>
                <Link className={classes.boldMaroon075}>
                  <Button
                    className={classes.boldMaroon075}
                    onClick={() => setEditAcupointDiagnosisOpen(true)}>
                    {t("translation:EDIT ACUPOINTS")}
                  </Button>
                </Link>
                {editAcupointDiagnosisOpen && (
                  <EditVisitAcupointDialog
                    setEditDiagnosisOpen={setEditAcupointDiagnosisOpen}
                    editDiagnosisOpen={editAcupointDiagnosisOpen}
                    storeHeaderId={orderDetailInfo.id}
                    setOrderDetailView={setOrderDetailView}
                    orderDetailInfo={orderDetailInfo}
                    setOrderDetailInfo={setOrderDetailInfo}
                    visitAcupoint={acupunctureGroupOption}
                    setVisitAcupoint={setAcupunctureGroupOption}
                  />
                )}
              </>
            )}
        </Grid>
        <Grid item xs={3}>
          <Panel>
            <EditVisitNotesDetails
              visitNotesDetails={
                editVisitNoteView ? visitNotesDetailsDialog : visitNotesDetails
              }
              setVisitNotesDetails={
                editVisitNoteView
                  ? setVisitNotesDetailsDialog
                  : setVisitNotesDetails
              }
              viewOnly={editVisitNoteViewOnly ? false : true}
            />
            <EditMcIssued
              visitNotesDetails={
                editVisitNoteView ? visitNotesDetailsDialog : visitNotesDetails
              }
              setVisitNotesDetails={
                editVisitNoteView
                  ? setVisitNotesDetailsDialog
                  : setVisitNotesDetails
              }
              viewOnly={editVisitNoteViewOnly ? false : true}
            />
            <EditMdIssued
              visitNotesDetails={
                editVisitNoteView ? visitNotesDetailsDialog : visitNotesDetails
              }
              setVisitNotesDetails={
                editVisitNoteView
                  ? setVisitNotesDetailsDialog
                  : setVisitNotesDetails
              }
              viewOnly={editVisitNoteViewOnly ? false : true}
            />
            {editVisitNoteViewOnly && (
              <EditVisitAttachment
                organisationCode={orderDetailInfo.organisation.codename}
                folderPath={"visit_attachment"}
                uploadFileObjects={
                  editVisitNoteView
                    ? uploadFileObjectsDialog
                    : uploadFileObjects
                }
                setUploadFileObjects={
                  editVisitNoteView
                    ? setUploadFileObjectsDialog
                    : setUploadFileObjects
                }
              />
            )}
            <DownloadVisitAttachment
              organisationCode={orderDetailInfo.organisation.codename}
              folderPath={"visit_attachment"}
              uploadFileObjects={
                editVisitNoteView ? uploadFileObjectsDialog : uploadFileObjects
              }
            />
          </Panel>
        </Grid>

        <Grid item xs={9}>
          <TabContext value={tabValue}>
            <Grid item xs={12}>
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth">
                <StyledTab
                  className={classes.defualtCursor}
                  value="0"
                  label={
                    <span>
                      <Typography display="block" variant="subtitle1">
                        {t("translation:MEDICINE")}
                      </Typography>

                      <Typography display="block" variant="subtitle1">
                        $ {price.medicineTab}
                      </Typography>
                    </span>
                  }></StyledTab>

                <StyledTab
                  value="1"
                  label={
                    <span>
                      <Typography display="block" variant="subtitle1">
                        {t("translation:ACUPUNCTURE")}
                      </Typography>

                      <Typography display="block" variant="subtitle1">
                        $ {price.acupuncturePrice}
                      </Typography>
                    </span>
                  }
                />
                <StyledTab
                  value="2"
                  label={
                    <span>
                      <Typography display="block" variant="subtitle1">
                        {t("translation:TREATMENT")}
                      </Typography>

                      <Typography display="block" variant="subtitle1">
                        $ {price.treatmentPrice}
                      </Typography>
                    </span>
                  }
                />
                <StyledTab
                  value="3"
                  label={
                    <span>
                      <Typography display="block" variant="subtitle1">
                        {t("translation:PAYMENT")}
                      </Typography>

                      <Typography display="block" variant="subtitle1">
                        $ {amountHeader.amt_with_gst}
                      </Typography>
                    </span>
                  }
                />
              </StyledTabs>
            </Grid>

            <TabPanel className={classes.gridRoot} value="0">
              <Panel>
                <GroupOption
                  viewOnly={true}
                  memoGroupOption={memoGroupOption}
                  groupOption={groupOption}
                  setGroupOption={setGroupOption}
                  group={group}
                  setGroup={setGroup}
                  memoSelectedGroupIndex={memoSelectedGroupIndex}
                  medicineList={medicineList}
                  setMedicineList={setMedicineList}
                  memoSelectedGroup={memoSelectedGroup}
                  setShowBrewing={setShowBrewing}
                  setShowDelivery={setShowDelivery}
                  setAmountHeader={setAmountHeader}
                  setPrice={setPrice}
                  showDelivery={showDelivery}
                  showBrewing={showBrewing}
                  setIsRawHerb={setIsRawHerb}
                  setGroupId={setGroupId}
                  groupId={groupId}
                  order={visitOrder}
                />
              </Panel>
              {Object.keys(medicineList).length !== 0 &&
                common.medicineMeasureUnitList && (
                  <div>
                    <Panel>
                      <Grid container>
                        {orderDetailInfo.status_type !==
                          generalConstants.NEW_STATUS_TYPE && (
                          <Link
                            onClick={() => {
                              setPrintingLogo();
                            }}
                            className={classes.boldMaroon075}>
                            <Button className={classes.boldMaroon075}>
                              {t("translation:PRINT PRESCRIPTION")}
                            </Button>

                            {pdfFontLoaded &&
                              TCMLogo &&
                              print !== 0 &&
                              common.medicineMeasureUnitList && (
                                <PatientPrescriptionPDF
                                  storeMedicineHeader={memoSelectedGroup}
                                  storeHeader={orderDetailInfo}
                                  t={t}
                                  measureUnit={
                                    common.medicineMeasureUnitList.measure_units
                                  }
                                  TCMLogo={TCMLogo}
                                  print={print}
                                  setPrintingDate={setPrintingDate}
                                  allowPrint={allowPrint}
                                  setAllowPrint={setAllowPrint}
                                />
                              )}
                          </Link>
                        )}
                        <Grid item xs={12} className={classes.userInfoField}>
                          <ViewMedicineListTable
                            t={t}
                            med_details_price_rmt={
                              orderDetailInfo.med_details_price_rmt
                            }
                            med_details_price_rpvt={
                              orderDetailInfo.med_details_price_rpvt
                            }
                            data={medicineList.sort(
                              (a, b) => a.row_no - b.row_no
                            )}
                            isRawHerb={isRawHerb}
                            costingHeader={costingHeader}
                            memoSelectedGroup={memoSelectedGroup}
                            measureUnit={
                              common.medicineMeasureUnitList.measure_units
                            }
                            infoModule="VISIT"
                          />
                        </Grid>

                        <GroupMedicineInfo
                          viewOnly={true}
                          memoGroupOption={memoGroupOption}
                          handleMedicineGroupNumInfo={
                            handleMedicineGroupNumInfo
                          }
                          handleMedicineGroupInfo={handleMedicineGroupInfo}
                          memoSelectedGroupIndex={memoSelectedGroupIndex}
                          memoSelectedGroup={memoSelectedGroup}
                          setGroupOption={setGroupOption}
                        />
                      </Grid>
                      <GroupTotal
                        viewOnly={true}
                        handleMedicineGroupNumInfo={handleMedicineGroupNumInfo}
                        price={price}
                        memoSelectedGroup={memoSelectedGroup}
                        showMultiplier={true}
                      />
                    </Panel>
                    {(memoSelectedGroup.is_brewing_services ||
                      memoSelectedGroup.is_delivery_services ||
                      memoSelectedGroup.delivery_incl_prescription ||
                      memoSelectedGroup.delivery_incl_receipt ||
                      memoSelectedGroup.delivery_owned?.status_type ===
                        generalConstants.APPROVED_STATUS_TYPE) && (
                      <Panel>
                        {memoSelectedGroup.delivery_incl_prescription && (
                          <Grid item xs={12} className={classes.userInfoField}>
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  checked={
                                    memoSelectedGroup.delivery_incl_prescription
                                      ? true
                                      : false
                                  }
                                  disabled
                                  name="delivery_incl_prescription"
                                  cy_data="deliveryInclPrescription"
                                />
                              }
                              label={t(
                                "translation:Delivery Include Prescription"
                              )}
                            />
                          </Grid>
                        )}
                        {memoSelectedGroup.delivery_incl_receipt && (
                          <Grid item xs={12} className={classes.userInfoField}>
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  checked={
                                    memoSelectedGroup.delivery_incl_receipt
                                      ? true
                                      : false
                                  }
                                  disabled
                                  name="delivery_incl_receipt"
                                  cy_data="deliveryInclInvoice"
                                />
                              }
                              label={t("translation:Delivery Include Invoice")}
                            />
                          </Grid>
                        )}
                        {memoSelectedGroup.is_brewing_services && (
                          <ViewBrewing memoSelectedGroup={memoSelectedGroup} />
                        )}
                        {memoSelectedGroup.is_delivery_services && (
                          <ViewDelivery memoSelectedGroup={memoSelectedGroup} />
                        )}

                        {memoSelectedGroup.delivery_owned?.status_type ===
                          generalConstants.APPROVED_STATUS_TYPE && (
                          <ViewSharedDelivery
                            memoSelectedGroup={memoSelectedGroup}
                            viemOnly={true}
                          />
                        )}
                      </Panel>
                    )}
                  </div>
                )}
              {memoSelectedGroup?.proceed_on !== null && (
                <Panel>
                  <ViewProceedDay memoSelectedGroup={memoSelectedGroup} />
                </Panel>
              )}
              <ViewAllianceTotalPrice
                memoGroupOption={memoGroupOption}
                costingHeader={costingHeader}
                setCostingHeader={setCostingHeader}
                currentOrganistion={orderDetailInfo.organisation}
                price={price}
                price_rmt={2}
                price_rpvt={5}
              />
              <Note groupPrice={true} />
            </TabPanel>

            <TabPanel className={classes.gridRoot} value="1">
              <Panel>
                <AcupunctureGroupOption
                  viewOnly={editVisitAcupointViewOnly ? false : true}
                  editVisitAcupointViewOnly={editVisitAcupointViewOnly}
                  memoAcupunctureGroupOption={memoAcupunctureGroupOption}
                  acupunctureGroupOption={
                    editVisitAcupointViewOnly
                      ? visitAcupointDialog
                      : acupunctureGroupOption
                  }
                  setAcupunctureGroupOption={
                    editVisitAcupointViewOnly
                      ? setVisitAcupointDialog
                      : setAcupunctureGroupOption
                  }
                  acupunctureGroup={acupunctureGroup}
                  setAcupunctureGroup={setAcupunctureGroup}
                  memoSelectedAcupunctureGroupIndex={
                    memoSelectedAcupunctureGroupIndex
                  }
                  acupointList={acupointList}
                  setAcupointList={setAcupointList}
                  memoSelectedAcupunctureGroup={memoSelectedAcupunctureGroup}
                  acupunctureGroupId={acupunctureGroupId}
                  setAcupunctureGroupId={setAcupunctureGroupId}
                />
              </Panel>

              {Object.keys(acupointList).length !== 0 && (
                <>
                  <Panel>
                    <Grid item xs={12} className={classes.userInfoField}>
                      <AcupointListTable
                        viewOnly={editVisitAcupointViewOnly ? false : true}
                        t={t}
                        data={acupointList.sort((a, b) => a.row_no - b.row_no)}
                        setData={setAcupointsData}
                        infoModule="VISIT"
                      />
                    </Grid>
                  </Panel>

                  <Panel>
                    <Grid item xs={12} className={classes.userInfoField}>
                      <GroupAcupunctureInfo
                        viewOnly={true}
                        memoSelectedAcupunctureGroup={
                          memoSelectedAcupunctureGroup
                        }
                        handleAcupunctureNumInfo={handleAcupunctureNumInfo}
                        acupointList={acupointList}
                      />
                    </Grid>
                  </Panel>
                </>
              )}
            </TabPanel>

            <TabPanel className={classes.gridRoot} value="2">
              {Object.keys(memoTreatmentWODelete).length !== 0 && (
                <>
                  <Panel>
                    <Grid item xs={12} className={classes.userInfoField}>
                      <TreatmentListTable
                        viewOnly={true}
                        t={t}
                        data={memoTreatmentWODelete.sort(
                          (a, b) => a.row_no - b.row_no
                        )}
                        setData={setTreatmentData}
                        med_details_price_rmt={
                          orderDetailInfo.med_details_price_rmt
                        }
                        med_details_price_rpvt={
                          orderDetailInfo.med_details_price_rpvt
                        }
                        infoModule="VISIT"
                      />
                    </Grid>
                  </Panel>
                </>
              )}
            </TabPanel>

            <TabPanel className={classes.gridRoot} value="3">
              {Object.keys(memoMiscWODelete).length !== 0 && (
                <>
                  <Panel>
                    <Grid item xs={12} className={classes.userInfoField}>
                      <MiscListTable
                        viewOnly={true}
                        t={t}
                        data={memoMiscWODelete.sort(
                          (a, b) => a.row_no - b.row_no
                        )}
                        setData={setMiscData}
                        med_details_price_rmt={
                          orderDetailInfo.med_details_price_rmt
                        }
                        med_details_price_rpvt={
                          orderDetailInfo.med_details_price_rpvt
                        }
                      />
                    </Grid>
                  </Panel>
                </>
              )}

              <Panel>
                <PaymentTable
                  viewOnly={true}
                  price={price}
                  discount={discount}
                  setPaymentData={setPaymentData}
                  extraAmt={extraAmt}
                  setExtraAmt={setExtraAmt}
                  t={t}
                />
              </Panel>

              <Panel>
                <PaymentTotal
                  viewOnly={true}
                  payment={payment}
                  setPayment={setPayment}
                  amountHeader={amountHeader}
                  currentOrganistion={orderDetailInfo}
                  gst_value={orderDetailInfo.gst_value}
                />
              </Panel>
            </TabPanel>
          </TabContext>
        </Grid>
        {!editVisitAcupointView && !editVisitNoteView && !historyMode && (
          <>
            <RequiredNote />
            <PageOnlyBackAction setView={() => setOrderDetailView(false)} />
          </>
        )}
      </Grid>
    </>
  );
};

export default OrderDetailPage;
