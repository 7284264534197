import React, { useEffect, useState, useMemo } from "react";

import { useTable, useSortBy, usePagination } from "react-table";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { IconButton, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Panel from "./Panel";
import SearchBar from "./SearchBar";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import * as pageConfigure from "../_constants/pageConstants";
import { useStyles, highlightColorStyle } from "./globalTableStyles";
import { useTheme } from "@mui/styles";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { setUserLocalStorage } from "../components/functions/localStorage";
import Refresh from "@mui/icons-material/Refresh";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import DragHandleIcon from "@mui/icons-material/DragHandle";
import { DraggableTableRow } from "./DraggableTableRow";

export const SortingSearchDragTable = ({
  statusPlaceholder,
  statusOption,
  tableHeading,
  searchPlaceholder,
  columns,
  data,
  pageInfo,
  setPageInfo,
  searchValue,
  pageCount: controlledPageCount,
  loading,
  totalItems,
  handleSearch,
  searchDate,
  searchDatePlaceholder,
  searchDate2,
  searchDatePlaceholder2,
  searchDispensaryDateOnly,
  searchDispensaryDateOnlyPlaceholder,
  disabledSearch,
  disabledFooter,
  banStatusIdList = [
    pageConfigure.DETELED_GENERAL_STATUS,
    pageConfigure.APPROVED_GENERAL_STATUS,
  ],
  highlightOrderId = null,
  localStorageName = null,
  showSerialNo = true,
  setGroupOrder,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { sortBy, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      manualPagination: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: pageInfo.page_number - 1,
        pageSize: pageInfo.page_size,
      },
    },
    useSortBy,
    usePagination
  );
  const { t } = useTranslation();

  const [activeId, setActiveId] = useState();
  const items = useMemo(() => data?.map(({ id }) => id), [data]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setGroupOrder((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newArray = arrayMove(data, oldIndex, newIndex);
        newArray.map((o1, index) => {
          newArray[index].row_no = index + 1;
        });
        return newArray;
      });
    }
    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  useEffect(() => {
    if (sortBy.length > 0) {
      setPageInfo((pageInfo) => ({
        ...pageInfo,

        order_by_field: sortBy[0].id,
        order_by_direction: sortBy[0].desc ? "Desc" : "Asc",
      }));
    }
  }, [sortBy, setPageInfo]);

  const optionAllow = (options, banStatusIdList) => {
    const result = banStatusIdList.reduce((acc, curr) => {
      acc = acc.filter((item) => item.id !== curr);
      return acc;
    }, options);

    return result;
  };
  const handlePreviousPage = () => {
    previousPage();
    setPageInfo({
      ...pageInfo,
      page_number: parseInt(pageIndex),
    });
  };

  const handleNextPage = () => {
    nextPage();
    setPageInfo({
      ...pageInfo,
      page_number: parseInt(pageIndex + 2),
    });
  };
  const handleChangeRowsPerPage = (event) => {
    if (localStorageName) {
      setUserLocalStorage(localStorageName, parseInt(event.target.value, 10));
    }
    gotoPage(0);
    setPageSize(parseInt(event.target.value, 10));
    setPageInfo({
      ...pageInfo,
      page_size: parseInt(event.target.value, 10),
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
    });
  };

  const handleChangeFistPage = () => {
    gotoPage(0);
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
    });
  };
  const handleChangeLastPage = () => {
    gotoPage(pageCount - 1);

    setPageInfo({
      ...pageInfo,
      page_number: parseInt(pageCount, 10),
    });
  };

  const handleChangeSearchBar = (value) => {
    gotoPage(0);

    handleSearch(value);
  };

  const handleChangeCancelSearchBar = () => {
    gotoPage(0);

    handleSearch();
  };

  const handleChangeSearchStatus = (event, option) => {
    gotoPage(0);

    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_status_type_value: option ? option.id : null,
    });
  };
  const getStatusOpObj = (option) => {
    if (!option.id) option = statusOption.find((op) => op.id === option);
    return option;
  };

  const paginationClasses = useStyles();
  function TablePaginationActions() {
    const theme = useTheme();

    return (
      <span className={paginationClasses.root}>
        <IconButton
          onClick={handleChangeFistPage}
          disabled={!canPreviousPage}
          aria-label="first page"
          size="large">
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handlePreviousPage}
          disabled={!canPreviousPage}
          aria-label="previous page"
          size="large">
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextPage}
          disabled={!canNextPage}
          aria-label="next page"
          size="large">
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleChangeLastPage}
          disabled={!canNextPage}
          aria-label="last page"
          size="large">
          <LastPage />
        </IconButton>
      </span>
    );
  }

  return (
    <Panel heading={tableHeading}>
      {loading && <>{t("translation:Loading")}...</>}
      {!disabledSearch && !loading && (
        <Grid container>
          <Grid
            item
            xs={
              statusOption || searchDate || searchDate2
                ? searchDate && statusOption
                  ? searchDate2
                    ? 6
                    : 8
                  : searchDate2
                  ? 8
                  : 10
                : 12
            }
            className={paginationClasses.searchField}>
            <SearchBar
              onRequestSearch={handleChangeSearchBar}
              onCancelSearch={handleChangeCancelSearchBar}
              placeholder={searchPlaceholder}
              value={searchValue}
            />
          </Grid>

          {searchDate2 && (
            <Grid item xs={2} className={paginationClasses.userInfoField}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={paginationClasses.userFieldColor}
                  inputVariant="outlined"
                  id="date"
                  label={searchDatePlaceholder2}
                  acceptInconsistentFormat={false}
                  autoOk={true}
                  slotProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                  value={
                    pageInfo.search_proceed_start_value
                      ? dayjs(pageInfo.search_proceed_start_value)
                      : null
                  }
                  format="DD MMM YYYY"
                  onChange={(e) => {
                    if (dayjs(e, true).isValid()) {
                      setPageInfo({
                        ...pageInfo,
                        search_proceed_start_value: dayjs(e, true).isValid()
                          ? dayjs(e).format("YYYY-MM-DD")
                          : null,
                        search_proceed_end_value: dayjs(e, true).isValid()
                          ? dayjs(e).format("YYYY-MM-DD")
                          : null,
                      });
                    }
                    if (e === null) {
                      setPageInfo({
                        ...pageInfo,
                        search_proceed_start_value: null,
                        search_proceed_end_value: null,
                      });
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          )}

          {searchDate && (
            <Grid item xs={2} className={paginationClasses.userInfoField}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={paginationClasses.userFieldColor}
                  inputVariant="outlined"
                  id="date"
                  label={searchDatePlaceholder}
                  acceptInconsistentFormat={false}
                  autoOk={true}
                  slotProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                  value={
                    pageInfo.search_start_date_value
                      ? dayjs(pageInfo.search_start_date_value)
                      : null
                  }
                  format="DD MMM YYYY"
                  onChange={(e) => {
                    if (dayjs(e, true).isValid()) {
                      setPageInfo({
                        ...pageInfo,
                        search_start_date_value: dayjs(e, true).isValid()
                          ? dayjs(e).format("YYYY-MM-DD")
                          : null,
                        search_end_date_value: dayjs(e, true).isValid()
                          ? dayjs(e).format("YYYY-MM-DD")
                          : null,
                      });
                    }
                    if (e === null) {
                      setPageInfo({
                        ...pageInfo,
                        search_start_date_value: null,
                        search_end_date_value: null,
                      });
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          )}
          {statusOption && (
            <Grid item xs={2} className={paginationClasses.userInfoField}>
              <Autocomplete
                name="Order Status"
                options={optionAllow(statusOption, banStatusIdList)}
                value={
                  pageInfo.search_status_type_value
                    ? pageInfo.search_status_type_value
                    : null
                }
                getOptionLabel={(option) => getStatusOpObj(option).name}
                isOptionEqualToValue={(option, value) => {
                  if (option.id === value) return option;
                }}
                onChange={(e, option) =>
                  handleChangeSearchStatus(e, option == null ? null : option)
                }
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    className={paginationClasses.userFieldColor}
                    label={statusPlaceholder}
                    {...params}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}

      {searchDispensaryDateOnly && (
        <Grid container>
          <Grid item xs={12} className={paginationClasses.userInfoField}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={paginationClasses.userFieldColor}
                inputVariant="outlined"
                id="date"
                label={searchDispensaryDateOnlyPlaceholder}
                acceptInconsistentFormat={false}
                autoOk={true}
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                value={
                  pageInfo.search_dispensary_wip_start_value
                    ? dayjs(pageInfo.search_dispensary_wip_start_value)
                    : null
                }
                format="DD MMM YYYY"
                onChange={(e) => {
                  if (dayjs(e, true).isValid()) {
                    setPageInfo({
                      ...pageInfo,
                      search_dispensary_wip_start_value: dayjs(
                        e,
                        true
                      ).isValid()
                        ? dayjs(e).format("YYYY-MM-DD")
                        : null,
                      search_dispensary_wip_end_value: dayjs(e, true).isValid()
                        ? dayjs(e).add(1, "day").format("YYYY-MM-DD")
                        : null,
                    });
                  }
                  if (e === null) {
                    setPageInfo({
                      ...pageInfo,
                      search_dispensary_wip_start_value: null,
                      search_dispensary_wip_end_value: null,
                    });
                  }
                }}
              />
            </LocalizationProvider>

            <Button
              onClick={() => {
                setPageInfo({
                  ...pageInfo,
                  search_dispensary_wip_start_value: dayjs(
                    pageInfo.search_dispensary_wip_start_value,
                    true
                  ).isValid()
                    ? dayjs(pageInfo.search_dispensary_wip_start_value).format(
                        "YYYY-MM-DD"
                      )
                    : null,
                  search_dispensary_wip_end_value: dayjs(
                    pageInfo.search_dispensary_wip_start_value,
                    true
                  ).isValid()
                    ? dayjs(pageInfo.search_dispensary_wip_start_value)
                        .add(1, "day")
                        .format("YYYY-MM-DD")
                    : null,
                });
              }}>
              <Refresh className={paginationClasses.iconColor} />
            </Button>
          </Grid>
        </Grid>
      )}

      {!loading && (
        <Grid container>
          <Grid item xs={12}>
            <>
              <TableContainer component={Paper}>
                <DndContext
                  sensors={sensors}
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                  onDragCancel={handleDragCancel}
                  collisionDetection={closestCenter}
                  modifiers={[restrictToVerticalAxis]}>
                  <MaUTable {...getTableProps()}>
                    <TableHead className={paginationClasses.headStyle}>
                      {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                          {showSerialNo && (
                            <TableCell
                              className={paginationClasses.headCellStyle}>
                              {t("translation:S/N")}
                            </TableCell>
                          )}
                          {headerGroup.headers.map((column) => (
                            <TableCell
                              //check sortable
                              className={paginationClasses.headCellStyle}
                              {...column.getHeaderProps(
                                column.sortable !== false &&
                                  column.getSortByToggleProps()
                              )}>
                              {column.render("Header")}

                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " ⇣"
                                    : " ⇡"
                                  : ""}
                              </span>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                      <SortableContext
                        items={items.map((i) => i.id)}
                        strategy={verticalListSortingStrategy}>
                        {page.map((row, index) => {
                          prepareRow(row);
                          return (
                            <DraggableTableRow
                              key={row.original.id}
                              row={row}
                              i={index}
                              highlightOrderId={highlightOrderId}
                              pageInfo={pageInfo}
                            />
                          );
                        })}
                      </SortableContext>
                    </TableBody>

                    {!disabledFooter && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            onPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={
                              pageConfigure.DEFAULT_PAGEOPTIONS
                            }
                            colSpan={12}
                            count={totalItems}
                            rowsPerPage={pageSize}
                            page={pageIndex}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </MaUTable>
                  <DragOverlay>
                    {activeId && (
                      <MaUTable>
                        <TableBody>
                          <TableRow
                            className={paginationClasses.rowBackground}
                            {...selectedRow.getRowProps()}>
                            {selectedRow.cells.map((cell, i) => {
                              return (
                                <TableCell
                                  //style for alignment and width
                                  style={
                                    cell.column.cellAlign
                                      ? {
                                          textAlign: cell.column.cellAlign,
                                          maxWidth: 70,
                                          minWidth: 10,
                                          color:
                                            highlightOrderId ===
                                            cell.row.original.id
                                              ? highlightColorStyle
                                              : "",
                                        }
                                      : {
                                          maxWidth: 700,
                                          minWidth: 10,
                                          color:
                                            highlightOrderId ===
                                            cell.row.original.id
                                              ? highlightColorStyle
                                              : cell.row.original
                                                  ?.dispensary_header
                                                  ?.row_color_hex_code
                                              ? `${cell.row.original?.dispensary_header?.row_color_hex_code}`
                                              : "",
                                        }
                                  }
                                  {...cell.getCellProps({})}>
                                  {i === 0 ? (
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small">
                                      <DragHandleIcon />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )}
                                  {cell.render("Cell")}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableBody>
                      </MaUTable>
                    )}
                  </DragOverlay>
                </DndContext>
              </TableContainer>
            </>
          </Grid>
        </Grid>
      )}
    </Panel>
  );
};
