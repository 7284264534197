//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

//actions
import { getDeliveryOptimizedRouting } from "../../../../actions/deliveryOrderActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { SortingSearchDragTable } from "../../../../components/SortingSearchDragTable";
import { useStyles } from "../../../../components/globalStyles";
import * as pageConfigure from "../../../../_constants/pageConstants";
import ViewOptimizedRoutesDialog from "./ViewOptimizedRoutesDialog";
//style
export default function DeliveryOptimizedDialog({
  isLoading,
  selectedValue,
  confirmOpen,
  setConfirmOpen,
  handleConfirmProcess,
  groupOrder,
  setGroupOrder,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const prevRouteStatus = useRef();

  const curUser = useSelector((state) => state.curUser);

  const deliveryOrder = useSelector((state) => state.deliveryOrder);

  const [routeLoading, setRouteLoading] = useState(false);

  const [alertMsg, setAlertMsg] = useState(null);

  const [alertType, setAlertType] = useState(null);

  const [msgAlarm, setMsgAlarm] = useState(false);

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
  });

  // const setData = (rowIndex, columnID) => {
  //   if (columnID === "up") {
  //     let result = [...groupOrder];
  //     const newAddMedicineVerified = groupOrder.findIndex(
  //       (e, index) => index === rowIndex
  //     );

  //     [result[newAddMedicineVerified], result[newAddMedicineVerified - 1]] = [
  //       result[newAddMedicineVerified - 1],
  //       result[newAddMedicineVerified],
  //     ];
  //     result = result.map((x, index) => {
  //       return { ...x, row_no: index + 1 };
  //     });
  //     setGroupOrder(result);
  //   } else if (columnID === "down") {
  //     let result = [...groupOrder];
  //     const newAddMedicineVerified = groupOrder.findIndex(
  //       (e, index) => index === rowIndex
  //     );

  //     [result[newAddMedicineVerified], result[newAddMedicineVerified + 1]] = [
  //       result[newAddMedicineVerified + 1],
  //       result[newAddMedicineVerified],
  //     ];

  //     result = result.map((x, index) => {
  //       return { ...x, row_no: index + 1 };
  //     });
  //     setGroupOrder(result);
  //   }
  // };

  const columnUpdateRow = [
    {
      id: "reorder",
      textAlign: "left",
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => <span className={classes.fullWidthField}></span>,
    },
    {
      Header: t("translation:Recipient"),
      accessor: "delivery_name",
      sortable: false,
    },
    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "delivery_contact_number",
    },
    {
      Header: t("translation:Postal Code"),
      sortable: false,
      accessor: "delivery_postal_code",
    },
    {
      Header: t("translation:Address"),
      sortable: false,
      accessor: "delivery_address",
    },
    {
      Header: t("translation:Address Details"),
      sortable: false,
      accessor: "delivery_address_details",
    },
    {
      Header: t("translation:Remarks"),
      sortable: false,
      accessor: "delivery_remarks",
    },
  ];

  const onClickRoute = (e) => {
    const submission = {
      is_pagination: false,
      search_delivery_order_id_value: selectedValue.id,
      geometry: {
        first_stop: {
          name: "first_stop",
          postal_code: curUser.defaultBranch.branch.postal_code,
        },
        remove_duplicate: true,
      },
    };
    dispatch(getDeliveryOptimizedRouting.pending(submission));
  };

  const overrideRoute = (value) => {
    if (value.length) {
      const tempRoute = value[0].geometry.filter(
        (item) => item !== null && item.name !== "first_stop"
      );
      let temValidAddress = [];
      //find the addr and arrange it

      tempRoute.map((o1, index) => {
        if (
          groupOrder.some((o2) => o2.delivery_postal_code === o1.postal_code)
        ) {
          const filterList = groupOrder.filter(
            (o2) => o2.delivery_postal_code === o1.postal_code
          );
          temValidAddress = [...temValidAddress, ...filterList];
        }
      });

      let temInvalidAddress = groupOrder.filter(
        (o1) =>
          !tempRoute.some((o2) => o1.delivery_postal_code === o2.postal_code)
      );
      let temCombinationAddress = [...temInvalidAddress, ...temValidAddress];

      temCombinationAddress.map((o1, index) => {
        temCombinationAddress[index].row_no = index + 1;
      });
      setGroupOrder(temCombinationAddress);
    }
  };

  //useEffects
  useEffect(() => {
    prevRouteStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingGetDeliveryOptimizedRouting !==
      prevRouteStatus.current
    ) {
      //check the previous ref with current state
      prevRouteStatus.current =
        deliveryOrder.isLoadingGetDeliveryOptimizedRouting;

      if (
        deliveryOrder.getDeliveryOptimizedRoutingIsSuccess === true &&
        deliveryOrder.isLoadingGetDeliveryOptimizedRouting === false
      ) {
        overrideRoute(deliveryOrder.getDeliveryOptimizedRouting);
        setRouteLoading(false);
        setMsgAlarm(t("translation:optimum routes successfully"));
        setAlertType("success");
        setAlertMsg(true);
      }

      if (
        deliveryOrder.getDeliveryOptimizedRoutingIsSuccess === false &&
        deliveryOrder.isLoadingGetDeliveryOptimizedRouting === false
      ) {
        setRouteLoading(false);
        setMsgAlarm(t("translation:Failed to optimum routes"));
        setAlertType("error");
        setAlertMsg(true);
      }
    }
  }, [dispatch, deliveryOrder]);

  return (
    <>
      {isLoading && (
        <ConfirmDialog
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Loading")}...
        </ConfirmDialog>
      )}
      {isLoading === false && (
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          maxWidth="lg"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {alertType !== null && alertMsg !== null && (
            <Collapse in={alertMsg}>
              <Alert
                severity={alertType}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertMsg(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {msgAlarm}
              </Alert>
            </Collapse>
          )}
          <Grid item xs={12}>
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                disabled={routeLoading}
                onClick={() => onClickRoute()}>
                {t("translation:Optimum Routes")}
              </Button>
            </Link>
            <ViewOptimizedRoutesDialog
              groupOrder={groupOrder}
              setMsgAlarm={setMsgAlarm}
              setAlertType={setAlertType}
              setAlertMsg={setAlertMsg}
            />
          </Grid>

          <SortingSearchDragTable
            tableHeading=""
            disabledSearch
            disabledFooter
            searchValue={" "}
            columns={columnUpdateRow}
            data={groupOrder}
            pageInfo={viewPageInfo}
            setPageInfo={setViewPageInfo}
            pageCount={0}
            loading={false}
            totalItems={0}
            handleSearch={() => null}
            setGroupOrder={setGroupOrder}
          />
        </ConfirmDialog>
      )}
    </>
  );
}
