//lib

import React, { useRef, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Close from "@mui/icons-material/Close";
import { Grid, Link, Button } from "@mui/material";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";

//components

import { useStyles } from "../../../../../components/globalStyles";
import Panel from "../../../../../components/Panel";
import * as pageConfigure from "../../../../../_constants/pageConstants";
import * as generalConstants from "../../../../../_constants/generalConstants";
import NodeItemTable from "../Components/NodeItemTable";

//Actions
import { getWikiDataPreviewList } from "../../../../../actions/wikiDataActions";
import { getVisitWikiDataList } from "../../../../../actions/visitOrderActions";

const WikiDataLoop = ({
  selectedList,
  setSelectedList,
  data,
  onHandleRetriveData,
  retrivedLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (selectedList.length === 0) {
    return (
      <Autocomplete
        name="Node"
        disableClearable
        disabled={retrivedLoading}
        key={0}
        options={data}
        getOptionLabel={(option) => option.name}
        onChange={(e, option) => {
          onHandleRetriveData(option);
          setSelectedList([
            {
              nodeId: option.id,
              name: option.name,
              subnodes: option.subnodes,
              nodeIndex: 0,
            },
          ]);
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            className={classes.userFieldColor}
            {...params}
            label={t("translation:Node")}
            variant="outlined"
          />
        )}
      />
    );
  } else {
    return selectedList.map((item, index) => {
      return (
        <>
          <Grid item xs={12} className={classes.userInfoField}>
            <Autocomplete
              disableClearable
              name="Node"
              disabled={retrivedLoading}
              value={item}
              key={item.id}
              options={index === 0 ? data : selectedList[index - 1].subnodes}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (option.id === value.nodeId) return option;
              }}
              onChange={(e, option) => {
                onHandleRetriveData(option);
                setSelectedList(() => {
                  let resultList = selectedList;
                  resultList[index] = {
                    nodeId: option.id,
                    name: option.name,
                    subnodes: option.subnodes,
                    nodeIndex: index,
                  };
                  resultList = resultList.filter((item, idx) => idx <= index);
                  return resultList;
                });
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  className={classes.userFieldColor}
                  {...params}
                  label={t("translation:Node")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            {index + 1 === selectedList.length &&
              item.subnodes &&
              item.subnodes.length > 0 && (
                <Autocomplete
                  disableClearable
                  name="Node"
                  disabled={retrivedLoading}
                  key={item.id}
                  options={item.subnodes ? item.subnodes : []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value.id) return option;
                  }}
                  onChange={(e, option) => {
                    onHandleRetriveData(option);
                    setSelectedList((selectedList) => {
                      const List = selectedList;
                      List[index + 1] = {
                        nodeId: option.id,
                        name: option.name,
                        nodeIndex: index + 1,
                      };
                      return List;
                    });
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Node")}
                      variant="outlined"
                    />
                  )}
                />
              )}
          </Grid>
        </>
      );
    });
  }
};

function wikiDataInsert(data, subnode) {
  const updatedData = data.map((item, index) => {
    if (item.nodeId === subnode.id) {
      item.subnodes = subnode.subnodes;
    }

    return item;
  });
  return updatedData;
}

export default function WikiDataPreview({
  visitOrderMode = false,
  setWikiOpen = () => null,
}) {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const prevGetListStatus = useRef();
  const wikiDataData = useSelector((state) => state.wikiData);
  const visitOrder = useSelector((state) => state.visitOrder);
  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: "Asc",
    order_by_field: "name",
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    is_pagination: false,
    search_desc_value: null,
    search_id_value: null,
    search_name_value: "",
    search_root_node: true,
    search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
    search_subnode_value: null,
    view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
  });
  const [previewWikidata, setPreviewWikidata] = React.useState([]);
  const [temWikiData, setTemWikiData] = useState(null);

  const [openWikiDataPreviewDialog, setOpenWikiDataPreviewDialog] =
    React.useState(false);

  const [retrivedById, setRetrivedById] = React.useState(false);

  const [retrivedLoading, setRetrivedLoading] = React.useState(false);

  const [selectedList, setSelectedList] = React.useState([]);

  const onHandleRetriveData = (data) => {
    setTemWikiData(data);
    if (data.is_root_node !== true) {
      setRetrivedLoading(true);
      setPageInfo((prev) => {
        return {
          ...prev,
          is_pagination: false,
          search_id_value: data.id,
          search_root_node: false,
          view_format_value: pageConfigure.WIKI_LEVEL_VIEW,
        };
      });

      setRetrivedById(true);
    }
  };
  //useEffect

  useEffect(() => {
    prevGetListStatus.current = false;
  }, []);
  useEffect(() => {
    if (openWikiDataPreviewDialog) {
      if (visitOrderMode) {
        dispatch(getVisitWikiDataList.pending(pageInfo));
      } else {
        dispatch(getWikiDataPreviewList.pending(pageInfo));
      }
    }
  }, [dispatch, pageInfo, openWikiDataPreviewDialog]);

  useEffect(() => {
    if (!visitOrderMode) {
      if (
        wikiDataData.isLoadingGetWikiDataPreviewList !==
        prevGetListStatus.current
      ) {
        //check the previous ref with current state
        prevGetListStatus.current =
          wikiDataData.isLoadingGetWikiDataPreviewList;

        if (
          wikiDataData.getWikiDataPreviewListIsSuccess === true &&
          wikiDataData.isLoadingGetWikiDataPreviewList === false
        ) {
          setRetrivedLoading(false);
          if (retrivedById) {
            if (wikiDataData.getWikiDataPreviewList?.wiki_datas.length > 0) {
              // const newJson = wikiDataInsert(
              //   previewWikidata,
              //   wikiDataData.getWikiDataPreviewList?.wiki_datas[0]
              // );
              const newJson = wikiDataInsert(
                selectedList,
                wikiDataData.getWikiDataPreviewList?.wiki_datas[0]
              );
              setTemWikiData(
                wikiDataData.getWikiDataPreviewList?.wiki_datas[0]
              );
              //setPreviewWikidata(newJson);
              setSelectedList(newJson);
            }
          } else {
            setPreviewWikidata(wikiDataData.getWikiDataPreviewList?.wiki_datas);
          }
        }
        if (
          wikiDataData.getWikiDataPreviewListIsSuccess === false &&
          wikiDataData.isLoadingGetWikiDataPreviewList === false
        ) {
          setRetrivedLoading(false);
        }
      }
    }
  }, [wikiDataData]);

  useEffect(() => {
    if (visitOrderMode) {
      if (
        visitOrder.isLoadingGetVisitWikiDataList !== prevGetListStatus.current
      ) {
        //check the previous ref with current state
        prevGetListStatus.current = visitOrder.isLoadingGetVisitWikiDataList;

        if (
          visitOrder.getVisitWikiDataListIsSuccess === true &&
          visitOrder.isLoadingGetVisitWikiDataList === false
        ) {
          setRetrivedLoading(false);
          if (retrivedById) {
            if (visitOrder.getVisitWikiDataList?.wiki_datas.length > 0) {
              const newJson = wikiDataInsert(
                selectedList,
                visitOrder.getVisitWikiDataList?.wiki_datas[0]
              );
              setTemWikiData(visitOrder.getVisitWikiDataList?.wiki_datas[0]);
              setSelectedList(newJson);
            }
          } else {
            setPreviewWikidata(visitOrder.getVisitWikiDataList?.wiki_datas);
          }
        }
        if (
          visitOrder.getVisitWikiDataListIsSuccess === false &&
          visitOrder.isLoadingGetVisitWikiDataList === false
        ) {
          setRetrivedLoading(false);
        }
      }
    }
  }, [visitOrder]);

  return (
    <Grid item>
      <Link className={classes.boldMaroon075}>
        <Button
          name={"wikiDataPreviewOpenButton"}
          className={classes.boldMaroon075}
          onClick={() => {
            setOpenWikiDataPreviewDialog(true);
            setWikiOpen(true);
          }}>
          {t("translation:Wiki Data Preview")}
        </Button>
      </Link>
      <Grid className={classes.root} container>
        <Dialog
          disableEnforceFocus // Allows other things to take focus
          hideBackdrop // Hides the shaded backdrop
          disableScrollLock
          disableAutoFocus
          open={openWikiDataPreviewDialog}
          style={{
            top: "5%", // Position however you like
            left: "65%",
          }}
          onClose={(event, reason) => {
            {
              setOpenWikiDataPreviewDialog(false);
              setWikiOpen(false);
            }
          }}
          fullWidth
          maxWidth="sm">
          <Close
            style={{ position: "absolute", right: "15px", zIndex: 1 }}
            onClick={() => {
              setOpenWikiDataPreviewDialog(false);
              setWikiOpen(false);
            }}
          />

          <DialogContent>
            <Panel heading={t("translation:Wiki Data Details")}>
              <Grid container>
                <Grid item xs={12}>
                  <WikiDataLoop
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                    data={previewWikidata}
                    onHandleRetriveData={onHandleRetriveData}
                    retrivedLoading={retrivedLoading}
                    temWikiData={temWikiData}
                  />
                </Grid>
                {temWikiData && (
                  <Grid item xs={12}>
                    <NodeItemTable
                      data={temWikiData}
                      setData={setTemWikiData}
                      temViewOnly={true}
                    />
                  </Grid>
                )}
              </Grid>
            </Panel>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
