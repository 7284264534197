import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/userDeviceKeyConstants";

export const getUserDeviceKeyById = {
  pending: (data) =>
    createAction(types.GET_USERDEVICEKEYBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERDEVICEKEYBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERDEVICEKEYBYID_ASYNC.ERROR, { error }),
};

export const getUserDeviceKeyList = {
  pending: (data) =>
    createAction(types.GET_USERDEVICEKEYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERDEVICEKEYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERDEVICEKEYLIST_ASYNC.ERROR, { error }),
};

export const postCreateUserDeviceKey = {
  pending: (data) =>
    createAction(types.POST_CREATEUSERDEVICEKEY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEUSERDEVICEKEY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEUSERDEVICEKEY_ASYNC.ERROR, { error }),
};

export const putUpdateUserDeviceKey = {
  pending: (data) =>
    createAction(types.PUT_UPDATEUSERDEVICEKEY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEUSERDEVICEKEY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEUSERDEVICEKEY_ASYNC.ERROR, { error }),
};

export const getUserDeviceKeyUserList = {
  pending: (data) =>
    createAction(types.GET_USERDEVICEKEYUSERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_USERDEVICEKEYUSERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_USERDEVICEKEYUSERLIST_ASYNC.ERROR, { error }),
};
