//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

//actions
import {
  getStoreHeaderSummaryList,
  getStoreHeaderList,
} from "../../../../actions/deliveryOrderActions";

//components
import { SortingSearchTable } from "../../../../components/SortingSearchTable";

import * as pageConfigure from "../../../../_constants/pageConstants";

import { ReactComponent as DetailLogo } from "../../../../assets/coolicons/file/file_blank_outline.svg";

import OrderDetailPage from "../../Store/OrderDetailPage";
import { useStyles } from "../../../../components/globalStyles";

export default function StoreOrderList({ onClose, setStoreOrderSearchId }) {
  //variables

  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      Header: "",
      accessor: "Search",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button
            title={t("translation:Search")}
            onClick={() => handleRowSearch(row.original)}>
            <SearchIcon className={classes.iconColor} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Order No"),
      accessor: "order_number",
    },
    {
      Header: t("translation:Physician"),
      accessor: "physician_name",
      sortable: false,
    },

    {
      Header: t("translation:Staff Name"),
      accessor: "staff_name",
    },

    {
      Header: t("translation:Order Description"),
      accessor: "desc",
    },

    {
      Header: t("translation:Physician/ Staff Contact Number"),
      sortable: false,
      accessor: "staff_contact_number",
      Cell: ({ row }) => {
        if (row.original.physician_name)
          return row.original.physician_contact_number;
        else return row.original.staff_contact_number;
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,
    is_pagination: true,
    search_end_date_value: null,
    search_name_value: null,
    search_desc_value: null,
    search_order_number_value: null,
    search_staff_name_value: null,
    search_start_date_value: null,
    search_staff_contact_number_value: null,
    search_physician_name_value: null,
    search_status_type_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
  });

  const dispatch = useDispatch();

  const deliveryOrder = useSelector((state) => state.deliveryOrder);

  const common = useSelector((state) => state.common);
  const prevGetEmailStoreOrderByIdOnStatus = useRef();

  const [searchValue, setSearchValue] = useState("");

  const [orderDetailView, setOrderDetailView] = useState(false);

  const [orderDetailInfo, setOrderDetailInfo] = useState([]);

  //functions

  const handleRowSearch = (value) => {
    setStoreOrderSearchId(value.id);
    // dispatch(getDeliveryOrderByStoreHeaderId.pending(value.id));
    onClose();
  };

  const handleRowDetail = (value) => {
    //

    dispatch(
      getStoreHeaderList.pending({
        search_order_number_value: value.order_number,
      })
    );

    setOrderDetailInfo(null);
    setOrderDetailView(true);
  };

  const handleSearch = (value) => {
    if (value) {
      setSearchValue(value);
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: value,
        search_staff_name_value: value,
        search_staff_contact_number_value: value,
        search_physician_name_value: value,
        search_desc_value: value,
      });
    } else {
      setSearchValue("");
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: "",
        search_staff_name_value: "",
        search_staff_contact_number_value: null,
        search_physician_name_value: null,
        search_desc_value: "",
      });
    }
  };

  useEffect(() => {
    prevGetEmailStoreOrderByIdOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingStoreOrderList !==
      prevGetEmailStoreOrderByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetEmailStoreOrderByIdOnStatus.current =
        deliveryOrder.isLoadingStoreOrderList;

      if (
        deliveryOrder.getStoreOrderListSuccess === true &&
        deliveryOrder.isLoadingStoreOrderList === false
      ) {
        setOrderDetailInfo(deliveryOrder.storeOrderList.store_headers[0]);
      }
    }
  }, [deliveryOrder, setOrderDetailInfo]);
  useEffect(() => {
    if (!orderDetailView) {
      dispatch(getStoreHeaderSummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, orderDetailView]);

  if (common.getGeneralStatusIsSuccess === true && !orderDetailView) {
    return (
      <>
        <SortingSearchTable
          searchPlaceholder={t(
            "translation:Order No/ Physician/Staff Name/ Staff Contact Number/ Order Description"
          )}
          statusPlaceholder={t("translation:Order Status")}
          searchValue={searchValue}
          statusOption={common.generalStatusList.status_general}
          columns={columns}
          data={
            deliveryOrder.storeOrderSummaryList
              ? deliveryOrder.storeOrderSummaryList.store_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            deliveryOrder.storeOrderSummaryList
              ? deliveryOrder.storeOrderSummaryList.total_pages
              : 0
          }
          loading={deliveryOrder.isLoadingStoreOrderSummaryList}
          totalItems={
            deliveryOrder.storeOrderSummaryList
              ? deliveryOrder.storeOrderSummaryList.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchDate={true}
          searchDatePlaceholder={t("translation:Creation Date")}
        />
      </>
    );
  } else if (orderDetailView && orderDetailInfo) {
    return (
      <OrderDetailPage
        orderDetailInfo={orderDetailInfo}
        setOrderDetailView={setOrderDetailView}
        setOrderDetailInfo={setOrderDetailInfo}
        editDeliveryOrder={true}
        printPrescription={false}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
