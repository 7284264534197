import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton } from "@mui/material";
import { useStyles, highlightColorStyle } from "./globalTableStyles";
import DragHandleIcon from "@mui/icons-material/DragHandle";

export const DraggableTableRow = ({ row, i, highlightOrderId, pageInfo }) => {
  const paginationClasses = useStyles();
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  return (
    <TableRow
      className={i % 2 ? paginationClasses.rowBackground : ""}
      ref={setNodeRef}
      {...row.getRowProps()}>
      <TableCell
        className={paginationClasses.narrowCellStyle}
        style={{
          color:
            highlightOrderId === row.original.id ? highlightColorStyle : "",
        }}>
        {i + 1 + pageInfo.page_size * pageInfo.page_number - pageInfo.page_size}
      </TableCell>

      {isDragging ? (
        <></>
      ) : (
        row.cells.map((cell, i) => {
          return (
            <TableCell
              //style for alignment and width
              style={
                cell.column.cellAlign
                  ? {
                      textAlign: cell.column.cellAlign,
                      maxWidth: 70,
                      minWidth: 10,
                      color:
                        highlightOrderId === cell.row.original.id
                          ? highlightColorStyle
                          : "",
                    }
                  : {
                      maxWidth: 700,
                      minWidth: 10,
                      color:
                        highlightOrderId === cell.row.original.id
                          ? highlightColorStyle
                          : cell.row.original?.dispensary_header
                              ?.row_color_hex_code
                          ? `${cell.row.original?.dispensary_header?.row_color_hex_code}`
                          : "",
                    }
              }
              {...cell.getCellProps({})}>
              {i === 0 ? (
                <IconButton aria-label="close" color="inherit" size="small">
                  <DragHandleIcon {...attributes} {...listeners} />
                </IconButton>
              ) : (
                <></>
              )}
              {cell.render("Cell")}
            </TableCell>
          );
        })
      )}
    </TableRow>
  );
};
