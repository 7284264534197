//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";

import { Grid, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

//action

import { getMedicineMeasureUnit } from "../../../actions/commonActions";
//components
import Panel from "../../../components/Panel";

import ViewMedicineListTable from "./ViewMedicineListTable";
import Note from "./components/addOrder/Note";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import ViewBrewing from "./components/viewOrder/ViewBrewing";
import ViewDelivery from "./components/viewOrder/ViewDelivery";
import GroupOption from "./components/editOrder/GroupOption";
import GroupTotal from "./components/addOrder/GroupTotal";
import GroupMedicineInfo from "./components/addOrder/GroupMedicineInfo";
import { StaffInfoTable } from "./StaffInfoTable";
import RequiredNote from "../../../components/RequiredNote";
import { useStyles } from "../../../components/globalStyles";
import ViewSharedDelivery from "./components/viewOrder/ViewSharedDelivery";

import * as generalConstants from "../../../_constants/generalConstants";
//style
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    "& > span": {
      width: "100%",
      backgroundColor: "rgba(240,240,240)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(240,240,240)",
    backgroundColor: "rgba(155,29,32,1)",
    borderRadius: "5px!important",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "rgba(240,240,240)",
      opacity: 1,
    },
    "&$selected": {
      color: "rgba(240,240,240)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(240,240,240)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const OrderDetailPage = ({
  setOrderDetailView,
  orderDetailInfo,
  addSaveSuccessAlarm,
  setEditOrderInfo,
}) => {
  //variables
  const prevSubmitWorkOrderStatus = useRef();
  const prevSaveWorkOrderStatus = useRef();
  const common = useSelector((state) => state.common);
  const visitOrder = useSelector((state) => state.visitOrder);
  const [showDelivery, setShowDelivery] = useState(true);

  const [showBrewing, setShowBrewing] = useState(true);
  const [isRawHerb, setIsRawHerb] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //dont display deleted group as default
  const defaultMedicineGroup = orderDetailInfo.store_medicine_header;

  const [price, setPrice] = useState({
    deliveryFee: orderDetailInfo.delivery_total_price_for_costing
      ? orderDetailInfo.delivery_total_price_for_costing
      : 0,
    brewingFee: orderDetailInfo.brewing_total_price_for_costing
      ? orderDetailInfo.brewing_total_price_for_costing
      : 0,
    consultationFee: orderDetailInfo.consultation_total_price
      ? orderDetailInfo.consultation_total_price
      : 0,
    medicineTab: orderDetailInfo.medicine_total_price
      ? orderDetailInfo.medicine_total_price
      : 0,
    medicineFee: orderDetailInfo.medicine_price_for_costing
      ? orderDetailInfo.medicine_price_for_costing
      : 0,
    medicineGroupTotal: defaultMedicineGroup ? defaultMedicineGroup.price : 0,
    acupuncturePrice: orderDetailInfo.acupuncture_total_price
      ? orderDetailInfo.acupuncture_total_price
      : 0,
    treatmentPrice: orderDetailInfo.treatment_total_price
      ? orderDetailInfo.treatment_total_price
      : 0,
    miscPrice: orderDetailInfo.misc_total_price
      ? orderDetailInfo.misc_total_price
      : 0,
  });

  const [tabValue, setTabValues] = useState("0");
  const [group, setGroup] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.name : null
  );
  const [groupId, setGroupId] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.id : null
  );

  //turn into single group
  const [groupOption, setGroupOption] = useState([
    orderDetailInfo.store_medicine_header,
  ]);

  const [medicineList, setMedicineList] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.store_medicine_details : []
  );

  //function

  const handleMedicineGroupInfo = (event, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? event.type === "blur"
          ? event.target.value.trim()
          : event.target.value
        : null,
    };

    setGroupOption(newArray);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValues(newTabValue);
  };

  const handleMedicineGroupNumInfo = (event, value) => {
    const elementsIndex = groupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
    //make a copy of group option
    let newArray = [...groupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setGroupOption(newArray);
  };

  //useMemo
  const groupOptionAll = () => {
    return groupOption;
  };

  const memoGroupOption = React.useMemo(groupOptionAll, [groupOption]);

  const selectedGroup = () => {
    return memoGroupOption.find(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroup = React.useMemo(selectedGroup, [
    memoGroupOption,
    group,
    groupId,
  ]);

  const selectedGroupIndex = () => {
    return memoGroupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroupIndex = React.useMemo(selectedGroupIndex, [
    memoGroupOption,
    group,
    groupId,
  ]);

  //useEffects
  useEffect(() => {
    prevSubmitWorkOrderStatus.current = false;
    prevSaveWorkOrderStatus.current = false;
  }, []);
  useEffect(() => {
    dispatch(getMedicineMeasureUnit.pending());
  }, [dispatch]);

  return (
    <div>
      <Typography display="block" variant="h4">
        {orderDetailInfo.store_header.order_number}
      </Typography>
      <StaffInfoTable staff={orderDetailInfo.store_header} t={t} />
      <form>
        <br />
        <Panel>
          <Grid container>
            <Grid xs={12} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={orderDetailInfo.store_header.desc}
                name="order description"
                cy_data="storeOrderDescription"
                label={t("translation:Order Description")}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Panel>
        <Grid className={classes.gridRoot} container>
          <Grid item xs={12}>
            <TabContext value={tabValue}>
              <Grid item xs={12}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth">
                  <StyledTab
                    className={classes.defualtCursor}
                    value="0"
                    label={
                      <span>
                        <Typography display="block" variant="subtitle1">
                          {t("translation:MEDICINE")}
                        </Typography>

                        <Typography display="block" variant="subtitle1">
                          $ {price.medicineTab}
                        </Typography>
                      </span>
                    }></StyledTab>
                </StyledTabs>
              </Grid>

              <TabPanel className={classes.gridRoot} value="0">
                <Panel>
                  <GroupOption
                    viewOnly={true}
                    memoGroupOption={memoGroupOption}
                    groupOption={groupOption}
                    setGroupOption={setGroupOption}
                    group={group}
                    setGroup={setGroup}
                    memoSelectedGroupIndex={memoSelectedGroupIndex}
                    medicineList={medicineList}
                    setMedicineList={setMedicineList}
                    memoSelectedGroup={memoSelectedGroup}
                    setShowBrewing={setShowBrewing}
                    setShowDelivery={setShowDelivery}
                    setPrice={setPrice}
                    showDelivery={showDelivery}
                    showBrewing={showBrewing}
                    setIsRawHerb={setIsRawHerb}
                    setGroupId={setGroupId}
                    groupId={groupId}
                    order={visitOrder}
                  />
                </Panel>
                {Object.keys(medicineList).length !== 0 &&
                  common.medicineMeasureUnitList && (
                    <div>
                      <Panel>
                        <Grid container>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <ViewMedicineListTable
                              t={t}
                              med_details_price_rmt={
                                orderDetailInfo.med_details_price_rmt
                              }
                              med_details_price_rpvt={
                                orderDetailInfo.med_details_price_rpvt
                              }
                              data={medicineList.sort(
                                (a, b) => a.row_no - b.row_no
                              )}
                              isRawHerb={isRawHerb}
                              memoSelectedGroup={memoSelectedGroup}
                              measureUnit={
                                common.medicineMeasureUnitList.measure_units
                              }
                              infoModule="STORE"
                            />
                          </Grid>

                          <GroupMedicineInfo
                            viewOnly={true}
                            memoGroupOption={memoGroupOption}
                            handleMedicineGroupNumInfo={
                              handleMedicineGroupNumInfo
                            }
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            setGroupOption={setGroupOption}
                          />
                        </Grid>
                        <GroupTotal
                          viewOnly={true}
                          handleMedicineGroupNumInfo={
                            handleMedicineGroupNumInfo
                          }
                          price={price}
                          memoSelectedGroup={memoSelectedGroup}
                          showMultiplier={false}
                        />
                      </Panel>
                      {(memoSelectedGroup.is_brewing_services ||
                        memoSelectedGroup.is_delivery_services ||
                        memoSelectedGroup.delivery_owned?.status_type ===
                          generalConstants.APPROVED_STATUS_TYPE) && (
                        <Panel>
                          {memoSelectedGroup.is_brewing_services && (
                            <ViewBrewing
                              memoSelectedGroup={memoSelectedGroup}
                            />
                          )}
                          {memoSelectedGroup.is_delivery_services && (
                            <ViewDelivery
                              memoSelectedGroup={memoSelectedGroup}
                            />
                          )}

                          {memoSelectedGroup.delivery_owned?.status_type ===
                            generalConstants.APPROVED_STATUS_TYPE && (
                            <ViewSharedDelivery
                              memoSelectedGroup={memoSelectedGroup}
                              viemOnly={true}
                            />
                          )}
                        </Panel>
                      )}
                    </div>
                  )}
              </TabPanel>
            </TabContext>
          </Grid>
          <Note groupPrice={true} />
          <RequiredNote />
          <PageOnlyBackAction setView={() => setOrderDetailView(false)} />
        </Grid>
      </form>
    </div>
  );
};

export default OrderDetailPage;
