import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/visitOrderConstants";

export const getVisitDispensaryBranches = {
  pending: (data) =>
    createAction(types.GET_VISITDISPENSARYBRANCHES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITDISPENSARYBRANCHES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITDISPENSARYBRANCHES_ASYNC.ERROR, { error }),
};

export const getVisitMedicineTypes = {
  pending: (data) =>
    createAction(types.GET_VISITMEDICINETYPES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITMEDICINETYPES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITMEDICINETYPES_ASYNC.ERROR, { error }),
};

export const getVisitDispensaryBranchesAlliance = {
  pending: (data) =>
    createAction(types.GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC.ERROR, {
      error,
    }),
};

export const getVisitMedicineTypesAlliance = {
  pending: (data) =>
    createAction(types.GET_VISITMEDICINETYPESALLIANCE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITMEDICINETYPESALLIANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITMEDICINETYPESALLIANCE_ASYNC.ERROR, { error }),
};

export const postVisitFormulasAlliance = {
  pending: (data) =>
    createAction(types.POST_VISITFORMULASALLIANCE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITFORMULASALLIANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITFORMULASALLIANCE_ASYNC.ERROR, { error }),
};

export const postVisitMedicineFormulasAlliance = {
  pending: (data) =>
    createAction(types.POST_VISITMEDICINEFORMULASALLIANCE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_VISITMEDICINEFORMULASALLIANCE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_VISITMEDICINEFORMULASALLIANCE_ASYNC.ERROR, {
      error,
    }),
};

export const postVisitBranchMedicines = {
  pending: (data) =>
    createAction(types.POST_VISITBRANCHMEDICINES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITBRANCHMEDICINES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITBRANCHMEDICINES_ASYNC.ERROR, { error }),
};

export const getVisitCurrentOrganisation = {
  pending: (data) =>
    createAction(types.GET_VISITCURRENTORGANISATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITCURRENTORGANISATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITCURRENTORGANISATION_ASYNC.ERROR, { error }),
};

export const postVisitSaveStoreOrder = {
  pending: (data) =>
    createAction(types.POST_VISITSAVESTOREORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITSAVESTOREORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITSAVESTOREORDER_ASYNC.ERROR, { error }),
};

export const postVisitSubmitStoreOrder = {
  pending: (data) =>
    createAction(types.POST_VISITSUBMITSTOREORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITSUBMITSTOREORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITSUBMITSTOREORDER_ASYNC.ERROR, { error }),
};

export const getVisitStoreOrderList = {
  pending: (data) =>
    createAction(types.GET_VISITSTOREORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITSTOREORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITSTOREORDERLIST_ASYNC.ERROR, { error }),
};

export const getVisitStoreOrderListLinkedDelivery = {
  pending: (data) =>
    createAction(types.GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC.ERROR, {
      error,
    }),
};
export const getVisitStoreOrderSummaryList = {
  pending: (data) =>
    createAction(types.GET_VISITSTOREORDERSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITSTOREORDERSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITSTOREORDERSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getVisitStoreOrderSummaryListLinkedDelivery = {
  pending: (data) =>
    createAction(
      types.GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getVisitStoreOrderById = {
  pending: (data) =>
    createAction(types.GET_VISITSTOREORDERBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITSTOREORDERBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITSTOREORDERBYID_ASYNC.ERROR, { error }),
};

export const getVisitStoreOrderByIdLinkedDelivery = {
  pending: (data) =>
    createAction(types.GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC.ERROR, {
      error,
    }),
};

export const getVisitStoreOrderByIdPatientHistory = {
  pending: (data) =>
    createAction(types.GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC.ERROR, {
      error,
    }),
};

export const getVisitStoreOrderDashboardSearchList = {
  pending: (data) =>
    createAction(types.GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC.ERROR, {
      error,
    }),
};

export const getPatientVisitStoreOrderList = {
  pending: (data) =>
    createAction(types.GET_PATIENTVISITSTOREORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_PATIENTVISITSTOREORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_PATIENTVISITSTOREORDERLIST_ASYNC.ERROR, { error }),
};

export const putVisitStoreEditMode = {
  pending: (data) =>
    createAction(types.PUT_VISITSTOREEDITMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITSTOREEDITMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITSTOREEDITMODE_ASYNC.ERROR, { error }),
};

export const getVisitAcupoints = {
  pending: (data) =>
    createAction(types.GET_VISITACUPOINTS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITACUPOINTS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITACUPOINTS_ASYNC.ERROR, { error }),
};

export const getVisitTreatmentTypes = {
  pending: (data) =>
    createAction(types.GET_VISITTREATMENTTYPES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITTREATMENTTYPES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITTREATMENTTYPES_ASYNC.ERROR, { error }),
};
export const getVisitPaymentTerms = {
  pending: (data) =>
    createAction(types.GET_VISITPAYMENTTERMS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITPAYMENTTERMS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITPAYMENTTERMS_ASYNC.ERROR, { error }),
};
export const getVisitPaymentMethods = {
  pending: (data) =>
    createAction(types.GET_VISITPAYMENTMETHODS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITPAYMENTMETHODS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITPAYMENTMETHODS_ASYNC.ERROR, { error }),
};

export const postVisitTreatments = {
  pending: (data) =>
    createAction(types.POST_VISITTREATMENTS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITTREATMENTS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITTREATMENTS_ASYNC.ERROR, { error }),
};

export const postVisitEmptyVisitOrder = {
  pending: (data) =>
    createAction(types.POST_VISITEMPTYORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITEMPTYORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITEMPTYORDER_ASYNC.ERROR, { error }),
};

export const getVisitPhysicians = {
  pending: (data) =>
    createAction(types.GET_VISITPHYSICIANS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITPHYSICIANS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITPHYSICIANS_ASYNC.ERROR, { error }),
};

export const postVisitPhysicians = {
  pending: (data) =>
    createAction(types.POST_VISITPHYSICIANS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITPHYSICIANS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITPHYSICIANS_ASYNC.ERROR, { error }),
};

export const getVisitUserList = {
  pending: (data) =>
    createAction(types.GET_VISITUSERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITUSERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITUSERLIST_ASYNC.ERROR, { error }),
};

export const getVisitUserAdvancedSearch = {
  pending: (data) =>
    createAction(types.GET_VISITUSERADVANCEDSEARCH_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITUSERADVANCEDSEARCH_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITUSERADVANCEDSEARCH_ASYNC.ERROR, { error }),
};

export const postVisitCreateUser = {
  pending: (data) =>
    createAction(types.POST_VISITCREATEUSER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITCREATEUSER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITCREATEUSER_ASYNC.ERROR, { error }),
};

export const putVisitUpdateUser = {
  pending: (data) =>
    createAction(types.PUT_VISITUPDATEUSER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITUPDATEUSER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITUPDATEUSER_ASYNC.ERROR, { error }),
};

export const getVisitIsUserCurrentOrganisation = {
  pending: (data) =>
    createAction(types.GET_VISITISUSERCURRENTORGANISATION_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITISUSERCURRENTORGANISATION_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITISUSERCURRENTORGANISATION_ASYNC.ERROR, {
      error,
    }),
};

export const putVisitUpdateUserOrganisation = {
  pending: (data) =>
    createAction(types.PUT_VISITUPDATEUSERORGANISATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITUPDATEUSERORGANISATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITUPDATEUSERORGANISATION_ASYNC.ERROR, { error }),
};

export const putVisitSetArrivalOn = {
  pending: (data) =>
    createAction(types.PUT_VISITSETARRIVALON_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITSETARRIVALON_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITSETARRIVALON_ASYNC.ERROR, { error }),
};

export const putVisitSetDepartureOn = {
  pending: (data) =>
    createAction(types.PUT_VISITSETDEPARTUREON_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITSETDEPARTUREON_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITSETDEPARTUREON_ASYNC.ERROR, { error }),
};

export const putVisitSetConsultOn = {
  pending: (data) =>
    createAction(types.PUT_VISITSETCONSULTON_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITSETCONSULTON_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITSETCONSULTON_ASYNC.ERROR, { error }),
};

export const putVisitSetQueueNumber = {
  pending: (data) =>
    createAction(types.PUT_VISITSETQUEUENUMBER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITSETQUEUENUMBER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITSETQUEUENUMBER_ASYNC.ERROR, { error }),
};

export const putVisitUpdateVisitNotes = {
  pending: (data) =>
    createAction(types.PUT_VISITUPDATEVISITNOTES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITUPDATEVISITNOTES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITUPDATEVISITNOTES_ASYNC.ERROR, { error }),
};

export const putVisitPrintDate = {
  pending: (data) =>
    createAction(types.PUT_VISITPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_VISITPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_VISITPRINTDATE_ASYNC.ERROR, { error }),
};

export const putUpdateUserMedicalHistory = {
  pending: (data) =>
    createAction(types.PUT_UPDATEUSERMEDICALHISTORY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_UPDATEUSERMEDICALHISTORY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_UPDATEUSERMEDICALHISTORY_ASYNC.ERROR, { error }),
};

export const getVisitPrescriptionMsgList = {
  pending: (data) =>
    createAction(types.GET_VISITPRESCRIPTIONMSGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITPRESCRIPTIONMSGLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITPRESCRIPTIONMSGLIST_ASYNC.ERROR, { error }),
};

export const getVisitMedicineInfo = {
  pending: (data) =>
    createAction(types.GET_VISITMEDICINEINFO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITMEDICINEINFO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITMEDICINEINFO_ASYNC.ERROR, { error }),
};

export const getVisitAcupointInfo = {
  pending: (data) =>
    createAction(types.GET_VISITACUPOINTINFO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITACUPOINTINFO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITACUPOINTINFO_ASYNC.ERROR, { error }),
};

export const getVisitTreatmentInfo = {
  pending: (data) =>
    createAction(types.GET_VISITTREATMENTINFO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_VISITTREATMENTINFO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_VISITTREATMENTINFO_ASYNC.ERROR, { error }),
};

export const postVisitChangeBranch = {
  pending: (data) =>
    createAction(types.POST_VISITCHANGEBRANCH_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_VISITCHANGEBRANCH_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_VISITCHANGEBRANCH_ASYNC.ERROR, { error }),
};

export const postVisitChangeBranchWholeMedicine = {
  pending: (data) =>
    createAction(types.POST_VISITCHANGEBRANCHHOLEMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_VISITCHANGEBRANCHHOLEMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_VISITCHANGEBRANCHHOLEMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const postVisitChangeBranchTreatmentOnly = {
  pending: (data) =>
    createAction(types.POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC.ERROR, {
      error,
    }),
};

export const postVisitChangeBranchAcupointOnly = {
  pending: (data) =>
    createAction(types.POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC.ERROR, {
      error,
    }),
};

export const putUpdateVisitAcupoints = {
  pending: (data) =>
    createAction(types.PUT_UPDATEVISITACUPOINTS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.PUT_UPDATEVISITACUPOINTS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.PUT_UPDATEVISITACUPOINTS_ASYNC.ERROR, {
      error,
    }),
};

export const getVisitPostalCodeSurchargeList = {
  pending: (data) =>
    createAction(types.GET_VISITPOSTALCODESURCHARGE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITPOSTALCODESURCHARGE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITPOSTALCODESURCHARGE_ASYNC.ERROR, {
      error,
    }),
};

export const getVisitAvailableProceedOn = {
  pending: (data) =>
    createAction(types.GET_VISITAVAILABLEPROCEEDON_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITAVAILABLEPROCEEDON_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITAVAILABLEPROCEEDON_ASYNC.ERROR, {
      error,
    }),
};

export const getVisitVerifyAddress = {
  pending: (data) =>
    createAction(types.GET_VISITVERIFYADDRESS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITVERIFYADDRESS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITVERIFYADDRESS_ASYNC.ERROR, {
      error,
    }),
};

export const getVisitWikiDataList = {
  pending: (data) =>
    createAction(types.GET_VISITWIKIDATALIST_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_VISITWIKIDATALIST_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_VISITWIKIDATALIST_ASYNC.ERROR, {
      error,
    }),
};
