import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserDeviceKeySummaryPage from "./UserDeviceKeySummary/UserDeviceKeySummaryPage";
export default function UserDeviceKeyPage() {
  return (
    <Switch>
      <Route
        path="/userDeviceKey/UserDeviceKeySummary"
        component={UserDeviceKeySummaryPage}
      />

      <Redirect to="/error" />
    </Switch>
  );
}
