import * as types from "../_constants/reportingConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingInvoiceDetailsListByStoreSuccess: false,
        isLoadingGetReportingInvoiceDetailsListByStore: true,
        reportingInvoiceDetailsListByStore: null,
      };

    case types.GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingInvoiceDetailsListByStoreSuccess: false,
        isLoadingGetReportingInvoiceDetailsListByStore: false,
        reportingInvoiceDetailsListByStore: null,
        error: {
          ...state.error,
          getReportingInvoiceDetailsListByStoreError: action.error,
        },
      };

    case types.GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingInvoiceDetailsListByStoreSuccess: true,
        isLoadingGetReportingInvoiceDetailsListByStore: false,
        reportingInvoiceDetailsListByStore: action.data,
      };

    case types.GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingInvoiceSummaryListByStoreSuccess: false,
        isLoadingGetReportingInvoiceSummaryListByStore: true,
        reportingInvoiceSummaryListByStore: null,
      };

    case types.GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingInvoiceSummaryListByStoreSuccess: false,
        isLoadingGetReportingInvoiceSummaryListByStore: false,
        reportingInvoiceSummaryListByStore: null,
        error: {
          ...state.error,
          getReportingInvoiceSummaryListByStoreError: action.error,
        },
      };

    case types.GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingInvoiceSummaryListByStoreSuccess: true,
        isLoadingGetReportingInvoiceSummaryListByStore: false,
        reportingInvoiceSummaryListByStore: action.data,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
      .PENDING:
      return {
        ...state,
        getReportingInvoiceMedicineCostingDetailsListByStoreSuccess: false,
        isLoadingGetReportingInvoiceMedicineCostingDetailsListByStore: true,
        reportingInvoiceMedicineCostingDetailsListByStore: null,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
      .ERROR:
      return {
        ...state,
        getReportingInvoiceMedicineCostingDetailsListByStoreSuccess: false,
        isLoadingGetReportingInvoiceMedicineCostingDetailsListByStore: false,
        reportingInvoiceMedicineCostingDetailsListByStore: null,
        error: {
          ...state.error,
          getReportingInvoiceMedicineCostingDetailsListByStoreError:
            action.error,
        },
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
      .SUCCESS:
      return {
        ...state,
        getReportingInvoiceMedicineCostingDetailsListByStoreSuccess: true,
        isLoadingGetReportingInvoiceMedicineCostingDetailsListByStore: false,
        reportingInvoiceMedicineCostingDetailsListByStore: action.data,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingInvoiceMedicineCostingListByStoreSuccess: false,
        isLoadingGetGetReportingInvoiceMedicineCostingListByStore: true,
        reportingInvoiceMedicineCostingListByStore: null,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingInvoiceMedicineCostingListByStoreSuccess: false,
        isLoadingGetGetReportingInvoiceMedicineCostingListByStore: false,
        reportingInvoiceMedicineCostingListByStore: null,
        error: {
          ...state.error,
          getReportingInvoiceMedicineCostingListByStoreError: action.error,
        },
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingInvoiceMedicineCostingListByStoreSuccess: true,
        isLoadingGetGetReportingInvoiceMedicineCostingListByStore: false,
        reportingInvoiceMedicineCostingListByStore: action.data,
      };

    case types.GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingInvoiceDetailsListByVisitSuccess: false,
        isLoadingGetReportingInvoiceDetailsListByVisit: true,
        reportingInvoiceDetailsListByVisit: null,
      };

    case types.GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingInvoiceDetailsListByVisitSuccess: false,
        isLoadingGetReportingInvoiceDetailsListByVisit: false,
        reportingInvoiceDetailsListByVisit: null,
        error: {
          ...state.error,
          getReportingInvoiceDetailsListByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingInvoiceDetailsListByVisitSuccess: true,
        isLoadingGetReportingInvoiceDetailsListByVisit: false,
        reportingInvoiceDetailsListByVisit: action.data,
      };

    case types.GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingInvoiceSummaryListByVisitSuccess: false,
        isLoadingGetReportingInvoiceSummaryListByVisit: true,
        reportingInvoiceSummaryListByVisit: null,
      };

    case types.GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingInvoiceSummaryListByVisitSuccess: false,
        isLoadingGetReportingInvoiceSummaryListByVisit: false,
        reportingInvoiceSummaryListByVisit: null,
        error: {
          ...state.error,
          getReportingInvoiceSummaryListByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingInvoiceSummaryListByVisitSuccess: true,
        isLoadingGetReportingInvoiceSummaryListByVisit: false,
        reportingInvoiceSummaryListByVisit: action.data,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
      .PENDING:
      return {
        ...state,
        getReportingInvoiceMedicineCostingDetailsListByVisitSuccess: false,
        isLoadingGetReportingInvoiceMedicineCostingDetailsListByVisit: true,
        reportingInvoiceMedicineCostingDetailsListByVisit: null,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
      .ERROR:
      return {
        ...state,
        getReportingInvoiceMedicineCostingDetailsListByVisitSuccess: false,
        isLoadingGetReportingInvoiceMedicineCostingDetailsListByVisit: false,
        reportingInvoiceMedicineCostingDetailsListByVisit: null,
        error: {
          ...state.error,
          getReportingInvoiceMedicineCostingDetailsListByVisitError:
            action.error,
        },
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
      .SUCCESS:
      return {
        ...state,
        getReportingInvoiceMedicineCostingDetailsListByVisitSuccess: true,
        isLoadingGetReportingInvoiceMedicineCostingDetailsListByVisit: false,
        reportingInvoiceMedicineCostingDetailsListByVisit: action.data,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingInvoiceMedicineCostingListByVisitSuccess: false,
        isLoadingGetReportingInvoiceMedicineCostingListByVisit: true,
        reportingInvoiceMedicineCostingListByVisit: null,
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingInvoiceMedicineCostingListByVisitSuccess: false,
        isLoadingGetReportingInvoiceMedicineCostingListByVisit: false,
        reportingInvoiceMedicineCostingListByVisit: null,
        error: {
          ...state.error,
          getReportingInvoiceMedicineCostingListByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingInvoiceMedicineCostingListByVisitSuccess: true,
        isLoadingGetReportingInvoiceMedicineCostingListByVisit: false,
        reportingInvoiceMedicineCostingListByVisit: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportInvoiceDetailsListByStoreSuccess: false,
        isLoadingGetReportingExportInvoiceDetailsListByStore: true,
        reportingExportInvoiceDetailsListByStore: null,
      };

    case types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportInvoiceDetailsListByStoreSuccess: false,
        isLoadingGetReportingExportInvoiceDetailsListByStore: false,
        reportingExportInvoiceDetailsListByStore: null,
        error: {
          ...state.error,
          getReportingExportInvoiceDetailsListByStoreError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceDetailsListByStoreSuccess: true,
        isLoadingGetReportingExportInvoiceDetailsListByStore: false,
        reportingExportInvoiceDetailsListByStore: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportInvoiceSummaryListByStoreSuccess: false,
        isLoadingGetReportingExportInvoiceSummaryListByStore: true,
        reportingExportInvoiceSummaryListByStore: null,
      };

    case types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportInvoiceSummaryListByStoreSuccess: false,
        isLoadingGetReportingExportInvoiceSummaryListByStore: false,
        reportingExportInvoiceSummaryListByStore: null,
        error: {
          ...state.error,
          getReportingExportInvoiceSummaryListByStoreError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceSummaryListByStoreSuccess: true,
        isLoadingGetReportingExportInvoiceSummaryListByStore: false,
        reportingExportInvoiceSummaryListByStore: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
      .PENDING:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingDetailsListByStoreSuccess: false,
        isLoadingGetReportingExportInvoiceMedicineCostingDetailsListByStore: true,
        reportingExportInvoiceMedicineCostingDetailsListByStore: null,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
      .ERROR:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingDetailsListByStoreSuccess: false,
        isLoadingGetReportingExportInvoiceMedicineCostingDetailsListByStore: false,
        reportingExportInvoiceMedicineCostingDetailsListByStore: null,
        error: {
          ...state.error,
          getReportingExportInvoiceMedicineCostingDetailsListByStoreError:
            action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
      .SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingDetailsListByStoreSuccess: true,
        isLoadingGetReportingExportInvoiceMedicineCostingDetailsListByStore: false,
        reportingExportInvoiceMedicineCostingDetailsListByStore: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC
      .PENDING:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingListByStoreSuccess: false,
        isLoadingGetGetReportingExportInvoiceMedicineCostingListByStore: true,
        reportingExportInvoiceMedicineCostingListByStore: null,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingListByStoreSuccess: false,
        isLoadingGetGetReportingExportInvoiceMedicineCostingListByStore: false,
        reportingExportInvoiceMedicineCostingListByStore: null,
        error: {
          ...state.error,
          getReportingExportInvoiceMedicineCostingListByStoreError:
            action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC
      .SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingListByStoreSuccess: true,
        isLoadingGetGetReportingExportInvoiceMedicineCostingListByStore: false,
        reportingExportInvoiceMedicineCostingListByStore: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportInvoiceDetailsListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceDetailsListByVisit: true,
        reportingExportInvoiceDetailsListByVisit: null,
      };

    case types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportInvoiceDetailsListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceDetailsListByVisit: false,
        reportingExportInvoiceDetailsListByVisit: null,
        error: {
          ...state.error,
          getReportingExportInvoiceDetailsListByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceDetailsListByVisitSuccess: true,
        isLoadingGetReportingExportInvoiceDetailsListByVisit: false,
        reportingExportInvoiceDetailsListByVisit: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportInvoiceSummaryListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceSummaryListByVisit: true,
        reportingExportInvoiceSummaryListByVisit: null,
      };

    case types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportInvoiceSummaryListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceSummaryListByVisit: false,
        reportingExportInvoiceSummaryListByVisit: null,
        error: {
          ...state.error,
          getReportingExportInvoiceSummaryListByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceSummaryListByVisitSuccess: true,
        isLoadingGetReportingExportInvoiceSummaryListByVisit: false,
        reportingExportInvoiceSummaryListByVisit: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
      .PENDING:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingDetailsListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceMedicineCostingDetailsListByVisit: true,
        reportingExportInvoiceMedicineCostingDetailsListByVisit: null,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
      .ERROR:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingDetailsListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceMedicineCostingDetailsListByVisit: false,
        reportingExportInvoiceMedicineCostingDetailsListByVisit: null,
        error: {
          ...state.error,
          getReportingExportInvoiceMedicineCostingDetailsListByVisitError:
            action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
      .SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingDetailsListByVisitSuccess: true,
        isLoadingGetReportingExportInvoiceMedicineCostingDetailsListByVisit: false,
        reportingExportInvoiceMedicineCostingDetailsListByVisit: action.data,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC
      .PENDING:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceMedicineCostingListByVisit: true,
        reportingExportInvoiceMedicineCostingListByVisit: null,
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingListByVisitSuccess: false,
        isLoadingGetReportingExportInvoiceMedicineCostingListByVisit: false,
        reportingExportInvoiceMedicineCostingListByVisit: null,
        error: {
          ...state.error,
          getReportingExportInvoiceMedicineCostingListByVisitError:
            action.error,
        },
      };

    case types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC
      .SUCCESS:
      return {
        ...state,
        getReportingExportInvoiceMedicineCostingListByVisitSuccess: true,
        isLoadingGetReportingExportInvoiceMedicineCostingListByVisit: false,
        reportingExportInvoiceMedicineCostingListByVisit: action.data,
      };

    case types.GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportStoreMedicineHeaderSuccess: false,
        isLoadingGetReportingExportStoreMedicineHeader: true,
        reportingExportStoreMedicineHeader: null,
      };

    case types.GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportStoreMedicineHeaderSuccess: false,
        isLoadingGetReportingExportStoreMedicineHeader: false,
        reportingExportStoreMedicineHeader: null,
        error: {
          ...state.error,
          getReportingExportStoreMedicineHeaderError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportStoreMedicineHeaderSuccess: true,
        isLoadingGetReportingExportStoreMedicineHeader: false,
        reportingExportStoreMedicineHeader: action.data,
      };

    case types.GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportMedicineInOutSuccess: false,
        isLoadingGetReportingExportMedicineInOut: true,
        reportingExportMedicineInOut: null,
      };

    case types.GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportMedicineInOutSuccess: false,
        isLoadingGetReportingExportMedicineInOut: false,
        reportingExportMedicineInOut: null,
        error: {
          ...state.error,
          getReportingExportMedicineInOutError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportMedicineInOutSuccess: true,
        isLoadingGetReportingExportMedicineInOut: false,
        reportingExportMedicineInOut: action.data,
      };

    case types.GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportMedicineInOutDetailsSuccess: false,
        isLoadingGetReportingExportMedicineInOutDetails: true,
        reportingExportMedicineInOutDetails: null,
      };

    case types.GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportMedicineInOutDetailsSuccess: false,
        isLoadingGetReportingExportMedicineInOutDetails: false,
        reportingExportMedicineInOutDetails: null,
        error: {
          ...state.error,
          getReportingExportMedicineInOutDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportMedicineInOutDetailsSuccess: true,
        isLoadingGetReportingExportMedicineInOutDetails: false,
        reportingExportMedicineInOutDetails: action.data,
      };
    case types.GET_REPORTINGEXPORTACUPOINTS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportAcupointsIsSuccess: false,
        isLoadingGetReportingExportAcupoints: true,
        getReportingExportAcupoints: null,
      };

    case types.GET_REPORTINGEXPORTACUPOINTS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportAcupointsIsSuccess: false,
        isLoadingGetReportingExportAcupoints: false,
        getReportingExportAcupoints: null,
        error: {
          ...state.error,
          getReportingExportAcupointsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTACUPOINTS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportAcupointsIsSuccess: true,
        isLoadingGetReportingExportAcupoints: false,
        getReportingExportAcupoints: action.data,
      };

    case types.POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyCSVAcupointsIsSuccess: false,
        isLoadingPostReportingVerifyCSVAcupoints: true,
        postReportingVerifyCSVAcupoints: null,
      };

    case types.POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyCSVAcupointsIsSuccess: false,
        isLoadingPostReportingVerifyCSVAcupoints: false,
        postReportingVerifyCSVAcupoints: null,
        error: {
          ...state.error,
          postReportingVerifyCSVAcupointsError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyCSVAcupointsIsSuccess: true,
        isLoadingPostReportingVerifyCSVAcupoints: false,
        postReportingVerifyCSVAcupoints: action.data,
      };

    case types.POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportCSVAcupointsIsSuccess: false,
        isLoadingPostReportingImportCSVAcupoints: true,
        postReportingImportCSVAcupoints: null,
      };

    case types.POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportCSVAcupointsIsSuccess: false,
        isLoadingPostReportingImportCSVAcupoints: false,
        postReportingImportCSVAcupoints: null,
        error: {
          ...state.error,
          postReportingVerifyCSVAcupointsError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportCSVAcupointsIsSuccess: true,
        isLoadingPostReportingImportCSVAcupoints: false,
        postReportingImportCSVAcupoints: action.data,
      };

    case types.GET_REPORTINGEXPORTTREATMENTS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportTreatmentsIsSuccess: false,
        isLoadingGetReportingExportTreatments: true,
        getReportingExportTreatments: null,
      };

    case types.GET_REPORTINGEXPORTTREATMENTS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportTreatmentsIsSuccess: false,
        isLoadingGetReportingExportTreatments: false,
        getReportingExportTreatments: null,
        error: {
          ...state.error,
          getReportingExportTreatmentsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTTREATMENTS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportTreatmentsIsSuccess: true,
        isLoadingGetReportingExportTreatments: false,
        getReportingExportTreatments: action.data,
      };

    case types.POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyCSVTreatmentsIsSuccess: false,
        isLoadingPostReportingVerifyCSVTreatments: true,
        postReportingVerifyCSVTreatments: null,
      };

    case types.POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyCSVTreatmentsIsSuccess: false,
        isLoadingPostReportingVerifyCSVTreatments: false,
        postReportingVerifyCSVTreatments: null,
        error: {
          ...state.error,
          postReportingVerifyCSVTreatmentsError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyCSVTreatmentsIsSuccess: true,
        isLoadingPostReportingVerifyCSVTreatments: false,
        postReportingVerifyCSVTreatments: action.data,
      };

    case types.POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportCSVTreatmentsIsSuccess: false,
        isLoadingPostReportingImportCSVTreatments: true,
        postReportingImportCSVTreatments: null,
      };

    case types.POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportCSVTreatmentsIsSuccess: false,
        isLoadingPostReportingImportCSVTreatments: false,
        postReportingImportCSVTreatments: null,
        error: {
          ...state.error,
          postReportingVerifyCSVTreatmentsError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportCSVTreatmentsIsSuccess: true,
        isLoadingPostReportingImportCSVTreatments: false,
        postReportingImportCSVTreatments: action.data,
      };

    case types.GET_REPORTINGEXPORTMEDICINES_ASYNC.PENDING:
      return {
        ...state,
        postReportingExportCSVMedicinesIsSuccess: false,
        isLoadingPostReportingExportCSVMedicines: true,
        postReportingExportCSVMedicines: null,
      };

    case types.GET_REPORTINGEXPORTMEDICINES_ASYNC.ERROR:
      return {
        ...state,
        postReportingExportCSVMedicinesIsSuccess: false,
        isLoadingPostReportingExportCSVMedicines: false,
        postReportingExportCSVMedicines: null,
        error: {
          ...state.error,
          postReportingExportCSVMedicinesError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTMEDICINES_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingExportCSVMedicinesIsSuccess: true,
        isLoadingPostReportingExportCSVMedicines: false,
        postReportingExportCSVMedicines: action.data,
      };

    case types.POST_REPORTINGVERIFYCSVMEDICINES_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyCSVMedicinesIsSuccess: false,
        isLoadingPostReportingVerifyCSVMedicines: true,
        postReportingVerifyCSVMedicines: null,
      };

    case types.POST_REPORTINGVERIFYCSVMEDICINES_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyCSVMedicinesIsSuccess: false,
        isLoadingPostReportingVerifyCSVMedicines: false,
        postReportingVerifyCSVMedicines: null,
        error: {
          ...state.error,
          postReportingVerifyCSVMedicinesError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYCSVMEDICINES_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyCSVMedicinesIsSuccess: true,
        isLoadingPostReportingVerifyCSVMedicines: false,
        postReportingVerifyCSVMedicines: action.data,
      };

    case types.POST_REPORTINGIMPORTCSVMEDICINES_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportCSVMedicinesIsSuccess: false,
        isLoadingPostReportingImportCSVMedicines: true,
        postReportingImportCSVMedicines: null,
      };

    case types.POST_REPORTINGIMPORTCSVMEDICINES_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportCSVMedicinesIsSuccess: false,
        isLoadingPostReportingImportCSVMedicines: false,
        postReportingImportCSVMedicines: null,
        error: {
          ...state.error,
          postReportingVerifyCSVMedicinesError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTCSVMEDICINES_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportCSVMedicinesIsSuccess: true,
        isLoadingPostReportingImportCSVMedicines: false,
        postReportingImportCSVMedicines: action.data,
      };

    case types.GET_REPORTINGMEDICINESHEADERNAMES_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingMedicinesHeaderNamesIsSuccess: true,
        isLoadingGetReportingMedicinesHeaderNames: false,
        getReportingMedicinesHeaderNames: action.data,
      };

    case types.GET_REPORTINGMEDICINESHEADERNAMES_ASYNC.PENDING:
      return {
        ...state,
        getReportingMedicinesHeaderNamesIsSuccess: false,
        isLoadingGetReportingMedicinesHeaderNames: true,
        getReportingMedicinesHeaderNames: null,
      };

    case types.GET_REPORTINGMEDICINESHEADERNAMES_ASYNC.ERROR:
      return {
        ...state,
        getReportingMedicinesHeaderNamesIsSuccess: false,
        isLoadingGetReportingMedicinesHeaderNames: false,
        getReportingMedicinesHeaderNames: null,
        error: {
          ...state.error,
          getReportingMedicinesHeaderNamesError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYMEDICINESWCOLUMNS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyMedicinesWColumnsIsSuccess: true,
        isLoadingPostReportingVerifyMedicinesWColumns: false,
        postReportingVerifyMedicinesWColumns: action.data,
      };

    case types.POST_REPORTINGVERIFYMEDICINESWCOLUMNS_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyMedicinesWColumnsIsSuccess: false,
        isLoadingPostReportingVerifyMedicinesWColumns: true,
        postReportingVerifyMedicinesWColumns: null,
      };

    case types.POST_REPORTINGVERIFYMEDICINESWCOLUMNS_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyMedicinesWColumnsIsSuccess: false,
        isLoadingPostReportingVerifyMedicinesWColumns: false,
        postReportingVerifyMedicinesWColumns: null,
        error: {
          ...state.error,
          postReportingVerifyMedicinesWColumnsError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTMEDICINESWCOLUMNS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportMedicinesWColumnsIsSuccess: true,
        isLoadingPostReportingImportMedicinesWColumns: false,
        postReportingImportMedicinesWColumns: action.data,
      };

    case types.POST_REPORTINGIMPORTMEDICINESWCOLUMNS_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportMedicinesWColumnsIsSuccess: false,
        isLoadingPostReportingImportMedicinesWColumns: true,
        postReportingImportMedicinesWColumns: null,
      };

    case types.POST_REPORTINGIMPORTMEDICINESWCOLUMNS_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportMedicinesWColumnsIsSuccess: false,
        isLoadingPostReportingImportMedicinesWColumns: false,
        postReportingImportMedicinesWColumns: null,
        error: {
          ...state.error,
          postReportingImportMedicinesWColumnsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTFORMULAS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingExportCSVFormulasIsSuccess: true,
        isLoadingPostReportingExportCSVFormulas: false,
        postReportingExportCSVFormulas: action.data,
      };

    case types.POST_REPORTINGVERIFYCSVFORMULAS_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyCSVFormulasIsSuccess: false,
        isLoadingPostReportingVerifyCSVFormulas: true,
        postReportingVerifyCSVFormulas: null,
      };

    case types.POST_REPORTINGVERIFYCSVFORMULAS_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyCSVFormulasIsSuccess: false,
        isLoadingPostReportingVerifyCSVFormulas: false,
        postReportingVerifyCSVFormulas: null,
        error: {
          ...state.error,
          postReportingVerifyCSVFormulasError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYCSVFORMULAS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyCSVFormulasIsSuccess: true,
        isLoadingPostReportingVerifyCSVFormulas: false,
        postReportingVerifyCSVFormulas: action.data,
      };

    case types.POST_REPORTINGIMPORTCSVFORMULAS_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportCSVFormulasIsSuccess: false,
        isLoadingPostReportingImportCSVFormulas: true,
        postReportingImportCSVFormulas: null,
      };

    case types.POST_REPORTINGIMPORTCSVFORMULAS_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportCSVFormulasIsSuccess: false,
        isLoadingPostReportingImportCSVFormulas: false,
        postReportingImportCSVFormulas: null,
        error: {
          ...state.error,
          postReportingVerifyCSVFormulasError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTCSVFORMULAS_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportCSVFormulasIsSuccess: true,
        isLoadingPostReportingImportCSVFormulas: false,
        postReportingImportCSVFormulas: action.data,
      };

    case types.GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postReportingExportCSVFormulaMedicineIsSuccess: false,
        isLoadingPostReportingExportCSVFormulaMedicine: true,
        postReportingExportCSVFormulaMedicine: null,
      };

    case types.GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postReportingExportCSVFormulaMedicineIsSuccess: false,
        isLoadingPostReportingExportCSVFormulaMedicine: false,
        postReportingExportCSVFormulaMedicine: null,
        error: {
          ...state.error,
          postReportingExportCSVFormulaMedicineError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingExportCSVFormulaMedicineIsSuccess: true,
        isLoadingPostReportingExportCSVFormulaMedicine: false,
        postReportingExportCSVFormulaMedicine: action.data,
      };

    case types.POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyCSVFormulaMedicineIsSuccess: false,
        isLoadingPostReportingVerifyCSVFormulaMedicine: true,
        postReportingVerifyCSVFormulaMedicine: null,
      };

    case types.POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyCSVFormulaMedicineIsSuccess: false,
        isLoadingPostReportingVerifyCSVFormulaMedicine: false,
        postReportingVerifyCSVFormulaMedicine: null,
        error: {
          ...state.error,
          postReportingVerifyCSVFormulaMedicineError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyCSVFormulaMedicineIsSuccess: true,
        isLoadingPostReportingVerifyCSVFormulaMedicine: false,
        postReportingVerifyCSVFormulaMedicine: action.data,
      };

    case types.POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportCSVFormulaMedicineIsSuccess: false,
        isLoadingPostReportingImportCSVFormulaMedicine: true,
        postReportingImportCSVFormulaMedicine: null,
      };

    case types.POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportCSVFormulaMedicineIsSuccess: false,
        isLoadingPostReportingImportCSVFormulaMedicine: false,
        postReportingImportCSVFormulaMedicine: null,
        error: {
          ...state.error,
          postReportingVerifyCSVFormulaMedicineError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportCSVFormulaMedicineIsSuccess: true,
        isLoadingPostReportingImportCSVFormulaMedicine: false,
        postReportingImportCSVFormulaMedicine: action.data,
      };

    case types.GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportStoreMedicineDetailsIsSuccess: false,
        isLoadingGetReportingExportStoreMedicineDetails: true,
        getReportingExportStoreMedicineDetails: null,
      };

    case types.GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportStoreMedicineDetailsIsSuccess: false,
        isLoadingGetReportingExportStoreMedicineDetails: false,
        getReportingExportStoreMedicineDetails: null,
        error: {
          ...state.error,
          getReportingExportStoreMedicineDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportStoreMedicineDetailsIsSuccess: true,
        isLoadingGetReportingExportStoreMedicineDetails: false,
        getReportingExportStoreMedicineDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportVisitNotesDetailsIsSuccess: false,
        isLoadingGetReportingExportVisitNotesDetails: true,
        getReportingExportVisitNotesDetails: null,
      };

    case types.GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportVisitNotesDetailsIsSuccess: false,
        isLoadingGetReportingExportVisitNotesDetails: false,
        getReportingExportVisitNotesDetails: null,
        error: {
          ...state.error,
          getReportingExportVisitNotesDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportVisitNotesDetailsIsSuccess: true,
        isLoadingGetReportingExportVisitNotesDetails: false,
        getReportingExportVisitNotesDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportBranchMedicineIsSuccess: false,
        isLoadingGetReportingExportBranchMedicine: true,
        getReportingExportBranchMedicine: null,
      };

    case types.GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportBranchMedicineIsSuccess: false,
        isLoadingGetReportingExportBranchMedicine: false,
        getReportingExportBranchMedicine: null,
        error: {
          ...state.error,
          getReportingExportBranchMedicineError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportBranchMedicineIsSuccess: true,
        isLoadingGetReportingExportBranchMedicine: false,
        getReportingExportBranchMedicine: action.data,
      };

    case types.POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyCSVBranchMedicineIsSuccess: false,
        isLoadingPostReportingVerifyCSVBranchMedicine: true,
        postReportingVerifyCSVBranchMedicine: null,
      };

    case types.POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyCSVBranchMedicineIsSuccess: false,
        isLoadingPostReportingVerifyCSVBranchMedicine: false,
        postReportingVerifyCSVBranchMedicine: null,
        error: {
          ...state.error,
          postReportingVerifyCSVBranchMedicineError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyCSVBranchMedicineIsSuccess: true,
        isLoadingPostReportingVerifyCSVBranchMedicine: false,
        postReportingVerifyCSVBranchMedicine: action.data,
      };

    case types.POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportCSVBranchMedicineIsSuccess: false,
        isLoadingPostReportingImportCSVBranchMedicine: true,
        postReportingImportCSVBranchMedicine: null,
      };

    case types.POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportCSVBranchMedicineIsSuccess: false,
        isLoadingPostReportingImportCSVBranchMedicine: false,
        postReportingImportCSVBranchMedicine: null,
        error: {
          ...state.error,
          postReportingVerifyCSVBranchMedicineError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportCSVBranchMedicineIsSuccess: true,
        isLoadingPostReportingImportCSVBranchMedicine: false,
        postReportingImportCSVBranchMedicine: action.data,
      };

    case types.GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportFixedMedicinePriceIsSuccess: false,
        isLoadingGetReportingExportFixedMedicinePrice: true,
        getReportingExportFixedMedicinePrice: null,
      };

    case types.GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportFixedMedicinePriceIsSuccess: false,
        isLoadingGetReportingExportFixedMedicinePrice: false,
        getReportingExportFixedMedicinePrice: null,
        error: {
          ...state.error,
          getReportingExportFixedMedicinePriceError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportFixedMedicinePriceIsSuccess: true,
        isLoadingGetReportingExportFixedMedicinePrice: false,
        getReportingExportFixedMedicinePrice: action.data,
      };

    case types.POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyCSVFixedMedicinePriceIsSuccess: false,
        isLoadingPostReportingVerifyCSVFixedMedicinePrice: true,
        postReportingVerifyCSVFixedMedicinePrice: null,
      };

    case types.POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyCSVFixedMedicinePriceIsSuccess: false,
        isLoadingPostReportingVerifyCSVFixedMedicinePrice: false,
        postReportingVerifyCSVFixedMedicinePrice: null,
        error: {
          ...state.error,
          postReportingVerifyCSVFixedMedicinePriceError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyCSVFixedMedicinePriceIsSuccess: true,
        isLoadingPostReportingVerifyCSVFixedMedicinePrice: false,
        postReportingVerifyCSVFixedMedicinePrice: action.data,
      };

    case types.POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportCSVFixedMedicinePriceIsSuccess: false,
        isLoadingPostReportingImportCSVFixedMedicinePrice: true,
        postReportingImportCSVFixedMedicinePrice: null,
      };

    case types.POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportCSVFixedMedicinePriceIsSuccess: false,
        isLoadingPostReportingImportCSVFixedMedicinePrice: false,
        postReportingImportCSVFixedMedicinePrice: null,
        error: {
          ...state.error,
          postReportingImportCSVFixedMedicinePriceError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportCSVFixedMedicinePriceIsSuccess: true,
        isLoadingPostReportingImportCSVFixedMedicinePrice: false,
        postReportingImportCSVFixedMedicinePrice: action.data,
      };

    case types.POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC.PENDING:
      return {
        ...state,
        postReportingVerifyFixedMedicinePriceSyncIsSuccess: false,
        isLoadingPostReportingVerifyFixedMedicinePriceSync: true,
        postReportingVerifyFixedMedicinePriceSync: null,
      };

    case types.POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC.ERROR:
      return {
        ...state,
        postReportingVerifyFixedMedicinePriceSyncIsSuccess: false,
        isLoadingPostReportingVerifyFixedMedicinePriceSync: false,
        postReportingVerifyFixedMedicinePriceSync: null,
        error: {
          ...state.error,
          postReportingVerifyFixedMedicinePriceSyncError: action.error,
        },
      };

    case types.POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingVerifyFixedMedicinePriceSyncIsSuccess: true,
        isLoadingPostReportingVerifyFixedMedicinePriceSync: false,
        postReportingVerifyFixedMedicinePriceSync: action.data,
      };

    case types.POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC.PENDING:
      return {
        ...state,
        postReportingImportFixedMedicinePriceSyncIsSuccess: false,
        isLoadingPostReportingImportFixedMedicinePriceSync: true,
        postReportingImportFixedMedicinePriceSync: null,
      };

    case types.POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC.ERROR:
      return {
        ...state,
        postReportingImportFixedMedicinePriceSyncIsSuccess: false,
        isLoadingPostReportingImportFixedMedicinePriceSync: false,
        postReportingImportFixedMedicinePriceSync: null,
        error: {
          ...state.error,
          postReportingImportFixedMedicinePriceSyncError: action.error,
        },
      };

    case types.POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC.SUCCESS:
      return {
        ...state,
        postReportingImportFixedMedicinePriceSyncIsSuccess: true,
        isLoadingPostReportingImportFixedMedicinePriceSync: false,
        postReportingImportFixedMedicinePriceSync: action.data,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFixedSellingPriceHeaderIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceHeader: true,
        getMedicineFixedSellingPriceHeader: null,
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFixedSellingPriceHeaderIsSuccess: false,
        isLoadingGetMedicineFixedSellingPriceHeader: false,
        getMedicineFixedSellingPriceHeader: null,
        error: {
          ...state.error,
          getMedicineFixedSellingPriceHeaderError: action.error,
        },
      };

    case types.GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFixedSellingPriceHeaderIsSuccess: true,
        isLoadingGetMedicineFixedSellingPriceHeader: false,
        getMedicineFixedSellingPriceHeader: action.data,
      };

    case types.GET_REPORTINGEXPORTUSERCREDITS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportUserCreditsIsSuccess: false,
        isLoadingGetReportingExportUserCredits: true,
        getReportingExportUserCredits: null,
      };

    case types.GET_REPORTINGEXPORTUSERCREDITS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportUserCreditsIsSuccess: false,
        isLoadingGetReportingExportUserCredits: false,
        getReportingExportUserCredits: null,
        error: {
          ...state.error,
          getReportingExportUserCreditsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTUSERCREDITS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportUserCreditsIsSuccess: true,
        isLoadingGetReportingExportUserCredits: false,
        getReportingExportUserCredits: action.data,
      };

    case types.GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportUserCreditsStatementIsSuccess: false,
        isLoadingGetReportingExportUserCreditsStatement: true,
        getReportingExportUserCreditsStatement: null,
      };

    case types.GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportUserCreditsStatementIsSuccess: false,
        isLoadingGetReportingExportUserCreditsStatement: false,
        getReportingExportUserCreditsStatement: null,
        error: {
          ...state.error,
          getReportingExportUserCreditsStatementError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportUserCreditsStatementIsSuccess: true,
        isLoadingGetReportingExportUserCreditsStatement: false,
        getReportingExportUserCreditsStatement: action.data,
      };

    case types.GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingBillAbleInvoiceByStoreIsSuccess: false,
        isLoadingGetReportingBillAbleInvoiceByStore: true,
        getReportingBillAbleInvoiceByStore: null,
      };

    case types.GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingBillAbleInvoiceByStoreIsSuccess: false,
        isLoadingGetReportingBillAbleInvoiceByStore: false,
        getReportingBillAbleInvoiceByStore: null,
        error: {
          ...state.error,
          getReportingBillAbleInvoiceByStoreError: action.error,
        },
      };

    case types.GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingBillAbleInvoiceByStoreIsSuccess: true,
        isLoadingGetReportingBillAbleInvoiceByStore: false,
        getReportingBillAbleInvoiceByStore: action.data,
      };

    case types.GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportBillAbleInvoiceByStoreIsSuccess: false,
        isLoadingGetReportingExportBillAbleInvoiceByStore: true,
        getReportingExportBillAbleInvoiceByStore: null,
      };

    case types.GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportBillAbleInvoiceByStoreIsSuccess: false,
        isLoadingGetReportingExportBillAbleInvoiceByStore: false,
        getReportingExportBillAbleInvoiceByStore: null,
        error: {
          ...state.error,
          getReportingExportBillAbleInvoiceByStoreError: action.error,
        },
      };

    case types.GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportBillAbleInvoiceByStoreIsSuccess: true,
        isLoadingGetReportingExportBillAbleInvoiceByStore: false,
        getReportingExportBillAbleInvoiceByStore: action.data,
      };

    case types.GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingBillAbleInvoiceByVisitIsSuccess: false,
        isLoadingGetReportingBillAbleInvoiceByVisit: true,
        getReportingBillAbleInvoiceByVisit: null,
      };

    case types.GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingBillAbleInvoiceByVisitIsSuccess: false,
        isLoadingGetReportingBillAbleInvoiceByVisit: false,
        getReportingBillAbleInvoiceByVisit: null,
        error: {
          ...state.error,
          getReportingBillAbleInvoiceByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingBillAbleInvoiceByVisitIsSuccess: true,
        isLoadingGetReportingBillAbleInvoiceByVisit: false,
        getReportingBillAbleInvoiceByVisit: action.data,
      };

    case types.GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportBillAbleInvoiceByVisitIsSuccess: false,
        isLoadingGetReportingExportBillAbleInvoiceByVisit: true,
        getReportingExportBillAbleInvoiceByVisit: null,
      };

    case types.GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportBillAbleInvoiceByVisitIsSuccess: false,
        isLoadingGetReportingExportBillAbleInvoiceByVisit: false,
        getReportingExportBillAbleInvoiceByVisit: null,
        error: {
          ...state.error,
          getReportingExportBillAbleInvoiceByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportBillAbleInvoiceByVisitIsSuccess: true,
        isLoadingGetReportingExportBillAbleInvoiceByVisit: false,
        getReportingExportBillAbleInvoiceByVisit: action.data,
      };

    case types.GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingBillAbleIMCByStoreIsSuccess: false,
        isLoadingGetReportingBillAbleIMCByStore: true,
        getReportingBillAbleIMCByStore: null,
      };

    case types.GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingBillAbleIMCByStoreIsSuccess: false,
        isLoadingGetReportingBillAbleIMCByStore: false,
        getReportingBillAbleIMCByStore: null,
        error: {
          ...state.error,
          getReportingBillAbleIMCByStoreError: action.error,
        },
      };

    case types.GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingBillAbleIMCByStoreIsSuccess: true,
        isLoadingGetReportingBillAbleIMCByStore: false,
        getReportingBillAbleIMCByStore: action.data,
      };

    case types.GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportBillAbleIMCByStoreIsSuccess: false,
        isLoadingGetReportingExportBillAbleIMCByStore: true,
        getReportingExportBillAbleIMCByStore: null,
      };

    case types.GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportBillAbleIMCByStoreIsSuccess: false,
        isLoadingGetReportingExportBillAbleIMCByStore: false,
        getReportingExportBillAbleIMCByStore: null,
        error: {
          ...state.error,
          getReportingExportBillAbleIMCByStoreError: action.error,
        },
      };

    case types.GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportBillAbleIMCByStoreIsSuccess: true,
        isLoadingGetReportingExportBillAbleIMCByStore: false,
        getReportingExportBillAbleIMCByStore: action.data,
      };

    case types.GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingBillAbleIMCByVisitIsSuccess: false,
        isLoadingGetReportingBillAbleIMCByVisit: true,
        getReportingBillAbleIMCByVisit: null,
      };

    case types.GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingBillAbleIMCByVisitIsSuccess: false,
        isLoadingGetReportingBillAbleIMCByVisit: false,
        getReportingBillAbleIMCByVisit: null,
        error: {
          ...state.error,
          getReportingBillAbleIMCByVisitError: action.error,
        },
      };

    case types.GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingBillAbleIMCByVisitIsSuccess: true,
        isLoadingGetReportingBillAbleIMCByVisit: false,
        getReportingBillAbleIMCByVisit: action.data,
      };

    case types.GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportBillAbleIMCByVisitIsSuccess: false,
        isLoadingGetReportingExportBillAbleIMCByVisit: true,
        getReportingExportBillAbleIMCByVisit: null,
      };

    case types.GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportBillAbleIMCByVisitIsSuccess: false,
        isLoadingGetReportingExportBillAbleIMCByVisit: false,
        getReportingExportBillAbleIMCByVisit: null,
        error: {
          ...state.error,
          getReportingExportBillAbleIMCByVisitError: action.error,
        },
      };

    case types.GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportBillAbleIMCByVisitIsSuccess: true,
        isLoadingGetReportingExportBillAbleIMCByVisit: false,
        getReportingExportBillAbleIMCByVisit: action.data,
      };

    case types.GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingPurchaseOrderSummaryIsSuccess: false,
        isLoadingGetReportingPurchaseOrderSummary: true,
        getReportingPurchaseOrderSummary: null,
      };

    case types.GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingPurchaseOrderSummaryIsSuccess: false,
        isLoadingGetReportingPurchaseOrderSummary: false,
        getReportingPurchaseOrderSummary: null,
        error: {
          ...state.error,
          getReportingPurchaseOrderSummaryError: action.error,
        },
      };

    case types.GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingPurchaseOrderSummaryIsSuccess: true,
        isLoadingGetReportingPurchaseOrderSummary: false,
        getReportingPurchaseOrderSummary: action.data,
      };

    case types.GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportPurchaseOrderSummaryIsSuccess: false,
        isLoadingGetReportingExportPurchaseOrderSummary: true,
        getReportingExportPurchaseOrderSummary: null,
      };

    case types.GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportPurchaseOrderSummaryIsSuccess: false,
        isLoadingGetReportingExportPurchaseOrderSummary: false,
        getReportingExportPurchaseOrderSummary: null,
        error: {
          ...state.error,
          getReportingExportPurchaseOrderSummaryError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportPurchaseOrderSummaryIsSuccess: true,
        isLoadingGetReportingExportPurchaseOrderSummary: false,
        getReportingExportPurchaseOrderSummary: action.data,
      };

    case types.GET_REPORTINGPURCHASEORDERDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingPurchaseOrderDetailsIsSuccess: false,
        isLoadingGetReportingPurchaseOrderDetails: true,
        getReportingPurchaseOrderDetails: null,
      };

    case types.GET_REPORTINGPURCHASEORDERDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingPurchaseOrderDetailsIsSuccess: false,
        isLoadingGetReportingPurchaseOrderDetails: false,
        getReportingPurchaseOrderDetails: null,
        error: {
          ...state.error,
          getReportingPurchaseOrderDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGPURCHASEORDERDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingPurchaseOrderDetailsIsSuccess: true,
        isLoadingGetReportingPurchaseOrderDetails: false,
        getReportingPurchaseOrderDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportPurchaseOrderDetailsSuccess: false,
        isLoadingGetReportingExportPurchaseOrderDetails: true,
        getReportingExportPurchaseOrderDetails: null,
      };

    case types.GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportPurchaseOrderDetailsSuccess: false,
        isLoadingGetReportingExportPurchaseOrderDetails: false,
        getReportingExportPurchaseOrderDetails: null,
        error: {
          ...state.error,
          getReportingExportPurchaseOrderDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportPurchaseOrderDetailsSuccess: true,
        isLoadingGetReportingExportPurchaseOrderDetails: false,
        getReportingExportPurchaseOrderDetails: action.data,
      };

    case types.GET_REPORTINGDELIVERYTRACKINGDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingDeliveryTrackingDetailsSuccess: false,
        isLoadingGetReportingDeliveryTrackingDetails: true,
        getReportingDeliveryTrackingDetails: null,
      };

    case types.GET_REPORTINGDELIVERYTRACKINGDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingDeliveryTrackingDetailsSuccess: false,
        isLoadingGetReportingDeliveryTrackingDetails: false,
        getReportingDeliveryTrackingDetails: null,
        error: {
          ...state.error,
          getReportingDeliveryTrackingDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGDELIVERYTRACKINGDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingDeliveryTrackingDetailsSuccess: true,
        isLoadingGetReportingDeliveryTrackingDetails: false,
        getReportingDeliveryTrackingDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTDELIVERYTRACKINGDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingDeliveryTrackingDetailsSuccess: false,
        isLoadingGetReportingDeliveryTrackingDetails: true,
        getReportingExportDeliveryTrackingDetails: null,
      };

    case types.GET_REPORTINGEXPORTDELIVERYTRACKINGDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingDeliveryTrackingDetailsSuccess: false,
        isLoadingGetReportingDeliveryTrackingDetails: false,
        getReportingExportDeliveryTrackingDetails: null,
        error: {
          ...state.error,
          getReportingExportDeliveryTrackingDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTDELIVERYTRACKINGDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingDeliveryTrackingDetailsSuccess: true,
        isLoadingGetReportingDeliveryTrackingDetails: false,
        getReportingExportDeliveryTrackingDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTDELIVERYTRACKINGSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportDeliveryTrackingSummaryIsSuccess: false,
        isLoadingGetReportingExportDeliveryTrackingSummary: true,
        getReportingExportDeliveryTrackingSummary: null,
      };

    case types.GET_REPORTINGEXPORTDELIVERYTRACKINGSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportDeliveryTrackingSummaryIsSuccess: false,
        isLoadingGetReportingExportDeliveryTrackingSummary: false,
        getReportingExportDeliveryTrackingSummary: null,
        error: {
          ...state.error,
          getReportingExportDeliveryTrackingSummaryError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTDELIVERYTRACKINGSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportDeliveryTrackingSummaryIsSuccess: true,
        isLoadingGetReportingExportDeliveryTrackingSummary: false,
        getReportingExportDeliveryTrackingSummary: action.data,
      };

    case types.GET_REPORTINGEXPORTAPPOINTMENTDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportAppointmentDetailsIsSuccess: false,
        isLoadingGetReportingExportAppointmentDetails: true,
        getReportingExportAppointmentDetails: null,
      };

    case types.GET_REPORTINGEXPORTAPPOINTMENTDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportAppointmentDetailsIsSuccess: false,
        isLoadingGetReportingExportAppointmentDetails: false,
        getReportingExportAppointmentDetails: null,
        error: {
          ...state.error,
          getReportingExportAppointmentDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTAPPOINTMENTDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportAppointmentDetailsIsSuccess: true,
        isLoadingGetReportingExportAppointmentDetails: false,
        getReportingExportAppointmentDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTAPPOINTMENTSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportAppointmentSummaryIsSuccess: false,
        isLoadingGetReportingExportAppointmentSummary: true,
        getReportingExportAppointmentSummary: null,
      };

    case types.GET_REPORTINGEXPORTAPPOINTMENTSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportAppointmentSummaryIsSuccess: false,
        isLoadingGetReportingExportAppointmentSummary: false,
        getReportingExportAppointmentSummary: null,
        error: {
          ...state.error,
          getReportingExportAppointmentSummaryError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTAPPOINTMENTSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportAppointmentSummaryIsSuccess: true,
        isLoadingGetReportingExportAppointmentSummary: false,
        getReportingExportAppointmentSummary: action.data,
      };

    case types.GET_REPORTINGEXPORTDISPENSARYDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportDispensaryDetailsIsSuccess: false,
        isLoadingGetReportingExportDispensaryDetails: true,
        getReportingExportDispensaryDetails: null,
      };

    case types.GET_REPORTINGEXPORTDISPENSARYDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportDispensaryDetailsIsSuccess: false,
        isLoadingGetReportingExportDispensaryDetails: false,
        getReportingExportDispensaryDetails: null,
        error: {
          ...state.error,
          getReportingExportDispensaryDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTDISPENSARYDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportDispensaryDetailsIsSuccess: true,
        isLoadingGetReportingExportDispensaryDetails: false,
        getReportingExportDispensaryDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTDISPENSARYSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportDispensarySummaryIsSuccess: false,
        isLoadingGetReportingExportDispensarySummary: true,
        getReportingExportDispensarySummary: null,
      };

    case types.GET_REPORTINGEXPORTDISPENSARYSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportDispensarySummaryIsSuccess: false,
        isLoadingGetReportingExportDispensarySummary: false,
        getReportingExportDispensarySummary: null,
        error: {
          ...state.error,
          getReportingExportDispensarySummaryError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTDISPENSARYSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportDispensarySummaryIsSuccess: true,
        isLoadingGetReportingExportDispensarySummary: false,
        getReportingExportDispensarySummary: action.data,
      };

    case types.GET_REPORTINGEXPORTSMSMESSAGEINOUTDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportSMSMessageInOutDetailsIsSuccess: false,
        isLoadingGetReportingExportSMSMessageInOutDetails: true,
        getReportingExportSMSMessageInOutDetails: null,
      };

    case types.GET_REPORTINGEXPORTSMSMESSAGEINOUTDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportSMSMessageInOutDetailsIsSuccess: false,
        isLoadingGetReportingExportSMSMessageInOutDetails: false,
        getReportingExportSMSMessageInOutDetails: null,
        error: {
          ...state.error,
          getReportingExportSMSMessageInOutDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTSMSMESSAGEINOUTDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportSMSMessageInOutDetailsIsSuccess: true,
        isLoadingGetReportingExportSMSMessageInOutDetails: false,
        getReportingExportSMSMessageInOutDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTSMSMESSAGEINOUTSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportSMSMessageInOutSummaryIsSuccess: false,
        isLoadingGetReportingExportSMSMessageInOutSummary: true,
        getReportingExportSMSMessageInOutSummary: null,
      };

    case types.GET_REPORTINGEXPORTSMSMESSAGEINOUTSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportSMSMessageInOutSummaryIsSuccess: false,
        isLoadingGetReportingExportSMSMessageInOutSummary: false,
        getReportingExportSMSMessageInOutSummary: null,
        error: {
          ...state.error,
          getReportingExportSMSMessageInOutSummaryError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTSMSMESSAGEINOUTSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportSMSMessageInOutSummaryIsSuccess: true,
        isLoadingGetReportingExportSMSMessageInOutSummary: false,
        getReportingExportSMSMessageInOutSummary: action.data,
      };

    case types.GET_REPORTINGEARNINGINSIGHTSSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingEarningInsightsSummaryIsSuccess: false,
        isLoadingGetReportingEarningInsightsSummary: true,
        getReportingEarningInsightsSummary: null,
      };

    case types.GET_REPORTINGEARNINGINSIGHTSSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingEarningInsightsSummaryIsSuccess: false,
        isLoadingGetReportingEarningInsightsSummary: false,
        getReportingEarningInsightsSummary: null,
        error: {
          ...state.error,
          getReportingEarningInsightsSummaryError: action.error,
        },
      };

    case types.GET_REPORTINGEARNINGINSIGHTSSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingEarningInsightsSummaryIsSuccess: true,
        isLoadingGetReportingEarningInsightsSummary: false,
        getReportingEarningInsightsSummary: action.data,
      };

    case types.GET_REPORTINGEXPORTEARNINGINSIGHTSSUMMARY_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportEarningInsightsSummaryIsSuccess: false,
        isLoadingGetReportingExportEarningInsightsSummary: true,
        getReportingExportEarningInsightsSummary: null,
      };

    case types.GET_REPORTINGEXPORTEARNINGINSIGHTSSUMMARY_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportEarningInsightsSummaryIsSuccess: false,
        isLoadingGetReportingExportEarningInsightsSummary: false,
        getReportingExportEarningInsightsSummary: null,
        error: {
          ...state.error,
          getReportingExportEarningInsightsSummaryError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTEARNINGINSIGHTSSUMMARY_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportEarningInsightsSummaryIsSuccess: true,
        isLoadingGetReportingExportEarningInsightsSummary: false,
        getReportingExportEarningInsightsSummary: action.data,
      };

    case types.GET_REPORTINGEARNINGINSIGHTSDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingEarningInsightsDetailsIsSuccess: false,
        isLoadingGetReportingEarningInsightsDetails: true,
        getReportingEarningInsightsDetails: null,
      };

    case types.GET_REPORTINGEARNINGINSIGHTSDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingEarningInsightsDetailsIsSuccess: false,
        isLoadingGetReportingEarningInsightsDetails: false,
        getReportingEarningInsightsDetails: null,
        error: {
          ...state.error,
          getReportingEarningInsightsDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEARNINGINSIGHTSDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingEarningInsightsDetailsIsSuccess: true,
        isLoadingGetReportingEarningInsightsDetails: false,
        getReportingEarningInsightsDetails: action.data,
      };

    case types.GET_REPORTINGEXPORTEARNINGINSIGHTSDETAILS_ASYNC.PENDING:
      return {
        ...state,
        getReportingExportEarningInsightsDetailsIsSuccess: false,
        isLoadingGetReportingExportEarningInsightsDetails: true,
        getReportingExportEarningInsightsDetails: null,
      };

    case types.GET_REPORTINGEXPORTEARNINGINSIGHTSDETAILS_ASYNC.ERROR:
      return {
        ...state,
        getReportingExportEarningInsightsDetailsIsSuccess: false,
        isLoadingGetReportingExportEarningInsightsDetails: false,
        getReportingExportEarningInsightsDetails: null,
        error: {
          ...state.error,
          getReportingExportEarningInsightsDetailsError: action.error,
        },
      };

    case types.GET_REPORTINGEXPORTEARNINGINSIGHTSDETAILS_ASYNC.SUCCESS:
      return {
        ...state,
        getReportingExportEarningInsightsDetailsIsSuccess: true,
        isLoadingGetReportingExportEarningInsightsDetails: false,
        getReportingExportEarningInsightsDetails: action.data,
      };

    default:
      return state;
  }
};
