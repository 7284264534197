import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PurchaseOrderPage from "./purchaseOrder/PurchaseOrderPage";
import OrderFormPage from "./orderForm/OrderFormPage";
import AppointmentPage from "./appointment/AppointmentPage";
import BranchMedicinePage from "./administration/branchMedicine/BranchMedicinePage";
import SchedulePage from "./administration/schedule/SchedulePage";
import UserPage from "./administration/user/UserPage";
import UserCreditPage from "./administration/userCredit/UserCreditPage";
import MedicinePage from "./administration/medicine/MedicinePage";
import PrescriptionMsgPage from "./administration/prescriptionMsg/PrescriptionMsgPage";
import SystemSchedulePage from "./administration/systemSchedule/SystemSchedulePage";
import AcupointPage from "./administration/acupoint/AcupointPage";
import TreatmentPage from "./administration/treatment/TreatmentPage";
import FormulaPage from "./administration/formula/FormulaPage";
import FormulaMedicinePage from "./administration/formulaMedicine/FormulaMedicinePage";
import PostalCodeSurchargePage from "./administration/postalCodeSurcharge/PostalCodeSurchargePage";
import EmailStoreAccountPage from "./administration/emailStoreAccount/EmailStoreAccountPage";
import EmailStoreSenderPage from "./administration/emailStoreSender/EmailStoreSenderPage";
import MedicineFixedSellingPricePage from "./administration/medicineFixedSellingPrice/MedicineFixedSellingPricePage";
import MedicineFixedSellingPriceDetailsPage from "./administration/medicineFixedSellingPriceDetails/MedicineFixedSellingPriceDetailsPage";
import UserMedicineFixedSellingPricePage from "./administration/userMedicineFixedSellingPrice/UserMedicineFixedSellingPricePage";
import WikiDataPage from "./administration/wikiData/WikiDataPage";
import NonBusinessDayPage from "./administration/nonBusinessDay/NonBusinessDayPage";
import CourierPage from "./administration/courier/CourierPage";
import UserDeviceKeyPage from "./administration/userDeviceKey/UserDeviceKeyPage";
import BranchCourierPage from "./administration/branchCourier/BranchCourierPage";
import DeliveryCutoffSchedulePage from "./administration/deliveryCutoffSchedule/DeliveryCutoffSchedulePage";
import { EditUserProfilePage } from "./auth/UserProfile/EditUserProfilePage";
import CurrentUserCreditPage from "./auth/currentUserCredit/CurrentUserCreditPage";
import WorkOrderPage from "./workOrder/WorkOrderPage";
import InvoicePage from "./invoice/InvoicePage";
import VisitOrderPage from "./visit/VisitOrderPage";
import emailStorePage from "./emailStore/EmailStorePage";
import { DefaultHomePage } from "./home/DefaultHomePage";
import ReSignIn from "../pages/auth/SignIn/ReSignInPage";
import HomecarePage from "./homecare/HomecarePage";
import ReportPage from "./report/ReportPage";
import NoAuthorization from "./errors/NoAuthorization";
//import NoInternet from "./errors/NoInternet";
import OrganisationChanged from "./errors/OrganisationChanged";
import ConfirmReloadDialog from "../components/ConfirmReloadDialog";
//import NotificationDialog from "../components/NotificationDialog";
import { BroadcastChannel } from "broadcast-channel";
import { useTranslation } from "react-i18next";
export default function HomePage() {
  const { t } = useTranslation();
  const [changeOrgnisation, setChangeOrgnisation] = React.useState(false);

  React.useEffect(() => {
    const channel = new BroadcastChannel("changeOrgnisation");
    channel.onmessage = (msg) => {
      let isMounted = true;
      if (isMounted && msg.changeOrgnisation) {
        setChangeOrgnisation(true);
        channel.close();
      }
      return () => {
        isMounted = false;
      };
    };
  }, []);

  return (
    <>
      <ConfirmReloadDialog
        ok={t("translation:Ok")}
        title={t("translation:Organisation has been changed")}
        open={changeOrgnisation}>
        {t("translation:Organisation has been changed") +
          " " +
          t("translation:Please refresh the page")}
      </ConfirmReloadDialog>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Redirect exact from="/signIn" to="/home" />
        <Route path="/appointment" component={AppointmentPage} />
        <Route exact path="/403" component={NoAuthorization} />
        <Route
          exact
          path="/OrganisationChanged"
          component={OrganisationChanged}
        />
        <Route path="/user" component={UserPage} />
        <Route path="/medicine" component={MedicinePage} />
        <Route path="/home" component={DefaultHomePage} />
        <Route path="/dashboard" component={DefaultHomePage} />
        <Route path="/reSignIn" component={ReSignIn} />
        <Route path="/profile" component={EditUserProfilePage} />
        <Route path="/currentUserCredit" component={CurrentUserCreditPage} />
        <Route path="/store" component={WorkOrderPage} />
        <Route path="/visit" component={VisitOrderPage} />
        <Route path="/schedule" component={SchedulePage} />
        <Route path="/invoice" component={InvoicePage} />
        <Route path="/userCredit" component={UserCreditPage} />
        <Route path="/orderForm" component={OrderFormPage} />
        <Route path="/purchaseOrder" component={PurchaseOrderPage} />
        <Route path="/branchMedicine" component={BranchMedicinePage} />
        <Route path="/homecare" component={HomecarePage} />
        <Route path="/report" component={ReportPage} />
        <Route path="/prescriptionMsg" component={PrescriptionMsgPage} />
        <Route path="/systemSchedule" component={SystemSchedulePage} />
        <Route path="/acupoint" component={AcupointPage} />
        <Route path="/treatment" component={TreatmentPage} />
        <Route path="/formula" component={FormulaPage} />
        <Route path="/formulaMedicine" component={FormulaMedicinePage} />
        <Route
          path="/postalCodeSurcharge"
          component={PostalCodeSurchargePage}
        />
        <Route path="/emailStoreAccount" component={EmailStoreAccountPage} />
        <Route path="/emailStoreSender" component={EmailStoreSenderPage} />
        <Route path="/emailStore" component={emailStorePage} />
        <Route
          path="/medicineFixedSellingPrice"
          component={MedicineFixedSellingPricePage}
        />
        <Route
          path="/userMedicineFixedSellingPrice"
          component={UserMedicineFixedSellingPricePage}
        />
        <Route
          path="/medicineFixedSellingPriceDetails"
          component={MedicineFixedSellingPriceDetailsPage}
        />

        <Route path="/wikiData" component={WikiDataPage} />

        <Route path="/nonBusinessDay" component={NonBusinessDayPage} />

        <Route
          path="/deliveryCutoffSchedule"
          component={DeliveryCutoffSchedulePage}
        />

        <Route path="/courier" component={CourierPage} />

        <Route path="/branchCourier" component={BranchCourierPage} />

        <Route path="/userDeviceKey" component={UserDeviceKeyPage} />

        <Redirect to="/home" />
      </Switch>
    </>
  );
}
