import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
// import "leaflet-routing-machine";

// import { useMap } from "react-leaflet";
//https://www.liedman.net/leaflet-routing-machine/tutorials/alternative-routers/  to set up own server
const position = [1.363459, 103.822857];

const Red_MARKER = (index) => {
  return `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
<svg width="47" height="47" viewBox="0 0 50 70" xmlns="http://www.w3.org/2000/svg">
  <style>
    .pin {
      fill: #ff0000;
      stroke :gray;
      strokeWidth: 3;
      fill-opacity: .65
    }
    .number {
      font-family: Arial, sans-serif;
      font-size: 24px;
      font-weight: bold;
      fill: #fff;
    }
  </style>
  <path class="pin" d="M25 0C16.16 0 9 7.16 9 16 9 25.84 25 70 25 70 25 70 41 25.84 41 16 41 7.16 33.84 0 25 0z"/>
  <text x="25" y="25" text-anchor="middle" alignment-baseline="middle" class="number">${index}</text>
</svg>
    `)}`;
};

// const Routing = ({ latLonList }) => {
//   const map = useMap();

//   const waypointsList = latLonList.map((item) =>
//     L.latLng(parseFloat(item.latitude), parseFloat(item.longitude))
//   );
//   useEffect(() => {
//     if (!map) return;

//     if (latLonList.length > 0) {
//       const routingControl = L.Routing.control({
//         waypoints: waypointsList,
//         routeWhileDragging: true,
//         lineOptions: {
//           styles: [{ color: "#6FA1EC", weight: 4 }],
//         },
//         createMarker: function () {
//           return null;
//         },
//         show: true,
//         collapsible: true,
//         showAlternatives: false,
//         addWaypoints: false,
//         fitSelectedRoutes: false,
//       }).addTo(map);

//       return () => map.removeControl(routingControl);
//     }
//   }, [map, latLonList]);

//   return null;
// };

const BoatIcon = (index) => {
  return L.icon({
    iconUrl: Red_MARKER(index),
    iconSize: [47, 47],
    iconAnchor: [23.5, 47], // CHANGE HERE
    popupAnchor: [-25, -50],
    tooltipAnchor: [30, -2],
  });
};

export default function LeafletMap({ latLonList }) {
  return (
    <MapContainer
      style={{ height: "80vh", width: "100wh" }}
      center={position}
      zoom={12}
      scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {latLonList.map((item, index) => (
        <Marker
          key={`leafMarker_${index}`}
          position={[item.latitude, item.longitude]}
          icon={BoatIcon(index)}
        />
      ))}
      {/* <Routing latLonList={latLonList} /> */}
    </MapContainer>
  );
}
