import * as types from "../_constants/commonConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_GENDER_ASYNC.PENDING:
      return {
        ...state,
        getGenderIsSuccess: false,
        isLoadingGenderList: true,
        genderList: null,
      };

    case types.GET_GENDER_ASYNC.ERROR:
      return {
        ...state,
        getGenderIsSuccess: false,
        isLoadingGenderList: false,
        genderList: null,

        error: { ...state.error, genderListError: action.error },
      };

    case types.GET_GENDER_ASYNC.SUCCESS:
      return {
        ...state,
        getGenderIsSuccess: true,
        isLoadingGenderList: false,
        genderList: action.data,
      };

    case types.GET_MARITAL_ASYNC.PENDING:
      return {
        ...state,
        getMaritalIsSuccess: false,
        isLoadingMarital: true,
        maritalList: null,
      };

    case types.GET_MARITAL_ASYNC.ERROR:
      return {
        ...state,
        getMaritalIsSuccess: false,
        isLoadingMarital: false,
        maritalList: null,
        error: { ...state.error, maritalListError: action.error },
      };

    case types.GET_MARITAL_ASYNC.SUCCESS:
      return {
        ...state,
        getMaritalIsSuccess: true,
        isLoadingMarital: false,
        maritalList: action.data,
      };
    case types.GET_NATIONALITY_ASYNC.PENDING:
      return {
        ...state,
        getNationalistIsSuccess: false,
        isLoadingNationalist: true,
        nationalityList: null,
      };

    case types.GET_NATIONALITY_ASYNC.ERROR:
      return {
        ...state,
        getNationalistIsSuccess: false,
        isLoadingNationalist: false,
        nationalityList: null,
        error: { ...state.error, nationalityListError: action.error },
      };

    case types.GET_NATIONALITY_ASYNC.SUCCESS:
      return {
        ...state,
        getNationalistIsSuccess: true,
        isLoadingNationalist: false,
        nationalityList: action.data,
      };

    case types.GET_RACE_ASYNC.PENDING:
      return {
        ...state,
        getRaceListIsSuccess: false,
        isLoadingRaceList: true,
        raceList: null,
      };

    case types.GET_RACE_ASYNC.ERROR:
      return {
        ...state,
        getRaceListIsSuccess: false,
        isLoadingRaceList: false,
        raceList: null,
        error: { ...state.error, raceListError: action.error },
      };

    case types.GET_RACE_ASYNC.SUCCESS:
      return {
        ...state,
        getRaceListIsSuccess: true,
        isLoadingRaceList: false,
        raceList: action.data,
      };

    case types.GET_RIGHTS_ASYNC.PENDING:
      return {
        ...state,
        getRightIsSuccess: false,
        isLoadingRight: true,
        rightList: null,
      };

    case types.GET_RIGHTS_ASYNC.ERROR:
      return {
        ...state,
        getRightIsSuccess: false,
        isLoadingRight: false,
        rightList: null,
        error: { ...state.error, rightListError: action.error },
      };

    case types.GET_RIGHTS_ASYNC.SUCCESS:
      return {
        ...state,
        getRightIsSuccess: true,
        isLoadingRight: false,
        rightList: action.data,
      };

    case types.GET_PREMISSION_ASYNC.PENDING:
      return {
        ...state,
        getPermissionIsSuccess: false,
        isLoadingPermission: true,
        permissionList: null,
      };

    case types.GET_PREMISSION_ASYNC.ERROR:
      return {
        ...state,
        getPermissionIsSuccess: false,
        isLoadingPermission: false,
        permissionList: null,
        error: { ...state.error, permissionListError: action.error },
      };

    case types.GET_PREMISSION_ASYNC.SUCCESS:
      return {
        ...state,
        getPermissionIsSuccess: true,
        isLoadingPermission: false,
        permissionList: action.data,
      };

    case types.GET_VIEWNEEDS_ASYNC.PENDING:
      return {
        ...state,
        getViewNeedsIsSuccess: false,
        isLoadingViewNeeds: true,
        viewNeedsList: null,
      };

    case types.GET_VIEWNEEDS_ASYNC.ERROR:
      return {
        ...state,
        getViewNeedsIsSuccess: false,
        isLoadingViewNeeds: false,
        viewNeedsList: null,
        error: { ...state.error, viewNeedsListError: action.error },
      };

    case types.GET_VIEWNEEDS_ASYNC.SUCCESS:
      return {
        ...state,
        getViewNeedsIsSuccess: true,
        isLoadingViewNeeds: false,
        viewNeedsList: action.data,
      };

    case types.GET_LANGUAGE_ASYNC.PENDING:
      return {
        ...state,
        getLanguageIsSuccess: false,
        isLoadingLanguage: true,
        languageList: null,
      };

    case types.GET_LANGUAGE_ASYNC.ERROR:
      return {
        ...state,
        getLanguageIsSuccess: false,
        isLoadingLanguage: false,
        languageList: null,
        error: { ...state.error, languageListError: action.error },
      };

    case types.GET_LANGUAGE_ASYNC.SUCCESS:
      return {
        ...state,
        getLanguageIsSuccess: true,
        isLoadingLanguage: false,
        languageList: action.data,
      };

    case types.GET_MEDICINECONSUMETIME_ASYNC.PENDING:
      return {
        ...state,
        getMedicineCosumeTimeIsSuccess: false,
        isLoadingMedicineCosumeTime: true,
        medicineCosumeTimeList: null,
      };

    case types.GET_MEDICINECONSUMETIME_ASYNC.ERROR:
      return {
        ...state,
        getMedicineCosumeTimeIsSuccess: false,
        isLoadingMedicineCosumeTime: false,
        medicineCosumeTimeList: null,
        error: { ...state.error, medicineCosumeTimeListError: action.error },
      };

    case types.GET_MEDICINECONSUMETIME_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineCosumeTimeIsSuccess: true,
        isLoadingMedicineCosumeTime: false,
        medicineCosumeTimeList: action.data,
      };

    case types.GET_MEDICINEMEASUREUNIT_ASYNC.PENDING:
      return {
        ...state,
        getMedicineMeasureUnitIsSuccess: false,
        isLoadingMedicineMeasureUnit: true,
        medicineMeasureUnitList: null,
      };

    case types.GET_MEDICINEMEASUREUNIT_ASYNC.ERROR:
      return {
        ...state,
        getMedicineMeasureUnitIsSuccess: false,
        isLoadingMedicineMeasureUnit: false,
        medicineMeasureUnitList: null,
        error: { ...state.error, medicineMeasureUnitError: action.error },
      };

    case types.GET_MEDICINEMEASUREUNIT_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineMeasureUnitIsSuccess: true,
        isLoadingMedicineMeasureUnit: false,
        medicineMeasureUnitList: action.data,
      };

    case types.GET_GENERALSTATUS_ASYNC.PENDING:
      return {
        ...state,
        getGeneralStatusIsSuccess: false,
        isLoadingtGeneralStatus: true,
        generalStatusList: null,
      };

    case types.GET_GENERALSTATUS_ASYNC.ERROR:
      return {
        ...state,
        getGeneralStatusIsSuccess: false,
        isLoadingtGeneralStatus: false,
        generalStatusList: null,
        error: { ...state.error, generalStatusError: action.error },
      };

    case types.GET_GENERALSTATUS_ASYNC.SUCCESS:
      return {
        ...state,
        getGeneralStatusIsSuccess: true,
        isLoadingtGeneralStatus: false,
        generalStatusList: action.data,
      };
    case types.GET_WATERAMOUNT_ASYNC.PENDING:
      return {
        ...state,
        getWaterAmountIsSuccess: false,
        isLoadingWaterAmount: true,
        waterAmountList: null,
      };

    case types.GET_WATERAMOUNT_ASYNC.ERROR:
      return {
        ...state,
        getWaterAmountIsSuccess: false,
        isLoadingWaterAmount: false,
        waterAmountList: null,
        error: { ...state.error, waterAmountError: action.error },
      };

    case types.GET_WATERAMOUNT_ASYNC.SUCCESS:
      return {
        ...state,
        getWaterAmountIsSuccess: true,
        isLoadingWaterAmount: false,
        waterAmountList: action.data,
      };

    case types.GET_PAYMENTMETHOD_ASYNC.PENDING:
      return {
        ...state,
        getPaymentMethodIsSuccess: false,
        isLoadingPaymentMethod: true,
        paymentMethodList: null,
      };

    case types.GET_PAYMENTMETHOD_ASYNC.ERROR:
      return {
        ...state,
        getPaymentMethodIsSuccess: false,
        isLoadingPaymentMethod: false,
        paymentMethodList: null,
        error: { ...state.error, paymentMethodError: action.error },
      };

    case types.GET_PAYMENTMETHOD_ASYNC.SUCCESS:
      return {
        ...state,
        getPaymentMethodIsSuccess: true,
        isLoadingPaymentMethod: false,
        paymentMethodList: action.data,
      };

    case types.GET_PAYMENTTERM_ASYNC.PENDING:
      return {
        ...state,
        getPaymentTermIsSuccess: false,
        isLoadingPaymentTerm: true,
        paymentTermList: null,
      };

    case types.GET_PAYMENTTERM_ASYNC.ERROR:
      return {
        ...state,
        getPaymentTermIsSuccess: false,
        isLoadingPaymentTerm: false,
        paymentTermList: null,
        error: { ...state.error, paymentTermError: action.error },
      };

    case types.GET_PAYMENTTERM_ASYNC.SUCCESS:
      return {
        ...state,
        getPaymentTermIsSuccess: true,
        isLoadingPaymentTerm: false,
        paymentTermList: action.data,
      };

    case types.GET_USERCREDITTYPE_ASYNC.PENDING:
      return {
        ...state,
        getUserCreditTypeIsSuccess: false,
        isLoadingUserCreditType: true,
        userCreditType: null,
      };

    case types.GET_USERCREDITTYPE_ASYNC.ERROR:
      return {
        ...state,
        getUserCreditTypeIsSuccess: false,
        isLoadingUserCreditType: false,
        userCreditType: null,
        error: { ...state.error, userCreditTypeError: action.error },
      };

    case types.GET_USERCREDITTYPE_ASYNC.SUCCESS:
      return {
        ...state,
        getUserCreditTypeIsSuccess: true,
        isLoadingUserCreditType: false,
        userCreditType: action.data,
      };

    case types.GET_INOUTTYPE_ASYNC.PENDING:
      return {
        ...state,
        getInOutTypeIsSuccess: false,
        isLoadingInOutType: true,
        inOutType: null,
      };

    case types.GET_INOUTTYPE_ASYNC.ERROR:
      return {
        ...state,
        getInOutTypeIsSuccess: false,
        isLoadingInOutType: false,
        inOutType: null,
        error: { ...state.error, inOutTypeError: action.error },
      };

    case types.GET_INOUTTYPE_ASYNC.SUCCESS:
      return {
        ...state,
        getInOutTypeIsSuccess: true,
        isLoadingInOutType: false,
        inOutType: action.data,
      };

    case types.GET_SYSTEMSETTINGS_ASYNC.PENDING:
      return {
        ...state,
        getSystemSettingsIsSuccess: false,
        isLoadingGetSystemSettings: true,
        getSystemSettings: null,
      };

    case types.GET_SYSTEMSETTINGS_ASYNC.ERROR:
      return {
        ...state,
        getSystemSettingsIsSuccess: false,
        isLoadingGetSystemSettings: false,
        getSystemSettings: null,
        error: { ...state.error, getSystemSettingsError: action.error },
      };

    case types.GET_SYSTEMSETTINGS_ASYNC.SUCCESS:
      return {
        ...state,
        getSystemSettingsIsSuccess: true,
        isLoadingGetSystemSettings: false,
        getSystemSettings: action.data,
      };

    case types.GET_MFATYPE_ASYNC.PENDING:
      return {
        ...state,
        getMfaTypeIsSuccess: false,
        isLoadingGetMfaType: true,
        getMfaType: null,
      };

    case types.GET_MFATYPE_ASYNC.ERROR:
      return {
        ...state,
        getMfaTypeIsSuccess: false,
        isLoadingGetMfaType: false,
        getMfaType: null,
        error: { ...state.error, getMfaTypeError: action.error },
      };

    case types.GET_MFATYPE_ASYNC.SUCCESS:
      return {
        ...state,
        getMfaTypeIsSuccess: true,
        isLoadingGetMfaType: false,
        getMfaType: action.data,
      };

    case types.GET_WORKORDERTYPE_ASYNC.PENDING:
      return {
        ...state,
        getWorkOrderTypeIsSuccess: false,
        isLoadingGetWorkOrderType: true,
        getWorkOrderType: null,
      };

    case types.GET_WORKORDERTYPE_ASYNC.ERROR:
      return {
        ...state,
        getWorkOrderTypeIsSuccess: false,
        isLoadingGetWorkOrderType: false,
        getWorkOrderType: null,
        error: { ...state.error, getWorkOrderTypeError: action.error },
      };

    case types.GET_WORKORDERTYPE_ASYNC.SUCCESS:
      return {
        ...state,
        getWorkOrderTypeIsSuccess: true,
        isLoadingGetWorkOrderType: false,
        getWorkOrderType: action.data,
      };

    case types.GET_POSTALCODEDISTRICT_ASYNC.PENDING:
      return {
        ...state,
        getPostalCodeDistrictIsSuccess: false,
        isLoadingGetPostalCodeDistrict: true,
        getPostalCodeDistrict: null,
      };

    case types.GET_POSTALCODEDISTRICT_ASYNC.ERROR:
      return {
        ...state,
        getPostalCodeDistrictIsSuccess: false,
        isLoadingGetPostalCodeDistrict: false,
        getPostalCodeDistrict: null,
        error: { ...state.error, getPostalCodeDistrictError: action.error },
      };

    case types.GET_POSTALCODEDISTRICT_ASYNC.SUCCESS:
      return {
        ...state,
        getPostalCodeDistrictIsSuccess: true,
        isLoadingGetPostalCodeDistrict: false,
        getPostalCodeDistrict: action.data,
      };

    default:
      return state;
  }
};
