//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";

//actions
import {
  getVisitStoreOrderSummaryList,
  getVisitStoreOrderById,
  putVisitStoreEditMode,
  getVisitCurrentOrganisation,
  putVisitSetArrivalOn,
  putVisitSetDepartureOn,
  getVisitStoreOrderDashboardSearchList,
} from "../../../actions/visitOrderActions";

//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import { ReactComponent as PendingLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
//import { ReactComponent as ArrivalLogo } from "../../../assets/coolicons/home/home_check.svg";

import OrderDetailPage from "./OrderDetailPage";
import OrderEditPage from "./OrderEditPage";
import * as pageConfigure from "../../../_constants/pageConstants";
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as generalConstants from "../../../_constants/generalConstants";
import AddOrderPage from "./AddOrderPage";
import copyMics from "./components/viewOrder/copyMics";
import CopyTreatment from "./components/viewOrder/copyTreatment";
import copyAcupuncture from "./components/viewOrder/copyAcupuncture";
import copyMedicine from "./components/viewOrder/copyMedicine";
import copyDignosis from "./components/viewOrder/copyDignosis";
import SubmissionNotificationDialog from "../../../components/SubmissionNotificationDialog";
import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";

export default function StoreListTable({
  setStoreInformationView = () => null,
  setEditSaveOrder = () => null,
  setConfirmAddOrderOpen = () => null,
  setRefreshTimeStamp = () => null,
  setWikiOpen = () => null,
  confirmAddOrderOpen = null,
  refreshTimeStamp = null,
  dashboardVisitOrderNo = null,
  disabledTableSearch = false,
  dashboardView = false,
}) {
  //variables

  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const localStorageNameKey = "visitListTable";
  const updateOrderAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Visit Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Physician"),
      accessor: "physician_name",
      sortable: false,
    },
    {
      Header: t("translation:Patient"),
      accessor: "staff_name",
    },

    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "staff_contact_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.NEW_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : row.original.status_type ===
          generalConstants.PENDING_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowEditProcess(row.original)}>
              <PendingLogo title={t("translation:New")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    // {
    //   Header: "",
    //   accessor: "Arrive",

    //   //adjust sort
    //   sortable: false,
    //   Cell: ({ row }) => {
    //     if (
    //       (row.original.visit_queue_details === null ||
    //         row.original.visit_queue_details?.arrival_on === null) &&
    //       row.original.status_type !== generalConstants.CLOSED_STATUS_TYPE &&
    //       row.original.status_type !== generalConstants.CANCELED_STATUS_TYPE &&
    //       row.original.status_type !== generalConstants.DELETED_STATUS_TYPE
    //     ) {
    //       return (
    //         <Button onClick={() => handleRowArrivalProcess(row.original)}>
    //           <ArrivalLogo title={t("translation:Arrive")} />
    //         </Button>
    //       );
    //     }
    //     return <></>;
    //   },
    // },

    {
      Header: t("translation:Visit Order No"),
      accessor: "order_number",
    },
    {
      Header: t("translation:Physician"),
      accessor: "physician_name",
    },
    {
      Header: t("translation:Patient"),
      accessor: "staff_name",
    },

    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "staff_contact_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_order_number_value: dashboardVisitOrderNo,
    search_staff_name_value: null,
    search_physician_name_value: null,
    search_desc_value: null,
    search_status_type_value: null,
    search_staff_contact_number_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
    search_proceed_start_value: null,
    search_proceed_end_value: null,
  });

  const dispatch = useDispatch();

  const prevGetVisitStoreOrderByIdOnStatus = useRef();

  const visitOrder = useSelector((state) => state.visitOrder);

  const common = useSelector((state) => state.common);
  const [orderEditView, setOrderEditView] = useState(false);
  const [saveOrderEditView, setSaveOrderEditView] = useState(false);

  const [newStatusErrorAlarm, setNewStatusErrorAlarm] = useState(false);
  const [arrivalStatusErrorAlarm, setArrivalStatusErrorAlarm] = useState(false);

  const [arrivalStatusSuccessAlarm, setArrivalStatusSuccessAlarm] =
    useState(false);

  const [newStatusSuccessAlarm, setNewStatusSuccessAlarm] = useState(false);

  const [orderDetailView, setOrderDetailView] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //const [orderDetailInfo, setOrderDetailInfo] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [confirmArrivalOpen, setConfirmArrivalOpen] = useState(false);
  const [confirmDepartureOpen, setConfirmDepartureOpen] = useState(false);

  const [editOrderInfo, setEditOrderInfo] = useState(null);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [arrivalMsgAlarm, setArrivalMsgAlarm] = React.useState([]);
  const [remountKey, setRemountKey] = useState(0);
  const prevvisitOrderStatus = useRef();
  const prevArrivalOnStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  //functions

  const handleCopyOnClick = (value, orderInfo) => {
    let editInfo = null;
    switch (value) {
      case "ALL":
        editInfo = copyDignosis(editOrderInfo, orderInfo);
        editInfo = copyMedicine(editInfo, orderInfo);
        editInfo = copyAcupuncture(editInfo, orderInfo, dispatch);
        editInfo = CopyTreatment(editInfo, orderInfo, dispatch);
        editInfo = copyMics(editInfo, orderInfo);
        break;
      case "DIAGNOSIS":
        editInfo = copyDignosis(editOrderInfo, orderInfo);
        break;
      case "MEDICINE":
        editInfo = copyMedicine(editOrderInfo, orderInfo);
        break;
      case "ACUPUNCTURE":
        editInfo = copyAcupuncture(editOrderInfo, orderInfo, dispatch);
        break;
      case "TREATMENT":
        editInfo = CopyTreatment(editOrderInfo, orderInfo, dispatch);
        break;
      case "MISC":
        editInfo = copyMics(editOrderInfo, orderInfo);
        break;
      default:
        editInfo = editOrderInfo;
    }
    setEditOrderInfo(editInfo);
    setRemountKey((prev) => prev + 1);
  };

  const handleRowEditProcess = (value) => {
    //
    setConfirmEditOpen(true);
    setSelectedValue(value.id);
  };

  // const handleRowArrivalProcess = (value) => {
  //   //
  //   setConfirmArrivalOpen(true);
  //   setEditOrderInfo(value);
  // };

  const handleConfirmEditProcess = () => {
    dispatch(putVisitStoreEditMode.pending(selectedValue));
  };

  const handleConfirmArrivalProcess = () => {
    const result = {
      store_header: {
        id: editOrderInfo.id,
      },
    };
    dispatch(putVisitSetArrivalOn.pending(result));
  };

  const handleConfirmDepartureProcess = () => {
    const result = {
      store_header: {
        id: editOrderInfo.id,
      },
    };
    dispatch(putVisitSetDepartureOn.pending(result));
  };

  // const handleConfirmQueueProcess = () => {
  //   const result = {
  //     store_header: {
  //       id: editOrderInfo.id,
  //       visit_queue_no: editOrderInfo.visit_queue_no,
  //     },
  //   };
  //   dispatch(putVisitSetQueueNumber.pending(result));
  // };

  const handleRowEdit = (value) => {
    //
    //setEditOrderInfo(value);
    dispatch(getVisitStoreOrderById.pending(value.id));
    setOrderEditView(true);
    setNewStatusSuccessAlarm(false);
    setNewStatusErrorAlarm(false);
    setArrivalStatusSuccessAlarm(false);
    setArrivalStatusErrorAlarm(false);
  };

  const handleRowDetail = (value) => {
    //
    //setOrderDetailInfo(value);
    dispatch(getVisitStoreOrderById.pending(value.id));
    setOrderDetailView(true);
    setNewStatusSuccessAlarm(false);
    setNewStatusErrorAlarm(false);
    setArrivalStatusSuccessAlarm(false);
    setArrivalStatusErrorAlarm(false);
  };

  //useEffects
  useEffect(() => {
    prevvisitOrderStatus.current = false;
    prevArrivalOnStatus.current = false;
    prevRefreshTimeStamp.current = null;
    prevGetVisitStoreOrderByIdOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      dashboardView &&
      !saveOrderEditView &&
      !orderEditView &&
      !orderDetailView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;

      dispatch(getVisitStoreOrderSummaryList.pending(pageInfo));
    }
  }, [
    refreshTimeStamp,
    dispatch,
    pageInfo,
    saveOrderEditView,
    orderEditView,
    orderDetailView,
    dashboardView,
  ]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (visitOrder.isLoadingStockEditMode !== prevvisitOrderStatus.current) {
      //check the previous ref with current state
      prevvisitOrderStatus.current = visitOrder.isLoadingStockEditMode;

      if (
        visitOrder.putVisitStockEditModeIsSuccess === true &&
        visitOrder.isLoadingStockEditMode === false
      ) {
        if (dashboardVisitOrderNo !== null) {
          dispatch(getVisitStoreOrderDashboardSearchList.pending(pageInfo));
        }
        if (!saveOrderEditView && !orderEditView) {
          dispatch(getVisitStoreOrderSummaryList.pending(pageInfo));
        }
        setNewStatusErrorAlarm(false);
        setNewStatusSuccessAlarm(true);
      }

      if (
        visitOrder.putVisitStockEditModeIsSuccess === false &&
        visitOrder.isLoadingStockEditMode === false
      ) {
        setMsgAlarm(visitOrder.error.putVisitStockEditModeError);

        setNewStatusErrorAlarm(true);
        setNewStatusSuccessAlarm(false);
      }
    }
  }, [
    dispatch,
    visitOrder,
    pageInfo,
    saveOrderEditView,
    orderEditView,
    dashboardVisitOrderNo,
  ]);

  useEffect(() => {
    if (visitOrder.isLoadingVisitSetArrivalOn !== prevArrivalOnStatus.current) {
      //check the previous ref with current state
      prevArrivalOnStatus.current = visitOrder.isLoadingVisitSetArrivalOn;

      if (
        visitOrder.putVisitSetArrivalOnIsSuccess === true &&
        visitOrder.isLoadingVisitSetArrivalOn === false
      ) {
        if (dashboardVisitOrderNo !== null) {
          dispatch(getVisitStoreOrderDashboardSearchList.pending(pageInfo));
        }
        if (!saveOrderEditView && !orderEditView) {
          dispatch(getVisitStoreOrderSummaryList.pending(pageInfo));
        }
        setArrivalStatusErrorAlarm(false);
        setArrivalStatusSuccessAlarm(true);
      }

      if (
        visitOrder.putVisitSetArrivalOnIsSuccess === false &&
        visitOrder.isLoadingVisitSetArrivalOn === false
      ) {
        setArrivalMsgAlarm(visitOrder.error.visitSetArrivalOnError);

        setArrivalStatusErrorAlarm(true);
        setArrivalStatusSuccessAlarm(false);
      }
    }
  }, [
    dispatch,
    visitOrder,
    pageInfo,
    saveOrderEditView,
    orderEditView,
    dashboardVisitOrderNo,
  ]);

  useEffect(() => {
    if (
      orderDetailView === true ||
      orderEditView === true ||
      saveOrderEditView === true
    ) {
      setStoreInformationView(true);
    } else {
      setStoreInformationView(false);
    }
  }, [
    orderEditView,
    orderDetailView,
    saveOrderEditView,
    setStoreInformationView,
  ]);

  useEffect(() => {
    //clear EditSaveOrderInfo when no more saveOrderEditView and it is summary page so that fail to create order error msg can be display
    if (saveOrderEditView === false) {
      setEditOrderInfo(null);
    }
  }, [saveOrderEditView, setEditOrderInfo]);

  useEffect(() => {
    if (
      !saveOrderEditView &&
      !orderDetailView &&
      !orderEditView &&
      !confirmAddOrderOpen
    ) {
      if (dashboardVisitOrderNo !== null) {
        dispatch(getVisitStoreOrderDashboardSearchList.pending(pageInfo));
      } else {
        if (!dashboardView) {
          dispatch(getVisitStoreOrderSummaryList.pending(pageInfo));
        }
      }
    }
  }, [
    dispatch,
    pageInfo,
    saveOrderEditView,
    orderDetailView,
    orderEditView,
    dashboardVisitOrderNo,
    confirmAddOrderOpen,
    dashboardView,
  ]);
  useEffect(() => {
    //refresh current user
    dispatch(getVisitCurrentOrganisation.pending());
  }, [dispatch]);

  useEffect(() => {
    if (
      visitOrder.isLoadingVisitStoreOrderById !==
      prevGetVisitStoreOrderByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetVisitStoreOrderByIdOnStatus.current =
        visitOrder.isLoadingVisitStoreOrderById;

      if (
        visitOrder.getVisitStoreOrderByIdIsSuccess === true &&
        visitOrder.isLoadingVisitStoreOrderById === false
      ) {
        setEditOrderInfo(visitOrder.visitStoreOrderListById.store_header);
      }
    }
  }, [visitOrder, dispatch, setEditOrderInfo]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: value,
        search_staff_name_value: value,
        search_physician_name_value: value,
        search_staff_contact_number_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: null,
        search_staff_name_value: null,
        search_physician_name_value: null,
        search_staff_contact_number_value: null,
        search_desc_value: null,
      });
    }
  };

  if (
    visitOrder.getVisitStoreOrderSummaryListIsSuccess === false &&
    visitOrder.isLoadingVisitStoreOrderSummaryList === false
  ) {
    return (
      <Alert severity="error">
        {visitOrder.error.getVisitStoreOrderSummaryListError}
      </Alert>
    );
  } else if (
    visitOrder.getVisitStoreOrderByIdIsSuccess === false &&
    visitOrder.isLoadingVisitStoreOrderById === false &&
    (orderDetailView || orderEditView)
  ) {
    return (
      <Alert severity="error">
        {visitOrder.error.getVisitStoreOrderByIdError}
      </Alert>
    );
  } else if (
    visitOrder.getVisitStoreOrderDashboardSearchListIsSuccess === false &&
    visitOrder.isLoadingVisitStoreOrderDashboardSearchList === false &&
    dashboardVisitOrderNo !== null
  ) {
    return (
      <Alert severity="error">
        {visitOrder.error.getVisitStoreOrderDashboardSearchListError}
      </Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    !orderEditView &&
    !orderDetailView &&
    !saveOrderEditView
  ) {
    return (
      <>
        <SubmissionNotificationDialog open={newStatusSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNewStatusSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general?.find(
                (item) => item.id === generalConstants.NEW_STATUS_TYPE
              ).name}
          </Alert>
        </SubmissionNotificationDialog>

        <SubmissionNotificationDialog open={newStatusErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNewStatusErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </SubmissionNotificationDialog>

        <SubmissionNotificationDialog open={arrivalStatusSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setArrivalStatusSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Arrival time has been updated")}
          </Alert>
        </SubmissionNotificationDialog>

        <SubmissionNotificationDialog open={arrivalStatusErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setArrivalStatusErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {arrivalMsgAlarm}
          </Alert>
        </SubmissionNotificationDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmEditOpen}
          setOpen={setConfirmEditOpen}
          onConfirm={handleConfirmEditProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmArrivalOpen}
          setOpen={setConfirmArrivalOpen}
          onConfirm={handleConfirmArrivalProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmDepartureOpen}
          setOpen={setConfirmDepartureOpen}
          onConfirm={handleConfirmDepartureProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        {confirmAddOrderOpen && (
          <>
            <AddOrderPage
              confirmAddOrderOpen={confirmAddOrderOpen}
              setConfirmAddOrderOpen={setConfirmAddOrderOpen}
              setSaveOrderEditView={setSaveOrderEditView}
              setEditOrderInfo={setEditOrderInfo}
            />
          </>
        )}
        {!confirmAddOrderOpen && dashboardVisitOrderNo === null && (
          <>
            <SortingSearchTable
              searchPlaceholder={t(
                "translation:Visit Order No/ Physician/ Patient/ Patient Contact Number"
              )}
              statusPlaceholder={t("translation:Order Status")}
              searchValue={searchValue}
              statusOption={common.generalStatusList.status_general}
              columns={updateOrderAccess ? columnsWUpdateAccess : columns}
              data={
                visitOrder.storeOrderSummaryList
                  ? visitOrder.storeOrderSummaryList.store_headers
                  : []
              }
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              pageCount={
                visitOrder.storeOrderSummaryList
                  ? visitOrder.storeOrderSummaryList.total_pages
                  : 0
              }
              loading={visitOrder.isLoadingVisitStoreOrderSummaryList}
              totalItems={
                visitOrder.storeOrderSummaryList
                  ? visitOrder.storeOrderSummaryList.total_items
                  : 0
              }
              handleSearch={handleSearch}
              disabledSearch={disabledTableSearch}
              searchDate={true}
              searchDatePlaceholder={t("translation:Creation Date")}
              searchDate2={true}
              searchDatePlaceholder2={t("translation:Proceed Date")}
              localStorageName={localStorageNameKey}
            />
          </>
        )}
        {!confirmAddOrderOpen && dashboardVisitOrderNo !== null && (
          <>
            <SortingSearchTable
              searchPlaceholder={t(
                "translation:Visit Order No/ Physician/ Patient/ Patient Contact Number"
              )}
              statusPlaceholder={t("translation:Order Status")}
              searchValue={searchValue}
              statusOption={common.generalStatusList.status_general}
              columns={updateOrderAccess ? columnsWUpdateAccess : columns}
              data={
                visitOrder.storeOrderDashboardSearchList
                  ? visitOrder.storeOrderDashboardSearchList.store_headers
                  : []
              }
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              pageCount={
                visitOrder.storeOrderDashboardSearchList
                  ? visitOrder.storeOrderDashboardSearchList.total_pages
                  : 0
              }
              loading={visitOrder.isLoadingVisitStoreOrderDashboardSearchList}
              totalItems={
                visitOrder.storeOrderDashboardSearchList
                  ? visitOrder.storeOrderDashboardSearchList.total_items
                  : 0
              }
              handleSearch={handleSearch}
              searchDate={true}
              searchDatePlaceholder={t("translation:Creation Date")}
              disabledSearch={disabledTableSearch}
              localStorageName={localStorageNameKey}
            />
          </>
        )}
      </>
    );
  } else if (orderDetailView && editOrderInfo !== null) {
    return (
      <OrderDetailPage
        orderDetailInfo={editOrderInfo}
        setOrderDetailInfo={setEditOrderInfo}
        setOrderDetailView={() => {
          setOrderDetailView(false);
          setEditOrderInfo(null);
          setRefreshTimeStamp(moment());
          setWikiOpen(false);
        }}
      />
    );
  } else if (orderEditView && editOrderInfo !== null) {
    //thi is through edit button
    return (
      <OrderEditPage
        key={remountKey}
        editOrderInfo={editOrderInfo}
        setOrderEditView={() => {
          setOrderEditView(false);
          setEditOrderInfo(null);
          setRefreshTimeStamp(moment());
          setWikiOpen(false);
        }}
        setWikiOpen={setWikiOpen}
        handleCopyOnClick={handleCopyOnClick}
        setEditOrderInfo={setEditOrderInfo}
      />
    );
  } else if (saveOrderEditView && editOrderInfo !== null) {
    //after save a new visit order
    return (
      <OrderEditPage
        editOrderInfo={editOrderInfo}
        key={remountKey}
        setOrderEditView={() => {
          setSaveOrderEditView(false);
          setEditOrderInfo(null);
          setRefreshTimeStamp(moment());
          setWikiOpen(false);
        }}
        setWikiOpen={setWikiOpen}
        handleCopyOnClick={handleCopyOnClick}
        setEditOrderInfo={setEditOrderInfo}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
