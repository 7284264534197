import * as generalConstants from "../../../../_constants/generalConstants";
export const handleIMCCheck = ({
  e,
  row,
  IMCSubmissionList,
  t,
  setIMCSubmissionList = () => null,
  setSubmitErrorAlarm = () => null,
  setMsgAlarm = () => null,
  setCheckAllRows = () => null,
}) => {
  if (e.target.checked) {
    if (IMCSubmissionList.length > 0) {
      const similarStatus = IMCSubmissionList.every(
        (item) => item.status_type === row.original.status_type
      );
      if (similarStatus) {
        setIMCSubmissionList((IMCSubmissionList) => [
          ...IMCSubmissionList,
          row.original,
        ]);
      } else {
        setSubmitErrorAlarm(true);
        setMsgAlarm(t("translation:Invalid Status Type"));
      }
    } else {
      setIMCSubmissionList((IMCSubmissionList) => [
        ...IMCSubmissionList,
        row.original,
      ]);
    }
  } else {
    setCheckAllRows(false);
    setIMCSubmissionList((IMCSubmissionList) => [
      ...IMCSubmissionList.filter((e) => e.id !== row.original.id),
    ]);
  }
};

export const handleIMCCheckAllRows = ({
  e,
  rows,
  IMCSubmissionList,
  t,
  setIMCSubmissionList = () => null,
  setSubmitErrorAlarm = () => null,
  setMsgAlarm = () => null,
  setCheckAllRows = () => null,
}) => {
  let tempIMCSubmissionList = IMCSubmissionList;
  if (e.target.checked) {
    rows.map(({ original }) => {
      if (tempIMCSubmissionList.length > 0) {
        const similarStatus = tempIMCSubmissionList.every(
          (item) => item.status_type === original.status_type
        );
        const sameId = tempIMCSubmissionList.some(
          (item) => item.id === original.id
        );
        if (similarStatus) {
          if (
            !sameId &&
            original.status_type !== generalConstants.CLOSED_STATUS_TYPE
          ) {
            tempIMCSubmissionList = [...tempIMCSubmissionList, original];
          }
        } else {
          if (original.status_type !== generalConstants.CLOSED_STATUS_TYPE) {
            setSubmitErrorAlarm(true);
            setMsgAlarm(t("translation:Invalid Status Type"));
          }
        }
      } else {
        if (original.status_type !== generalConstants.CLOSED_STATUS_TYPE) {
          tempIMCSubmissionList = [...tempIMCSubmissionList, original];
        }
      }
    });
    setCheckAllRows(true);
  } else {
    setCheckAllRows(false);
    if (tempIMCSubmissionList.length > 0) {
      rows.map(({ original }) => {
        const sameId = tempIMCSubmissionList.some(
          (item) => item.id === original.id
        );

        if (sameId) {
          tempIMCSubmissionList = tempIMCSubmissionList.filter(
            (e) => e.id !== original.id
          );
        }
      });
    }
  }

  setIMCSubmissionList(tempIMCSubmissionList);
};
