//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Panel from "../../../../components/Panel";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
//actions

import { getReportingMedicinesHeaderNames } from "../../../../actions/reportingActions";

//components

import StyledCheckbox from "../../../../components/StyledCheckbox";
import { useStyles } from "../../../../components/globalStyles";
import ConfirmDialog from "../../../../components/ConfirmDialog";
//global functions

function not(a, b) {
  return a.filter((item1) => !b.find((item2) => item1.id === item2.id));
}

function intersection(a, b) {
  return a.filter((item1) => b.find((item2) => item1.id === item2.id));
}

export const HeaderSelection = ({
  openDialog,
  setOpenDialog,
  uploadHeaderOnHandle,
}) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const reporting = useSelector((state) => state.reporting);
  const preGetHeaderStatus = useRef();
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  //functions
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };
  const customList = (items) => (
    <Paper className={classes.columnHeaderPaper}>
      <List dense component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              className={i % 2 ? classes.rowBackground : ""}
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <StyledCheckbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={` ${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  //useEffects

  useEffect(() => {
    //set ref when first render
    preGetHeaderStatus.current = false;
  }, []);

  useEffect(() => {
    dispatch(getReportingMedicinesHeaderNames.pending());
  }, [dispatch]);

  useEffect(() => {
    if (
      reporting.isLoadingGetReportingMedicinesHeaderNames !==
      preGetHeaderStatus.current
    ) {
      //check the previous ref with current state
      preGetHeaderStatus.current =
        reporting.isLoadingGetReportingMedicinesHeaderNames;

      if (
        reporting.getReportingMedicinesHeaderNamesIsSuccess === true &&
        reporting.isLoadingGetReportingMedicinesHeaderNames === false
      ) {
        setLeft(reporting.getReportingMedicinesHeaderNames.column_names);
      }
      if (
        reporting.getReportingMedicinesHeaderNamesIsSuccess === false &&
        reporting.isLoadingGetReportingMedicinesHeaderNames === false
      ) {
        setLeft([]);
      }
    }
  }, [reporting]);
  return (
    <ConfirmDialog
      open={openDialog}
      setOpen={setOpenDialog}
      onConfirm={() => {
        uploadHeaderOnHandle(right);
      }}
      Yes={right.length ? t("translation:Yes") : ""}
      No={t("translation:No")}
      maxWidth="lg">
      <Panel heading={t("translation:Update Header Selection")}>
        <Collapse in={successAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:User Role Updated")}
          </Alert>
        </Collapse>

        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <br />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}>
          <Grid item>
            <label className={classes.boldCenterMaroon100}>
              {t("translation:Available Header")}
            </label>
            {customList(left.sort((a, b) => a.id - b.id))}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right">
                ≫
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right">
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left">
                &lt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left">
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <label className={classes.boldCenterMaroon100}>
              {t("translation:Selected Header")}
            </label>
            {customList(right.sort((a, b) => a.id - b.id))}
          </Grid>
        </Grid>
      </Panel>
    </ConfirmDialog>
  );
};
