//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//action
import { putUpdateBranchMedicine } from "../../../../actions/branchMedicineActions";
//components
import ConfirmDialog from "../../../../components/ConfirmDialog";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import MedicineSelectionField from "./components/MedicineSelectionField";
import BranchMedicineField from "./components/BranchMedicineField";
import MedicineInOurTable from "./components/MedicineInOurTable";
import Panel from "../../../../components/Panel";

const ProcessPage = ({
  setView,
  branchId,
  infoData = null,
  setInfoData = () => null,
  viewMode = false,
}) => {
  //variables
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const branchMedicine = useSelector((state) => state.branchMedicine);
  const prevSubmitBranchMedicineStatus = useRef();
  const prevGetBranchMedicineListStatus = useRef();
  const { handleSubmit } = useForm();

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [medicineType, setMedicineType] = useState(
    infoData?.medicines ? infoData.medicines.medicine_type : null
  );

  const [medicine, setMedicine] = useState(
    infoData?.medicines ? infoData.medicines : null
  );

  const [branchMedicines, setBranchMedicines] = useState(
    infoData ? infoData.branch_medicines : null
  );

  //functions

  const onSubmit = () => {
    setConfirmSubmit(true);
  };

  const onConfirmSubmit = () => {
    //reset submit error
    setErrorAlarm(false);
    setSuccessAlarm(false);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const { branches, medicines, ...rest } = branchMedicines;
    const med = medicine;
    const submitJson = {
      branch: {
        id: branchId,
      },
      branch_medicines: [
        {
          ...rest,
          medicines: {
            id: med.id,
            quantity:
              branchMedicine.branchMedicineList.branch_medicines[0].quantity,
            reserve_quantity:
              branchMedicine.branchMedicineList.branch_medicines[0]
                .reserve_quantity,
          },
        },
      ],
    };
    dispatch(putUpdateBranchMedicine.pending(submitJson));
  };

  useEffect(() => {
    //set ref when first render
    prevSubmitBranchMedicineStatus.current = false;
    prevGetBranchMedicineListStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      branchMedicine.isLoadingPutUpdateBranchMedicine !==
      prevSubmitBranchMedicineStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitBranchMedicineStatus.current =
        branchMedicine.isLoadingPutUpdateBranchMedicine;

      if (
        branchMedicine.putUpdateBranchMedicineIsSuccess === false &&
        branchMedicine.isLoadingPutUpdateBranchMedicine === false
      ) {
        setErrorAlarm(true);
        window.scrollTo(0, 0);
        setMsgAlarm(branchMedicine.error.putUpdateBranchMedicineError);
      }

      if (
        branchMedicine.putUpdateBranchMedicineIsSuccess === true &&
        branchMedicine.isLoadingPutUpdateBranchMedicine === false
      ) {
        setSuccessAlarm(true);
        window.scrollTo(0, 0);
      }
    }
  }, [branchMedicine]);

  useEffect(() => {
    if (
      branchMedicine.isLoadingGetBranchMedicineList !==
      prevGetBranchMedicineListStatus.current
    ) {
      //check the previous ref with current state
      prevGetBranchMedicineListStatus.current =
        branchMedicine.isLoadingGetBranchMedicineList;

      if (
        branchMedicine.getBranchMedicineListIsSuccess === true &&
        branchMedicine.isLoadingGetBranchMedicineList === false
      ) {
        if (branchMedicine.branchMedicineList.branch_medicines.length) {
          setBranchMedicines(
            branchMedicine.branchMedicineList.branch_medicines[0]
          );
        } else {
          setBranchMedicines({});
        }

        // setStatusType(
        //   branchMedicine.branchMedicineById.branch_medicines[0]?.status_type
        // );
      }
    }
  }, [branchMedicine]);

  return (
    <Panel
      heading={
        viewMode
          ? t("translation:Branch Medicine Details")
          : t("translation:Edit Branch Medicine Details")
      }>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>

      <Collapse in={successAlarm}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:Branch Medicine has been submitted")}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <MedicineSelectionField
          medicineType={medicineType}
          setMedicineType={setMedicineType}
          medicine={medicine}
          setMedicine={setMedicine}
          setBranchMedicines={setBranchMedicines}
          branchId={branchId}
        />

        {medicine && branchMedicine.branchMedicineList && branchMedicines && (
          <>
            {branchMedicines.id && (
              <MedicineInOurTable branchMedicineId={branchMedicines?.id} />
            )}
            <BranchMedicineField
              viewMode={viewMode}
              branchMedicines={branchMedicines}
              setBranchMedicines={setBranchMedicines}
            />
          </>
        )}

        <RequiredNote />
        {viewMode && <PageOnlyBackAction setView={() => setView()} />}
        {!viewMode && <PageSubmitWBackAction setView={() => setView()} />}
      </form>
    </Panel>
  );
};

export default ProcessPage;
