//lib
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Grid, TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { Font } from "@react-pdf/renderer";
import Print from "@mui/icons-material/Print";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//actions

//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";

import { useStyles } from "../../../components/globalStyles";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import DeliveryLabelPDF from "../../../components/PDF/DeliveryLabelPDF";
import NotoSansHansRegular from "../../../components/PDF/NotoSansHans-Regular-2.ttf";

import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import SingleGroupOrderDetailPage from "../Store/SingleGroupOrderDetailPage";
// import ExportDeliveryOrders from "./components/ExportDeliveryOrders";
// import ExportDeliveryOrdersV2 from "./components/ExportDeliveryOrdersV2";
import ExportCourierDeliveryAddress from "./components/ExportCourierDeliveryAddress";
import ViewOptimizedRoutesDialog from "./components/ViewOptimizedRoutesDialog";
import ViewNonGroupingOptimizedRoutesDialog from "./components/ViewNonGroupingOptimizedRoutesDialog";
import { addressNonGrouping } from "./components/groupingfunction";

//register font before calling pdf file
Font.register({
  family: "NotoSansSChinese",
  fonts: [{ src: NotoSansHansRegular }],
});

Font.registerHyphenationCallback((word) =>
  word.length === 1 ? [word] : Array.from(word).map((char) => char)
);
export default function DetailDeliveryPage({
  orderInfo,
  setDeliveryOrderListView,
  setOrderInfo,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const prevSetPrintDateOnStatus = useRef();
  const prevSetGetLogoStatus = useRef();
  const prevSetGetOrganisationStatus = useRef();
  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const common = useSelector((state) => state.common);
  const deliveryOrder = useSelector((state) => state.deliveryOrder);
  const curUser = useSelector((state) => state.curUser);
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);

  const [orderDetailView, setOrderDetailView] = useState(false);

  const [orderDetailInfo, setOrderDetailInfo] = useState([]);
  const [print, setPrint] = React.useState(0);
  const [printDowoId, setPrintDowoId] = React.useState(null);
  const [alertMsg, setAlertMsg] = useState(null);

  const [alertType, setAlertType] = useState(null);

  const [msgAlarm, setMsgAlarm] = useState(null);

  const handleRowDetail = (value) => {
    //

    setOrderDetailInfo(value);
    setOrderDetailView(true);
  };

  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);
  const viewColumns = [
    {
      Header: "",
      accessor: "Print",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button
            title={t("translation:PRINT DELIVERY LABEL")}
            onClick={() => {
              setPrintDowoId(row.original.delivery_header.id);
              setPrint(new Date().valueOf());
            }}
            className={classes.grey}>
            <Print />
          </Button>
          {pdfFontLoaded &&
            print > 0 &&
            printDowoId === row.original.delivery_header.id && (
              <DeliveryLabelPDF
                t={t}
                deliverOrderDetails={orderInfo.delivery_order_details}
                dowo={row.original.delivery_header}
                courier={orderInfo.courier}
                doNumber={orderInfo.order_number}
                deliveryOwned={
                  row.original.delivery_header.store_medicine_header
                    .delivery_owned
                }
                print={print}
              />
            )}
        </>
      ),
    },
    // {
    //   Header: "",
    //   accessor: "Print1",

    //   //adjust sort
    //   sortable: false,
    //   Cell: ({ row }) => (
    //     <>
    //       {pdfFontLoaded &&
    //         row.original.delivery_header.store_medicine_header
    //           .delivery_incl_prescription &&
    //         TCMLogo.some(
    //           (item) =>
    //             item.id ===
    //             row.original.delivery_header.store_header.organisation.id
    //         ) &&
    //         organisationInfo.some(
    //           (item) =>
    //             item.organisation.id ===
    //             row.original.delivery_header.store_header.organisation.id
    //         ) && (
    //           <PatientPrescriptionPDF
    //             TCMLogo={
    //               TCMLogo.find(
    //                 (item) =>
    //                   item.id ===
    //                   row.original.delivery_header.store_header.organisation.id
    //               ).data
    //             }
    //             t={t}
    //             orderDetailInfo={row.original.delivery_header}
    //             printedId={row.original.id}
    //             measureUnit={
    //               common.medicineMeasureUnitList
    //                 ? common.medicineMeasureUnitList.measure_units
    //                 : []
    //             }
    //             organisationInfo={
    //               organisationInfo.find(
    //                 (item) =>
    //                   item.organisation.id ===
    //                   row.original.delivery_header.store_header.organisation.id
    //               ).organisation
    //             }
    //           />
    //         )}
    //     </>
    //   ),
    // },
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Store Order No"),
      sortable: false,
      accessor: "delivery_header.store_header.order_number",
    },
    {
      Header: t("translation:Group"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.name",
    },

    {
      Header: t("translation:Recipient"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_name",
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_name;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_name
            : "";
        }
      },
    },
    {
      Header: t("translation:Postal Code"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_postal_code",
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_postal_code;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_postal_code
            : "";
        }
      },
    },
    {
      Header: t("translation:Address"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_address",
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_address;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_address
            : "";
        }
      },
    },

    {
      Header: t("translation:Address Details"),
      sortable: false,
      accessor:
        "delivery_header.store_medicine_header.delivery_address_details",
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_address_details;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_address_details
            : "";
        }
      },
    },
    {
      Header: t("translation:Remarks"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_remarks",

      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_remarks;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_remarks
            : "";
        }
      },
    },
    {
      Header: t("translation:Link Delivery Services"),
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header.delivery_owned
            ?.status_type === generalConstants.APPROVED_STATUS_TYPE
        ) {
          return (
            row.original.delivery_header.store_medicine_header.delivery_owned
              .delivery_linked_store_medicine_header.store_header.order_number +
            "/" +
            row.original.delivery_header.store_medicine_header.delivery_owned
              .delivery_linked_store_medicine_header.name
          );
        } else if (
          row.original.delivery_header.store_medicine_header.delivery_owner.some(
            (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
          )
        ) {
          let result =
            row.original.delivery_header.store_medicine_header.delivery_owner.reduce(
              (a, b) => {
                if (b.status_type === generalConstants.APPROVED_STATUS_TYPE) {
                  return a + 1;
                }
                return a;
              },
              0
            );
          return result + " " + t("translation:Group");
        } else return "";
      },
    },
    {
      Header: t("translation:Proceed Date"),
      sortable: false,
      accessor: "store_medicine_header.proceed_on",
      Cell: ({ row }) => {
        if (row.original.delivery_header.store_medicine_header.proceed_on)
          return moment
            .utc(row.original.delivery_header.store_medicine_header.proceed_on)
            .local()
            .format("DD-MMM-YYYY");
        else return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.delivery_header.created_on)
          return moment
            .utc(d.delivery_header.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "delivery_header.status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //useEffects

  // useEffect(() => {
  //   const myArrayWithNoDuplicates = orderInfo.delivery_order_details.reduce(
  //     (accumulator, currentValue) => {
  //       if (
  //         !accumulator.includes(
  //           currentValue.delivery_header.store_header.organisation
  //         )
  //       ) {
  //         return [
  //           ...accumulator,
  //           currentValue.delivery_header.store_header.organisation,
  //         ];
  //       }
  //       return accumulator;
  //     },
  //     []
  //   );
  //   setOrganisationList(myArrayWithNoDuplicates);
  // }, [orderInfo]);

  useEffect(() => {
    prevSetPrintDateOnStatus.current = false;
    prevSetGetLogoStatus.current = false;
    prevSetGetOrganisationStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingPutDeliveryOrderPrintDate !==
      prevSetPrintDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintDateOnStatus.current =
        deliveryOrder.isLoadingPutDeliveryOrderPrintDate;

      if (
        deliveryOrder.putDeliveryOrderPrintDateIsSuccess === true &&
        deliveryOrder.isLoadingPutDeliveryOrderPrintDate === false
      ) {
        setOrderInfo(
          deliveryOrder.putDeliveryOrderPrintDate.delivery_order_header
        );
      }
    }
  }, [deliveryOrder, setOrderInfo]);

  // useEffect(() => {
  //   dispatch(getMedicineMeasureUnit.pending());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (organisationList.length > 0) {
  //     var res = organisationList.filter(function (o) {
  //       return TCMLogo.find(function (o2) {
  //         return o.id !== o2.id;
  //       });
  //     });

  //     if (res.length === 0 && TCMLogo.length === 0) {
  //       const asceSort = organisationList.sort((a, b) => a.id - b.id);
  //       dispatch(getOrganisationLogo.pending(asceSort[0].id));
  //     }

  //     if (res.length > 0 && TCMLogo.length < organisationList.length) {
  //       const descSort = organisationList.sort((a, b) => b.id - a.id);
  //       dispatch(getOrganisationLogo.pending(descSort[0].id));
  //     }
  //   }
  // }, [dispatch, organisationList, TCMLogo]);

  // useEffect(() => {
  //   if (curUser.isLoadingOrganisationLogo !== prevSetGetLogoStatus.current) {
  //     //
  //     //check the previous ref with current state
  //     prevSetGetLogoStatus.current = curUser.isLoadingOrganisationLogo;

  //     if (
  //       curUser.getOrganisationLogoIsSuccess === true &&
  //       curUser.isLoadingOrganisationLogo === false
  //     ) {
  //       setTCMLOGO((item) => [...item, curUser.OrganisationLogo]);
  //     }
  //   }
  // }, [curUser]);

  // useEffect(() => {
  //   if (organisationList.length > 0) {
  //     var res = organisationList.filter(function (o) {
  //       return organisationInfo.find(function (o2) {
  //         return o.id !== o2.organisation.id;
  //       });
  //     });

  //     if (res.length === 0 && organisationInfo.length === 0) {
  //       const asceSort = organisationList.sort((a, b) => a.id - b.id);
  //       dispatch(getOrganisation.pending(asceSort[0].id));
  //     }

  //     if (res.length > 0 && organisationInfo.length < organisationList.length) {
  //       const descSort = organisationList.sort((a, b) => b.id - a.id);
  //       dispatch(getOrganisation.pending(descSort[0].id));
  //     }
  //   }
  // }, [dispatch, organisationList, organisationInfo]);

  // useEffect(() => {
  //   if (
  //     curUser.isLoadingOrganisation !== prevSetGetOrganisationStatus.current
  //   ) {
  //     //
  //     //check the previous ref with current state
  //     prevSetGetOrganisationStatus.current = curUser.isLoadingOrganisation;

  //     if (
  //       curUser.getOrganisationIsSuccess === true &&
  //       curUser.isLoadingOrganisation === false
  //     ) {
  //       setOrganisationInfo((item) => [...item, curUser.organisation]);
  //     }
  //   }
  // }, [curUser]);

  if (common.getGeneralStatusIsSuccess === true && !orderDetailView) {
    return (
      <>
        {alertType !== null && alertMsg !== null && (
          <Collapse in={alertMsg}>
            <Alert
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertMsg(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {msgAlarm}
            </Alert>
          </Collapse>
        )}
        <Typography display="block" variant="h4">
          {orderInfo.order_number}
        </Typography>

        <Grid item xs={12}>
          {/* <ExportDeliveryOrders orderInfo={orderInfo} />
          <ExportDeliveryOrdersV2 orderInfo={orderInfo} /> */}
          <ExportCourierDeliveryAddress orderInfo={orderInfo} />
          <ViewNonGroupingOptimizedRoutesDialog
            groupOrder={addressNonGrouping(
              orderInfo.delivery_order_details.filter(
                (item) =>
                  item.status_type === generalConstants.APPROVED_STATUS_TYPE
              )
            )}
            key={"nonGroupingDialog"}
            setMsgAlarm={setMsgAlarm}
            setAlertType={setAlertType}
            setAlertMsg={setAlertMsg}
          />
          {curUser?.defaultBranch?.branch
            ?.is_work_order_delivery_tracking_required === true && (
            <ViewOptimizedRoutesDialog
              viewMode={true}
              key={"groupingDialog"}
              deliveryOrderId={orderInfo.id}
              setMsgAlarm={setMsgAlarm}
              setAlertType={setAlertType}
              setAlertMsg={setAlertMsg}
            />
          )}
        </Grid>

        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            disabled={true}
            value={orderInfo.courier}
            options={[]}
            disableClearable
            name="courier"
            id="courier"
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                {...params}
                label={t("translation:Courier")}
                variant="outlined"
              />
            )}
          />
        </Grid>
        {orderInfo.delivery_order_details.filter(
          (e) => e.status_type === generalConstants.APPROVED_STATUS_TYPE
        ).length > 0 && (
          <Grid item xs={12}>
            <SortingSearchTable
              disabledSearch
              statusOption={common.generalStatusList.status_general}
              columns={viewColumns}
              data={orderInfo.delivery_order_details.filter(
                (e) => e.status_type === generalConstants.APPROVED_STATUS_TYPE
              )}
              pageInfo={viewPageInfo}
              setPageInfo={setViewPageInfo}
              totalItems={orderInfo.delivery_order_details.length}
              disabledFooter
            />
          </Grid>
        )}
        <PageOnlyBackAction setView={() => setDeliveryOrderListView(false)} />
      </>
    );
  } else if (orderDetailView) {
    return (
      <SingleGroupOrderDetailPage
        orderDetailInfo={orderDetailInfo.delivery_header}
        setOrderDetailView={setOrderDetailView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
