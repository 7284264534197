import * as generalConstants from "../../../../_constants/generalConstants";

export const addressGrouping = (
  deliveryOrderDetailInfo,
  setDifferentDetailsList = () => null
) => {
  const deliveryOrderDetailInfoWoDeleted = deliveryOrderDetailInfo.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );

  const tempMergeLinkedDeliveryAddr = deliveryOrderDetailInfoWoDeleted.map(
    (item) => {
      if (item.delivery_header.store_medicine_header.delivery_owned) {
        return {
          ...item,

          delivery_header: {
            ...item.delivery_header,
            store_medicine_header: {
              ...item.delivery_header.store_medicine_header,
              delivery_name:
                item.delivery_header.store_medicine_header.delivery_owned
                  .delivery_linked_store_medicine_header.delivery_name,
              delivery_contact_number:
                item.delivery_header.store_medicine_header.delivery_owned
                  .delivery_linked_store_medicine_header
                  .delivery_contact_number,
              delivery_remarks:
                item.delivery_header.store_medicine_header.delivery_owned
                  .delivery_linked_store_medicine_header.delivery_remarks,
              delivery_address:
                item.delivery_header.store_medicine_header.delivery_owned
                  .delivery_linked_store_medicine_header.delivery_address,
              delivery_address_details:
                item.delivery_header.store_medicine_header.delivery_owned
                  .delivery_linked_store_medicine_header
                  .delivery_address_details,
              delivery_postal_code:
                item.delivery_header.store_medicine_header.delivery_owned
                  .delivery_linked_store_medicine_header.delivery_postal_code,
            },
          },
        };
      } else {
        return { ...item };
      }
    }
  );

  const result = Object.groupBy(
    tempMergeLinkedDeliveryAddr,
    ({ delivery_header }) =>
      delivery_header.store_medicine_header.delivery_address +
      delivery_header.store_medicine_header.delivery_address_details +
      delivery_header.store_medicine_header.delivery_postal_code
  );

  const addressKeys = Object.entries(result);

  const addrList = addressKeys.filter((item) => {
    const result = Object.groupBy(
      item[1],
      ({ delivery_header }) =>
        delivery_header.store_medicine_header.delivery_name +
        delivery_header.store_medicine_header.delivery_contact_number +
        delivery_header.store_medicine_header.delivery_remarks
    );
    const resultList = Object.entries(result);

    if (resultList.length > 1) {
      return resultList;
    }
  });

  const addrDifferentList = addrList.map((item) => {
    return {
      addrDifferentList: item[1],
    };
  });
  setDifferentDetailsList(addrDifferentList);
  return addrDifferentList;
};

export const addressNonGrouping = (deliveryOrderDetailInfo) => {
  const deliveryOrderDetailInfoWoDeleted = deliveryOrderDetailInfo.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );

  deliveryOrderDetailInfoWoDeleted.map((item, index) => {
    if (item.delivery_header.store_medicine_header.delivery_owned !== null) {
      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_name =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_name;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_remarks =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_remarks;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_address =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_address;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_address_details =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_address_details;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_postal_code =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_postal_code;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_contact_number =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_contact_number;
    }
  });

  const addressKeys = Object.entries(deliveryOrderDetailInfoWoDeleted);

  const addrDifferentList = addressKeys.map((item, index) => {
    return {
      delivery_address:
        item[1].delivery_header.store_medicine_header.delivery_address,
      delivery_address_details:
        item[1].delivery_header.store_medicine_header.delivery_address_details,

      delivery_contact_number:
        item[1].delivery_header.store_medicine_header.delivery_contact_number,
      delivery_name:
        item[1].delivery_header.store_medicine_header.delivery_name,
      delivery_postal_code:
        item[1].delivery_header.store_medicine_header.delivery_postal_code,
      delivery_remarks:
        item[1].delivery_header.store_medicine_header.delivery_remarks,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
    };
  });
  return addrDifferentList;
};

export const addressGroupingSort = (deliveryOrderDetailInfo, setGroupOrder) => {
  const deliveryOrderDetailInfoWoDeleted = deliveryOrderDetailInfo.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );

  deliveryOrderDetailInfoWoDeleted.map((item, index) => {
    if (item.delivery_header.store_medicine_header.delivery_owned !== null) {
      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_name =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_name;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_remarks =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_remarks;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_address =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_address;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_address_details =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_address_details;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_postal_code =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_postal_code;

      deliveryOrderDetailInfoWoDeleted[
        index
      ].delivery_header.store_medicine_header.delivery_contact_number =
        item.delivery_header.store_medicine_header.delivery_owned.delivery_linked_store_medicine_header.delivery_contact_number;
    }
  });

  const result = Object.groupBy(
    deliveryOrderDetailInfoWoDeleted,
    ({ delivery_header }) =>
      delivery_header.store_medicine_header.delivery_address +
      delivery_header.store_medicine_header.delivery_address_details +
      delivery_header.store_medicine_header.delivery_postal_code
  );

  const addressKeys = Object.entries(result);

  const addrDifferentList = addressKeys.map((item, index) => {
    return {
      delivery_address:
        item[1][0].delivery_header.store_medicine_header.delivery_address,
      delivery_address_details:
        item[1][0].delivery_header.store_medicine_header
          .delivery_address_details,

      delivery_contact_number:
        item[1][0].delivery_header.store_medicine_header
          .delivery_contact_number,
      delivery_name:
        item[1][0].delivery_header.store_medicine_header.delivery_name,
      delivery_postal_code:
        item[1][0].delivery_header.store_medicine_header.delivery_postal_code,
      delivery_remarks:
        item[1][0].delivery_header.store_medicine_header.delivery_remarks,
      //for sorting
      id: index + 1,
      realId: 0,
      row_no: index + 1,
      status_type: generalConstants.APPROVED_STATUS_TYPE,
    };
  });
  setGroupOrder(addrDifferentList);
  return addrDifferentList;
};
