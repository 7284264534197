import * as types from "../_constants/userDeviceKeyConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_USERDEVICEKEYBYID_ASYNC.PENDING:
      return {
        ...state,
        getUserDeviceKeyByIdIsSuccess: false,
        isLoadingGetUserDeviceKeyById: true,
        getUserDeviceKeyById: null,
      };

    case types.GET_USERDEVICEKEYBYID_ASYNC.ERROR:
      return {
        ...state,
        getUserDeviceKeyByIdIsSuccess: false,
        isLoadingGetUserDeviceKeyById: false,
        getUserDeviceKeyById: null,
        error: { ...state.error, getUserDeviceKeyByIdError: action.error },
      };

    case types.GET_USERDEVICEKEYBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getUserDeviceKeyByIdIsSuccess: true,
        isLoadingGetUserDeviceKeyById: false,
        getUserDeviceKeyById: action.data,
      };

    case types.GET_USERDEVICEKEYLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserDeviceKeyListIsSuccess: false,
        isLoadingGetUserDeviceKeyList: true,
        getUserDeviceKeyList: null,
      };

    case types.GET_USERDEVICEKEYLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserDeviceKeyListIsSuccess: false,
        isLoadingGetUserDeviceKeyList: false,
        getUserDeviceKeyList: null,
        error: { ...state.error, getUserDeviceKeyListError: action.error },
      };

    case types.GET_USERDEVICEKEYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserDeviceKeyListIsSuccess: true,
        isLoadingGetUserDeviceKeyList: false,
        getUserDeviceKeyList: action.data,
      };

    case types.POST_CREATEUSERDEVICEKEY_ASYNC.PENDING:
      return {
        ...state,
        postCreateUserDeviceKeyIsSuccess: false,
        isLoadingPostCreateUserDeviceKey: true,
        postCreateUserDeviceKey: null,
      };

    case types.POST_CREATEUSERDEVICEKEY_ASYNC.ERROR:
      return {
        ...state,
        postCreateUserDeviceKeyIsSuccess: false,
        isLoadingPostCreateUserDeviceKey: false,
        postCreateUserDeviceKey: null,
        error: { ...state.error, postCreateUserDeviceKeyError: action.error },
      };

    case types.POST_CREATEUSERDEVICEKEY_ASYNC.SUCCESS:
      return {
        ...state,
        postCreateUserDeviceKeyIsSuccess: true,
        isLoadingPostCreateUserDeviceKey: false,
        postCreateUserDeviceKey: action.data,
      };

    case types.PUT_UPDATEUSERDEVICEKEY_ASYNC.PENDING:
      return {
        ...state,
        putUpdateUserDeviceKeyIsSuccess: false,
        isLoadingPutUpdateUserDeviceKey: true,
        putUpdateUserDeviceKey: null,
      };

    case types.PUT_UPDATEUSERDEVICEKEY_ASYNC.ERROR:
      return {
        ...state,
        putUpdateUserDeviceKeyIsSuccess: false,
        isLoadingPutUpdateUserDeviceKey: false,
        putUpdateUserDeviceKey: null,
        error: { ...state.error, putUpdateUserDeviceKeyError: action.error },
      };

    case types.PUT_UPDATEUSERDEVICEKEY_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateUserDeviceKeyIsSuccess: true,
        isLoadingPutUpdateUserDeviceKey: false,
        putUpdateUserDeviceKey: action.data,
      };

    case types.GET_USERDEVICEKEYUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getUserDeviceKeyUserListIsSuccess: false,
        isLoadingGetUserDeviceKeyUserList: true,
        getUserDeviceKeyUserList: null,
      };

    case types.GET_USERDEVICEKEYUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getUserDeviceKeyUserListIsSuccess: false,
        isLoadingGetUserDeviceKeyUserList: false,
        getUserDeviceKeyUserList: null,
        error: { ...state.error, getUserDeviceKeyUserListError: action.error },
      };

    case types.GET_USERDEVICEKEYUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getUserDeviceKeyUserListIsSuccess: true,
        isLoadingGetUserDeviceKeyUserList: false,
        getUserDeviceKeyUserList: action.data,
      };
    default:
      return state;
  }
};
