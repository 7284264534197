import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/reportingConstants";

export const getReportingInvoiceDetailsListByStore = {
  pending: (data) =>
    createAction(types.GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingInvoiceSummaryListByStore = {
  pending: (data) =>
    createAction(types.GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingInvoiceMedicineCostingDetailsListByStore = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC.ERROR,
      { error }
    ),
};

export const getReportingInvoiceMedicineCostingListByStore = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.ERROR,
      { error }
    ),
};

export const getReportingInvoiceDetailsListByVisit = {
  pending: (data) =>
    createAction(types.GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingInvoiceSummaryListByVisit = {
  pending: (data) =>
    createAction(types.GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingInvoiceMedicineCostingDetailsListByVisit = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC.ERROR,
      { error }
    ),
};

export const getReportingInvoiceMedicineCostingListByVisit = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.ERROR,
      { error }
    ),
};

export const getReportingExportInvoiceDetailsListByStore = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getReportingExportInvoiceSummaryListByStore = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getReportingExportInvoiceMedicineCostingDetailsListByStore = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
        .PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
        .SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC
        .ERROR,
      { error }
    ),
};

export const getReportingExportInvoiceMedicineCostingListByStore = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC.ERROR,
      { error }
    ),
};

export const getReportingExportInvoiceDetailsListByVisit = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getReportingExportInvoiceSummaryListByVisit = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const getReportingExportInvoiceMedicineCostingDetailsListByVisit = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
        .PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
        .SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC
        .ERROR,
      { error }
    ),
};

export const getReportingExportInvoiceMedicineCostingListByVisit = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.PENDING,
      { data }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.SUCCESS,
      { data }
    ),
  error: (error) =>
    createAction(
      types.GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC.ERROR,
      { error }
    ),
};

export const getReportingExportStoreMedicineHeader = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportMedicineInOut = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC.ERROR, { error }),
};

export const getReportingExportMedicineInOutDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportAcupoints = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTACUPOINTS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTACUPOINTS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTACUPOINTS_ASYNC.ERROR, { error }),
};

export const postReportingVerifyCSVAcupoints = {
  pending: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC.ERROR, { error }),
};

export const postReportingImportCSVAcupoints = {
  pending: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC.ERROR, { error }),
};

export const getReportingExportTreatments = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTTREATMENTS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTTREATMENTS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTTREATMENTS_ASYNC.ERROR, { error }),
};

export const postReportingVerifyCSVTreatments = {
  pending: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportCSVTreatments = {
  pending: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportMedicines = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINES_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINES_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTMEDICINES_ASYNC.ERROR, { error }),
};

export const postReportingVerifyCSVMedicines = {
  pending: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVMEDICINES_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVMEDICINES_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYCSVMEDICINES_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportCSVMedicines = {
  pending: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVMEDICINES_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVMEDICINES_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTCSVMEDICINES_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingMedicinesHeaderNames = {
  pending: (data) =>
    createAction(types.GET_REPORTINGMEDICINESHEADERNAMES_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGMEDICINESHEADERNAMES_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGMEDICINESHEADERNAMES_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingVerifyMedicinesWColumns = {
  pending: (data) =>
    createAction(types.POST_REPORTINGVERIFYMEDICINESWCOLUMNS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGVERIFYMEDICINESWCOLUMNS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYMEDICINESWCOLUMNS_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportMedicinesWColumns = {
  pending: (data) =>
    createAction(types.POST_REPORTINGIMPORTMEDICINESWCOLUMNS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGIMPORTMEDICINESWCOLUMNS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTMEDICINESWCOLUMNS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportFormulas = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTFORMULAS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTFORMULAS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTFORMULAS_ASYNC.ERROR, { error }),
};

export const postReportingVerifyCSVFormulas = {
  pending: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVFORMULAS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVFORMULAS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYCSVFORMULAS_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportCSVFormulas = {
  pending: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVFORMULAS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVFORMULAS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTCSVFORMULAS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportFormulaMedicine = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingVerifyCSVFormulaMedicine = {
  pending: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportCSVFormulaMedicine = {
  pending: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportVisitNotesDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportStoreMedicineDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportBranchMedicine = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingVerifyCSVBranchMedicine = {
  pending: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportCSVBranchMedicine = {
  pending: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportFixedMedicinePrice = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingVerifyCSVFixedMedicinePrice = {
  pending: (data) =>
    createAction(
      types.POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportCSVFixedMedicinePrice = {
  pending: (data) =>
    createAction(
      types.POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingVerifyFixedMedicinePriceSync = {
  pending: (data) =>
    createAction(
      types.POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC.ERROR, {
      error,
    }),
};

export const postReportingImportFixedMedicinePriceSync = {
  pending: (data) =>
    createAction(
      types.POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC.ERROR, {
      error,
    }),
};

export const getMedicineFixedSellingPriceHeader = {
  pending: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportUserCredits = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTUSERCREDITS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTUSERCREDITS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTUSERCREDITS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportUserCreditsStatement = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingBillAbleInvoiceByStore = {
  pending: (data) =>
    createAction(types.GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportBillAbleInvoiceByStore = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingBillAbleInvoiceByVisit = {
  pending: (data) =>
    createAction(types.GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportBillAbleInvoiceByVisit = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingBillAbleIMCByStore = {
  pending: (data) =>
    createAction(types.GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportBillAbleIMCByStore = {
  pending: (data) =>
    createAction(types.GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingBillAbleIMCByVisit = {
  pending: (data) =>
    createAction(types.GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportBillAbleIMCByVisit = {
  pending: (data) =>
    createAction(types.GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingPurchaseOrderSummary = {
  pending: (data) =>
    createAction(types.GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportPurchaseOrderSummary = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingPurchaseOrderDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGPURCHASEORDERDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGPURCHASEORDERDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGPURCHASEORDERDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportPurchaseOrderDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingDeliveryTrackingDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGDELIVERYTRACKINGDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGDELIVERYTRACKINGDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGDELIVERYTRACKINGDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportDeliveryTrackingDetails = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTDELIVERYTRACKINGDETAILS_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTDELIVERYTRACKINGDETAILS_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTDELIVERYTRACKINGDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportDeliveryTrackingSummary = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTDELIVERYTRACKINGSUMMARY_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTDELIVERYTRACKINGSUMMARY_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTDELIVERYTRACKINGSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportAppointmentDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTAPPOINTMENTDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTAPPOINTMENTDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTAPPOINTMENTDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportAppointmentSummary = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTAPPOINTMENTSUMMARY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTAPPOINTMENTSUMMARY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTAPPOINTMENTSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportDispensaryDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTDISPENSARYDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTDISPENSARYDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTDISPENSARYDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportDispensarySummary = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEXPORTDISPENSARYSUMMARY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEXPORTDISPENSARYSUMMARY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTDISPENSARYSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportSMSMessageInOutDetails = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTSMSMESSAGEINOUTDETAILS_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTSMSMESSAGEINOUTDETAILS_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTSMSMESSAGEINOUTDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportSMSMessageInOutSummary = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTSMSMESSAGEINOUTSUMMARY_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTSMSMESSAGEINOUTSUMMARY_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTSMSMESSAGEINOUTSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingEarningInsightsSummary = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEARNINGINSIGHTSSUMMARY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEARNINGINSIGHTSSUMMARY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEARNINGINSIGHTSSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportEarningInsightsSummary = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTEARNINGINSIGHTSSUMMARY_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTEARNINGINSIGHTSSUMMARY_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTEARNINGINSIGHTSSUMMARY_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingEarningInsightsDetails = {
  pending: (data) =>
    createAction(types.GET_REPORTINGEARNINGINSIGHTSDETAILS_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_REPORTINGEARNINGINSIGHTSDETAILS_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_REPORTINGEARNINGINSIGHTSDETAILS_ASYNC.ERROR, {
      error,
    }),
};

export const getReportingExportEarningInsightsDetails = {
  pending: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTEARNINGINSIGHTSDETAILS_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.GET_REPORTINGEXPORTEARNINGINSIGHTSDETAILS_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(types.GET_REPORTINGEXPORTEARNINGINSIGHTSDETAILS_ASYNC.ERROR, {
      error,
    }),
};
