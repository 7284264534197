//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
//action
import { postCreateUserDeviceKey } from "../../../../actions/userDeviceKeyActions";

//components
import Panel from "../../../../components/Panel";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { useStyles } from "../../../../components/globalStyles";
import RequiredNote from "../../../../components/RequiredNote";
import PageSubmitWBackAction from "../../../../components/PageSubmitWBackAction";
import PageOnlyBackAction from "../../../../components/PageOnlyBackAction";
import UserSearch from "./components/UserSearch";

const AddUserDeviceKeyPage = ({ setListView }) => {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [successAlarm, setSuccessAlarm] = useState(false);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [temViewOnly, setTemViewOnly] = useState(false);
  const [user, setUser] = useState(null);
  const [msgAlarm, setMsgAlarm] = useState("");
  const common = useSelector((state) => state.common);
  const curUser = useSelector((state) => state.curUser);
  const userDeviceKey = useSelector((state) => state.userDeviceKey);
  const [disableSummaryView, setDisableSummaryView] = React.useState(false);

  const [defaultUserSearchState, setDefaultUserSearchState] = useState(null);

  const prevPostUserDeviceKeyStatus = useRef();

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      name: null,
      device_key: null,
      status_type: null,
    },
  });

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [JsonText, setJsonText] = useState(null);

  //functions

  const getOpObj = (option, list) => {
    if (!option.id && list) option = list.find((op) => op.id === option);
    return option;
  };

  const onSubmit = (data) => {
    if (user) {
      setJsonText(data);
      setConfirmSubmit(true);
    } else {
      setErrorAlarm(true);
      setMsgAlarm(t("translation:Missing User"));
    }
  };

  const onConfirmSubmit = (data) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    const defaultOrganisation =
      curUser.organisationInfo.user_organisations.find(
        (item) => item.is_default === true
      );
    const submitJson = {
      user_device_key: {
        id: 0,
        status_type: data.status_type,
        name: data.name.trim(),
        user: { id: user.id },
        alliance: { id: defaultOrganisation.alliance.id },
        branch: { id: defaultOrganisation.organisation_branch.branch.id },
        organisation: { id: defaultOrganisation.organisations.id },
        device_key: data.device_key,
      },
    };
    dispatch(postCreateUserDeviceKey.pending(submitJson));
  };

  //useEffects
  useEffect(() => {
    //set ref when first render
    prevPostUserDeviceKeyStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      userDeviceKey.isLoadingPostCreateUserDeviceKey !==
      prevPostUserDeviceKeyStatus.current
    ) {
      //check the previous ref with current state
      prevPostUserDeviceKeyStatus.current =
        userDeviceKey.isLoadingPostCreateUserDeviceKey;

      if (
        userDeviceKey.postCreateUserDeviceKeyIsSuccess === true &&
        userDeviceKey.isLoadingPostCreateUserDeviceKey === false
      ) {
        setSuccessAlarm(true);
        setErrorAlarm(false);

        reset({
          ...userDeviceKey.postCreateUserDeviceKey.user_device_key,
        });
        window.scrollTo(0, 0);
        setTemViewOnly(true);
      }
      if (
        userDeviceKey.postCreateUserDeviceKeyIsSuccess === false &&
        userDeviceKey.isLoadingPostCreateUserDeviceKey === false
      ) {
        setSuccessAlarm(false);
        setErrorAlarm(true);
        setMsgAlarm(userDeviceKey.error.postCreateUserDeviceKeyError);
        window.scrollTo(0, 0);
      }
    }
  }, [userDeviceKey, reset]);

  return (
    <Panel heading={t("translation:User Device Key Details")}>
      <ConfirmDialog
        Yes={t("translation:Yes")}
        No={t("translation:No")}
        title={t("translation:Confirmation")}
        textValue={JsonText}
        open={confirmSubmit}
        setOpen={setConfirmSubmit}
        onConfirm={onConfirmSubmit}>
        {t("translation:Are you sure you want to proceed?")}
      </ConfirmDialog>
      <Collapse in={successAlarm}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {t("translation:User Device Key has been created")}
        </Alert>
      </Collapse>

      <Collapse in={errorAlarm}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorAlarm(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {msgAlarm}
        </Alert>
      </Collapse>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.gridRoot} container>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="name"
              label={t("translation:Name")}
              variant="outlined"
              {...register("name")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              disabled={temViewOnly}
              className={classes.userFieldColor}
              required
              name="device_key"
              label={t("translation:Device Key")}
              variant="outlined"
              {...register("device_key")}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfoField}>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  disabled={temViewOnly}
                  {...field}
                  name="status_type"
                  id="status_type"
                  required
                  options={
                    common.generalStatusList.status_general
                      ? common.generalStatusList.status_general
                      : []
                  }
                  getOptionLabel={(option) =>
                    getOpObj(option, common.generalStatusList.status_general)
                      .name
                  }
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value) {
                      return option;
                    }
                  }}
                  onChange={(e, option) =>
                    field.onChange(option == null ? null : option.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      disabled={temViewOnly}
                      required
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Status Type")}
                      variant="outlined"
                    />
                  )}
                />
              )}
              name="status_type"
              control={control}
            />
          </Grid>

          <UserSearch
            user={user}
            temViewOnly={temViewOnly}
            setUser={setUser}
            disableSummaryView={disableSummaryView}
            setDisableSummaryView={setDisableSummaryView}
            defaultUserSearchState={defaultUserSearchState}
            setDefaultUserSearchState={setDefaultUserSearchState}
          />

          <>
            <RequiredNote />
            {temViewOnly ? (
              <PageOnlyBackAction setView={() => setListView(true)} />
            ) : (
              <PageSubmitWBackAction setView={() => setListView(true)} />
            )}
          </>
        </Grid>
      </form>
    </Panel>
  );
};

export default AddUserDeviceKeyPage;
