//lib
import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//components
import * as generalConstants from "../../../_constants/generalConstants";
import StoreListTable from "./StoreListTable";
import { useStyles } from "../../../components/globalStyles";

//actions

const OrderSummaryPage = () => {
  //variables
  const classes = useStyles();
  const { t } = useTranslation();
  const [storeInformationView, setStoreInformationView] = React.useState(false);
  const [confirmAddOrderOpen, setConfirmAddOrderOpen] = React.useState(false);
  const [wikiOpen, setWikiOpen] = React.useState(false);
  //useEffects

  const curUser = useSelector((state) => state.curUser);
  const addOrderAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_VISIT_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.CREATE_PERMISSION_TYPE
      )
    : false;

  return (
    <Paper
      className={wikiOpen ? classes.summaryPaper65 : classes.summaryPaper}
      elevation={3}>
      {!storeInformationView && !confirmAddOrderOpen && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Visit Order Summary")}
            </Typography>
          </Grid>

          {addOrderAccess && (
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonColor}
                onClick={() => setConfirmAddOrderOpen(true)}>
                {t("translation:Add")}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {confirmAddOrderOpen && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <Typography className={classes.title} variant="h3" gutterBottom>
              {t("translation:Add Visit Order")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <hr />
      <StoreListTable
        setStoreInformationView={setStoreInformationView}
        setConfirmAddOrderOpen={setConfirmAddOrderOpen}
        confirmAddOrderOpen={confirmAddOrderOpen}
        setWikiOpen={setWikiOpen}
      />
    </Paper>
  );
};

export default OrderSummaryPage;
