import apiConstants from "../_constants/apiConstants";
import axios from "axios";
import { headerWithauth } from "../_helper/apiHeader";

const commonApis = {
  getRaceApi,
  getGenderApi,
  getMaritalApi,
  getNationalityApi,
  getRightApi,
  getPermissionApi,
  getLanguageApi,
  getMedicineConsumeTimeApi,
  getMedicineMeasureUnitApi,
  getGeneralStatusApi,
  getWaterAmountApi,
  getPaymentMethodApi,
  getPaymentTermApi,
  getUserCreditTypeApi,
  getInOutTypeApi,
  getViewNeedsApi,
  getSystemSettingsApi,
  getMfaTypeApi,
  getWorkOrderTypeApi,
  getPostalCodeDistrictApi,
};

async function getRaceApi() {
  return await axios
    .get(apiConstants.apiGetRace)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getGenderApi() {
  return await axios
    .get(apiConstants.apiGetGender)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMaritalApi() {
  return await axios
    .get(apiConstants.apiGetMarital)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getNationalityApi() {
  return await axios
    .get(apiConstants.apiGetNationality)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getRightApi() {
  return await axios
    .get(apiConstants.apiGetRight)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPermissionApi() {
  return await axios
    .get(apiConstants.apiGetPermission)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getViewNeedsApi() {
  return await axios
    .get(apiConstants.apiGetViewNeeds)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getLanguageApi() {
  return await axios
    .get(apiConstants.apiGetLanguage)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineConsumeTimeApi() {
  return await axios
    .get(apiConstants.apiGetMedicineConsumeTime)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMedicineMeasureUnitApi() {
  return await axios
    .get(apiConstants.apiGetMedicineMeasureUnit)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getGeneralStatusApi() {
  return await axios
    .get(apiConstants.apiGetGeneralStatus)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWaterAmountApi() {
  return await axios
    .get(apiConstants.apiGetWaterAmount)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPaymentMethodApi() {
  return await axios
    .get(apiConstants.apiGetPaymentMethod)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPaymentTermApi() {
  return await axios
    .get(apiConstants.apiGetPaymentTerm)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getUserCreditTypeApi() {
  return await axios
    .get(apiConstants.apiGetUserCreditType)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInOutTypeApi() {
  return await axios
    .get(apiConstants.apiGetInOutType)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getSystemSettingsApi() {
  return await axios
    .get(apiConstants.apiGetSystemSettings)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getMfaTypeApi() {
  return await axios
    .get(apiConstants.apiGetMfaType)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getWorkOrderTypeApi() {
  return await axios
    .get(apiConstants.apiGetWorkOrderType)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getPostalCodeDistrictApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .post(apiConstants.apiGetPostalCodeDistrict, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default commonApis;
