//lib
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Alert from "@mui/material/Alert";
//actions
import { getUserDeviceKeyUserList } from "../../../../../actions/userDeviceKeyActions";

//components
import { MultiFieldSortingSearchTable } from "../../../../../components/MultiFieldSortingSearchTable";

import * as pageConfigure from "../../../../../_constants/pageConstants";

import { useStyles } from "../../../../../components/globalStyles";

import { ReactComponent as DetailLogo } from "../../../../../assets/coolicons/file/file_blank_outline.svg";

import { UserDetailPage } from "../../../user/UserSummary/UserDetailPage";

import StyledRadioCheckbox from "../../../../../components/StyledRadioCheckbox";

import { SortingSearchTable } from "../../../../../components/SortingSearchTable";

import ConfirmDialog from "../../../../../components/ConfirmDialog";

export default function UserSearch({
  user,
  temViewOnly,
  setUser,
  disableSummaryView,
  setDisableSummaryView,
  defaultUserSearchState,
  setDefaultUserSearchState,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { t } = useTranslation();

  const columns = [
    {
      Header: "",
      accessor: "checkUserDeviceKey",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                user !== null && user.id === row.original.id ? true : false
              }
              name="checkUserDeviceKey"
              onChange={(e) => handleUserDeviceKeyCheck(e, row)}
            />
          </>
        );
      },
    },

    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    // {
    //   Header: "",
    //   accessor: "Edit",

    //   //adjust sort
    //   sortable: false,
    //   Cell: ({ row }) => (
    //     <>
    //       <Button onClick={() => handleRowEdit(row.original)}>
    //         <EditLogo title={t("translation:Edit")} />
    //       </Button>
    //     </>
    //   ),
    // },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "identification_number",
    },
  ];
  const viewColumns = [
    {
      Header: t("translation:Name"),
      accessor: "name",
    },

    {
      Header: t("translation:Contact Number"),
      accessor: "contact_number",
    },
    {
      Header: t("translation:Identification Number"),
      accessor: "identification_number",
    },
  ];

  //variables
  const userDeviceKey = useSelector((state) => state.userDeviceKey);
  const [userDetailInfo, setUserDetailInfo] = React.useState([]);
  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });
  const [pageInfo, setPageInfo] = useState({
    order_by_direction: null,
    order_by_field: null,

    search_contact_number_value: null,
    search_date_of_birth_value: null,
    search_identification_number_value: null,
    search_name_value: null,
    search_username_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
  });

  const [searchValue, setSearchValue] = useState({
    name: null,
    contactNo: null,
    dateofBirth: null,
    identificationNo: null,
  });

  //functions

  const handleRowDetail = (value) => {
    setUserDetailInfo(value);
    setDisableSummaryView(true);
  };
  const handleUserDeviceKeyCheck = (e, row) => {
    if (e.target.checked) {
      setUser(row.original);
    } else {
      setUser(null);
    }
  };

  const handleSearch = (event) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_contact_number_value: searchValue.contactNo,
      search_date_of_birth_value: searchValue.dateofBirth,
      search_identification_number_value: searchValue.identificationNo,
      search_name_value: searchValue.name,
    });
  };

  useEffect(() => {
    if (defaultUserSearchState) {
      //set the default search value and added a new user value
      setSearchValue({
        name: defaultUserSearchState.name,
        contactNo: defaultUserSearchState.contact_number,
        dateofBirth: defaultUserSearchState.date_of_birth
          ? defaultUserSearchState.date_of_birth
          : null,
        identificationNo: defaultUserSearchState.identification_number,
      });
      setDefaultUserSearchState(null);
      // setPageInfo({
      //   ...pageInfo,
      //   search_contact_number_value: defaultUserSearchState.contactNo,
      //   search_date_of_birth_value: defaultUserSearchState.dateofBirth,
      //   search_identification_number_value:
      //     defaultUserSearchState.identificationNo,
      //   search_name_value: defaultUserSearchState.name,
      // });
    }
  }, [defaultUserSearchState, setDefaultUserSearchState]);

  useEffect(() => {
    dispatch(getUserDeviceKeyUserList.pending(pageInfo));
  }, [dispatch, pageInfo]);

  if (
    userDeviceKey.getUserDeviceKeyUserListIsSuccess === false &&
    userDeviceKey.isLoadingGetUserDeviceKeyUserList === false
  ) {
    return (
      <Alert severity="error">
        {userDeviceKey.error.getUserDeviceKeyUserListError}
      </Alert>
    );
  } else if (userDeviceKey.getUserDeviceKeyUserList) {
    return (
      <Grid item xs={12}>
        <ConfirmDialog
          open={disableSummaryView}
          setOpen={setDisableSummaryView}
          maxWidth="xl">
          <UserDetailPage
            user={userDetailInfo}
            setUserDetailView={() => {
              setDisableSummaryView(false);
            }}
          />
        </ConfirmDialog>
        {user && (
          <Grid item xs={12}>
            <SortingSearchTable
              disabledSearch
              columns={viewColumns}
              data={[user]}
              pageInfo={viewPageInfo}
              setPageInfo={setViewPageInfo}
              totalItems={1}
              disabledFooter
            />
          </Grid>
        )}
        {!temViewOnly && (
          <MultiFieldSortingSearchTable
            searchPlaceholder={""}
            columns={columns}
            data={
              userDeviceKey.getUserDeviceKeyUserList
                ? userDeviceKey.getUserDeviceKeyUserList.users
                : []
            }
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            pageCount={
              userDeviceKey.getUserDeviceKeyUserList
                ? userDeviceKey.getUserDeviceKeyUserList.total_pages
                : 0
            }
            loading={false}
            totalItems={
              userDeviceKey.getUserDeviceKeyUserList
                ? userDeviceKey.getUserDeviceKeyUserList.total_items
                : 0
            }
            handleSearch={handleSearch}>
            <TextField
              className={classes.userFieldColor}
              label={t("translation:Name")}
              variant="outlined"
              value={searchValue.name ? searchValue.name : ""}
              onChange={(e) =>
                setSearchValue({ ...searchValue, name: e.target.value })
              }
            />

            <TextField
              className={classes.userFieldColor}
              label={t("translation:Contact Number")}
              variant="outlined"
              value={searchValue.contactNo ? searchValue.contactNo : ""}
              onChange={(e) =>
                setSearchValue({ ...searchValue, contactNo: e.target.value })
              }
            />

            <TextField
              className={classes.userFieldColor}
              label={t("translation:Identification Number")}
              variant="outlined"
              autoComplete="off"
              value={
                searchValue.identificationNo ? searchValue.identificationNo : ""
              }
              onChange={(e) =>
                setSearchValue({
                  ...searchValue,
                  identificationNo: e.target.value,
                })
              }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={classes.userFieldColor}
                inputVariant="outlined"
                id="date"
                label={t("translation:Date of Birth")}
                autoOk={true}
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                value={
                  searchValue.dateofBirth
                    ? dayjs(searchValue.dateofBirth)
                    : null
                }
                format="DD MMM YYYY"
                onChange={(e) =>
                  setSearchValue({
                    ...searchValue,
                    dateofBirth: dayjs(e, true).isValid()
                      ? dayjs(e).format("YYYY-MM-DD")
                      : null,
                  })
                }
              />
            </LocalizationProvider>
          </MultiFieldSortingSearchTable>
        )}
      </Grid>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
