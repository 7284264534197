//lib
import React, { useState, useEffect } from "react";
import { useTable, useRowSelect } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//Actions
import {
  getWikiAcupointList,
  getWikiCustomWordList,
  getWikiTreatmentTypes,
  getWikiTreatmentList,
  getWikiMedicineTypes,
  getWikiMedicineList,
} from "../../../../../actions/wikiDataActions";

//Component
import { ReactComponent as DeleteIcon } from "../../../../../assets/coolicons/basic/trash_empty.svg";
import { useStyles } from "../../../../../components/globalStyles";
import { useStyles as tableStyles } from "../../../../../components/globalTableStyles";
import * as generalConstants from "../../../../../_constants/generalConstants";
import EditableCell from "../../../../../components/EditableCell";
import Panel from "../../../../../components/Panel";

function Table({ columns, data, disablePageResetOnDataChange }) {
  const defaultColumn = {
    // And also our default editable cell
    Cell: EditableCell,
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        disablePageResetOnDataChange,
      },

      useRowSelect
    );
  const paginationClasses = tableStyles();
  const { t } = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <MaUTable {...getTableProps()}>
          <TableHead className={paginationClasses.headStyle}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={paginationClasses.headCellStyle}>
                  {t("translation:S/N")}
                </TableCell>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    //check sortable
                    className={paginationClasses.headCellStyle}
                    {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <TableRow
                    className={i % 2 ? paginationClasses.rowBackground : ""}
                    {...row.getRowProps()}>
                    <TableCell className={paginationClasses.narrowCellStyle}>
                      {i + 1}
                    </TableCell>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={paginationClasses.narrowCellStyle}
                          {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: cell.column.editable,
                            editableNum: cell.column.editableNum,
                          })}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
}

export default function NodeItemTable({
  data = [],
  setData,
  temViewOnly = false,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();

  //Initialization of pageinfo

  const dispatch = useDispatch();
  const wikiData = useSelector((state) => state.wikiData);
  //Init

  const [selectedNodeItem, setSelectedNodeItem] = useState("");
  const [nodeListType, setNodeListType] = useState([
    { name: t("translation:medicines"), key: "medicines" },
    { name: t("translation:acupoints"), key: "acupoints" },
    { name: t("translation:treatments"), key: "treatments" },
    { name: t("translation:review notes"), key: "review_notes" },
    { name: t("translation:tongue diagnosis"), key: "tongue_diagnosis" },
    { name: t("translation:pulse diagnosis"), key: "pulse_diagnosis" },
    { name: t("translation:physician diagnosis"), key: "physician_diagnosis" },
    { name: t("translation:syndrome"), key: "syndrome" },
    { name: t("translation:western diagnosis"), key: "western_diagnosis" },
    { name: t("translation:physician advice"), key: "physician_advice" },
    { name: t("translation:others"), key: "others" },
  ]);
  const [tabValue, setTabValue] = React.useState("medicines");

  const [disableTreatment, setDisableTreatment] = useState(true);
  const [selectedTreatmentTypeItem, setSelectedTreatmentTypeItem] =
    useState(null);

  const [disableMedicine, setDisableMedicine] = useState(true);
  const [selectedMedicineTypeItem, setSelectedMedicineTypeItem] =
    useState(null);
  const columns = [
    {
      Header: "",
      accessor: "Delete",
      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          {!temViewOnly && (
            <Button onClick={() => handleRowDel(row.original.id, "delete")}>
              <DeleteIcon title={t("translation:Delete")} />
            </Button>
          )}
        </>
      ),
    },
    {
      Header: t("translation:Name"),
      accessor: "name",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === "acupoints" && !temViewOnly) {
      dispatch(
        getWikiAcupointList.pending({
          is_pagination: false,
        })
      );
    }
    if (newValue === "treatments" && !temViewOnly) {
      dispatch(
        getWikiTreatmentTypes.pending({
          is_pagination: false,
        })
      );
    }
    if (
      newValue !== "acupoints" &&
      newValue !== "medicines" &&
      newValue !== "treatments" &&
      !temViewOnly
    ) {
      dispatch(
        getWikiCustomWordList.pending({
          is_pagination: false,
        })
      );
    }
  };
  const handleSetTreatmentTypeChoice = (event, option) => {
    setSelectedTreatmentTypeItem(option);
    const treatmentType = {
      is_pagination: false,
      search_treatment_type_id_value: option.id,
    };
    dispatch(getWikiTreatmentList.pending(treatmentType));
    setDisableTreatment(false);
  };

  const handleSetMedicineTypeChoice = (event, option) => {
    setSelectedMedicineTypeItem(option);
    const medicineType = {
      is_pagination: false,
      search_medicine_type_id_value: option.id,
    };
    dispatch(getWikiMedicineList.pending(medicineType));
    setDisableMedicine(false);
  };

  const handleRowDel = (rowId, action) => {
    setData((data) => {
      const filterData = data[tabValue].filter((item) => item.id !== rowId);
      return { ...data, [tabValue]: filterData };
    });
  };

  const handleSelectedNodeItem = (event, newValue) => {
    event.preventDefault();
    const elementsIndex = tabValue;

    //make a copy of group option
    let oldArray = data;
    const selectedAcupoint = {
      id: newValue.id,
      name: newValue.name,
    };
    let acupointDetail;

    if (oldArray[elementsIndex]) {
      const repeatAcupoint = oldArray[elementsIndex].some(
        (item) =>
          item.id === newValue.id &&
          item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );

      if (!repeatAcupoint) {
        acupointDetail = [...oldArray[elementsIndex], selectedAcupoint];
        setData((item) => {
          return { ...item, [elementsIndex]: acupointDetail };
        });
      }
    }
  };
  //useEffects

  useEffect(() => {
    if (!temViewOnly) {
      dispatch(getWikiMedicineTypes.pending());
    }
  }, [temViewOnly]);

  useEffect(() => {
    const availableList = [];
    if (temViewOnly && data) {
      if (data.medicines?.length > 0) {
        availableList.push({
          name: t("translation:medicines"),
          key: "medicines",
        });
      }

      if (data.acupoints?.length > 0) {
        availableList.push({
          name: t("translation:acupoints"),
          key: "acupoints",
        });
      }

      if (data.treatments?.length > 0) {
        availableList.push({
          name: t("translation:treatments"),
          key: "treatments",
        });
      }

      if (data.review_notes?.length > 0) {
        availableList.push({
          name: t("translation:review notes"),
          key: "review_notes",
        });
      }

      if (data.tongue_diagnosis?.length > 0) {
        availableList.push({
          name: t("translation:tongue diagnosis"),
          key: "tongue_diagnosis",
        });
      }

      if (data.pulse_diagnosis?.length > 0) {
        availableList.push({
          name: t("translation:pulse diagnosis"),
          key: "pulse_diagnosis",
        });
      }

      if (data.physician_diagnosis?.length > 0) {
        availableList.push({
          name: t("translation:physician diagnosis"),
          key: "physician_diagnosis",
        });
      }

      if (data.syndrome?.length > 0) {
        availableList.push({
          name: t("translation:syndrome"),
          key: "syndrome",
        });
      }

      if (data.western_diagnosis?.length > 0) {
        availableList.push({
          name: t("translation:western diagnosis"),
          key: "western_diagnosis",
        });
      }

      if (data.physician_advice?.length > 0) {
        availableList.push({
          name: t("translation:physician advice"),
          key: "physician_advice",
        });
      }

      if (data.others?.length > 0) {
        availableList.push({
          name: t("translation:others"),
          key: "others",
        });
      }

      setNodeListType(availableList);
      if (availableList.length) {
        setTabValue(availableList[0].key);
      }
    }
  }, [data]);

  return (
    <>
      {(!temViewOnly || nodeListType.length > 0) && (
        <Panel>
          <Grid className={classes.root} container>
            <Box
              sx={{
                bgcolor: "background.paper",
                maxWidth: "100%",
              }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "rgba(149, 0, 0,1)",
                  },
                }}
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                  "& button.Mui-selected": {
                    color: "rgba(149, 0, 0,1)",
                  },
                }}>
                {nodeListType.map((item) => (
                  <Tab
                    key={item.key}
                    value={item.key}
                    label={item.name}
                    title={item.name}
                  />
                ))}
              </Tabs>
            </Box>

            {!temViewOnly && tabValue === "acupoints" && (
              <Grid item xs={12} className={classes.userInfoField}>
                <Autocomplete
                  disabled={false}
                  inputValue={selectedNodeItem}
                  value={selectedNodeItem == null ? "" : selectedNodeItem}
                  name="acupiont"
                  id="acupiont"
                  options={
                    wikiData.getWikiAcupointList?.acupoints
                      ? wikiData.getWikiAcupointList.acupoints.filter(
                          (item) =>
                            item.status_type ===
                            generalConstants.APPROVED_STATUS_TYPE
                        )
                      : []
                  }
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  style={{ width: "100%" }}
                  isOptionEqualToValue={() => {
                    return true;
                  }}
                  disableClearable
                  onChange={(e, option) => {
                    setSelectedNodeItem("");
                    handleSelectedNodeItem(e, option);
                  }}
                  disablePortal
                  sx={{
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                      '&[aria-selected="true"]': {
                        backgroundColor: "rgba(255,255,255)",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //clear the field to empty
                      onChange={(event) => {
                        setSelectedNodeItem(event.target.value);
                      }}
                      className={classes.userFieldColor}
                      label={<span>{t("translation:Acupoint")}</span>}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
            {!temViewOnly &&
              tabValue !== "acupoints" &&
              tabValue !== "medicines" &&
              tabValue !== "treatments" && (
                <Grid item xs={12} className={classes.userInfoField}>
                  <Autocomplete
                    disabled={false}
                    inputValue={selectedNodeItem}
                    value={selectedNodeItem == null ? "" : selectedNodeItem}
                    name="customNode"
                    id="customNode"
                    options={
                      wikiData.getWikiCustomWordList?.custom_words
                        ? wikiData.getWikiCustomWordList.custom_words.filter(
                            (item) =>
                              item.status_type ===
                              generalConstants.APPROVED_STATUS_TYPE
                          )
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={() => {
                      return true;
                    }}
                    disableClearable
                    onChange={(e, option) => {
                      setSelectedNodeItem("");
                      handleSelectedNodeItem(e, option);
                    }}
                    disablePortal
                    sx={{
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                        '&[aria-selected="true"]': {
                          backgroundColor: "rgba(255,255,255)",
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //clear the field to empty
                        onChange={(event) => {
                          setSelectedNodeItem(event.target.value);
                        }}
                        className={classes.userFieldColor}
                        label={<span>{t("translation:Custom Words")}</span>}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              )}
            {!temViewOnly && tabValue === "medicines" && (
              <>
                <Grid item xs={6} className={classes.userInfoField}>
                  <Autocomplete
                    value={selectedMedicineTypeItem}
                    disableClearable
                    name="medicine_types"
                    id="medicine_types"
                    options={
                      wikiData.getWikiMedicineTypes
                        ? wikiData.getWikiMedicineTypes.medicine_types.filter(
                            (item) =>
                              item.status_type ===
                              generalConstants.APPROVED_STATUS_TYPE
                          )
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, option) =>
                      handleSetMedicineTypeChoice(e, option)
                    }
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Medicine Type")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} className={classes.userInfoField}>
                  <Autocomplete
                    disabled={disableMedicine}
                    inputValue={selectedNodeItem}
                    value={selectedNodeItem == null ? "" : selectedNodeItem}
                    name="medicine"
                    id="medicine"
                    options={
                      wikiData.getWikiMedicineList?.medicines
                        ? wikiData.getWikiMedicineList.medicines.filter(
                            (item) =>
                              item.status_type ===
                              generalConstants.APPROVED_STATUS_TYPE
                          )
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={() => {
                      return true;
                    }}
                    disableClearable
                    onChange={(e, option) => {
                      handleSelectedNodeItem(e, option);
                      setSelectedNodeItem("");
                    }}
                    disablePortal
                    sx={{
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                        '&[aria-selected="true"]': {
                          backgroundColor: "rgba(255,255,255)",
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //clear the field to empty
                        onChange={(event) => {
                          setSelectedNodeItem(event.target.value);
                        }}
                        className={classes.userFieldColor}
                        label={<span>{t("translation:Medicine")}</span>}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {!temViewOnly && tabValue === "treatments" && (
              <>
                {" "}
                <Grid item xs={6} className={classes.userInfoField}>
                  <Autocomplete
                    value={selectedTreatmentTypeItem}
                    disableClearable
                    name="treatment_types"
                    id="treatment_types"
                    options={
                      wikiData.getWikiTreatmentTypes
                        ? wikiData.getWikiTreatmentTypes.treatment_types.filter(
                            (item) =>
                              item.status_type ===
                              generalConstants.APPROVED_STATUS_TYPE
                          )
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, option) =>
                      handleSetTreatmentTypeChoice(e, option)
                    }
                    renderInput={(params) => (
                      <TextField
                        className={classes.userFieldColor}
                        {...params}
                        label={t("translation:Treatment Type")}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} className={classes.userInfoField}>
                  <Autocomplete
                    disabled={disableTreatment}
                    inputValue={selectedNodeItem}
                    value={selectedNodeItem == null ? "" : selectedNodeItem}
                    name="treatment"
                    id="treatment"
                    options={
                      wikiData.getWikiTreatmentList?.treatments
                        ? wikiData.getWikiTreatmentList.treatments.filter(
                            (item) =>
                              item.status_type ===
                              generalConstants.APPROVED_STATUS_TYPE
                          )
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    style={{ width: "100%" }}
                    isOptionEqualToValue={() => {
                      return true;
                    }}
                    disableClearable
                    onChange={(e, option) => {
                      handleSelectedNodeItem(e, option);
                      setSelectedNodeItem("");
                    }}
                    disablePortal
                    sx={{
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option ": {
                        '&[aria-selected="true"]': {
                          backgroundColor: "rgba(255,255,255)",
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        //clear the field to empty
                        onChange={(event) => {
                          setSelectedNodeItem(event.target.value);
                        }}
                        className={classes.userFieldColor}
                        label={<span>{t("translation:Treatment")}</span>}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {tabValue && data[tabValue]?.length > 0 && (
                <Table
                  columns={columns}
                  data={data[tabValue]}
                  updateMyData={setData}
                />
              )}
            </Grid>
          </Grid>
        </Panel>
      )}
    </>
  );
}
