//lib
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { Font } from "@react-pdf/renderer";
import { Button, Link } from "@mui/material";

import { Grid, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
// import IconButton from "@mui/material/IconButton";
// import Alert from "@mui/material/Alert";
// import Collapse from "@mui/material/Collapse";
// import CloseIcon from "@mui/icons-material/Close";
//action

import { getMedicineMeasureUnit } from "../../../actions/commonActions";

import { putStorePrintDate } from "../../../actions/workOrderActions";

//import { getOrganisationLogo } from "../../../actions/curUserActions";

import { postCreatePresignedUrlOrganisationLogo } from "../../../actions/cloudStorageActions";

//components
import StyledCheckbox from "../../../components/StyledCheckbox";
import Panel from "../../../components/Panel";
import ViewMedicineListTable from "./ViewMedicineListTable";
import * as generalConstants from "../../../_constants/generalConstants";

import Note from "./components/addOrder/Note";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import ViewAllianceTotalPrice from "./components/viewOrder/ViewAllianceTotalPrice";
import ViewBrewing from "./components/viewOrder/ViewBrewing";
import ViewDelivery from "./components/viewOrder/ViewDelivery";
import GroupOption from "./components/editOrder/GroupOption";
import GroupTotal from "./components/addOrder/GroupTotal";
import GroupMedicineInfo from "./components/addOrder/GroupMedicineInfo";
import { StaffInfoTable } from "./StaffInfoTable";
import RequiredNote from "../../../components/RequiredNote";
import { useStyles } from "../../../components/globalStyles";
import PatientPrescriptionPDF from "../../../components/PDF/PatientPrescriptionPDF";
import ViewSharedDelivery from "./components/viewOrder/ViewSharedDelivery";
import S3OrganisationLogo, {
  setPrintingOrganisationLogo,
} from "../../../components/functions/S3OrganisationLogo";
import ViewProceedDay from "./components/viewOrder/ViewProceedDay";

//style
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    "& > span": {
      width: "100%",
      backgroundColor: "rgba(240,240,240)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(240,240,240)",
    backgroundColor: "rgba(155,29,32,1)",
    borderRadius: "5px!important",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "rgba(240,240,240)",
      opacity: 1,
    },
    "&$selected": {
      color: "rgba(240,240,240)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(240,240,240)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const OrderDetailPage = ({
  setOrderDetailView,
  orderDetailInfo,
  setOrderDetailInfo = () => null,
  editDeliveryOrder = false,
  printPrescription = true,
}) => {
  //variables
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);

  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);
  useEffect(() => {
    loadFonts();
  }, [loadFonts]);

  const prevSubmitWorkOrderStatus = useRef();
  const prevSaveWorkOrderStatus = useRef();
  const prevSetPrintDateOnStatus = useRef();
  const common = useSelector((state) => state.common);

  //const curUser = useSelector((state) => state.curUser);
  const visitOrder = useSelector((state) => state.visitOrder);
  const workOrder = useSelector((state) => state.workOrder);
  const [showDelivery, setShowDelivery] = useState(true);

  const [showBrewing, setShowBrewing] = useState(true);
  const [isRawHerb, setIsRawHerb] = useState(false);
  const [TCMLogo, setTCMLOGO] = useState(null);
  // const [logoError, setLogoError] = useState(false);
  // const [logoErrorMsg, setLogoErrorMsg] = useState(null);
  const [print, setPrint] = React.useState(0);
  const [allowPrint, setAllowPrint] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //dont display deleted group as default
  const defaultMedicineGroup = orderDetailInfo.store_medicine_headers.find(
    (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
  );

  const [price, setPrice] = useState({
    deliveryFee: orderDetailInfo.delivery_total_price_for_costing
      ? orderDetailInfo.delivery_total_price_for_costing
      : 0,
    brewingFee: orderDetailInfo.brewing_total_price_for_costing
      ? orderDetailInfo.brewing_total_price_for_costing
      : 0,
    consultationFee: orderDetailInfo.consultation_total_price
      ? orderDetailInfo.consultation_total_price
      : 0,
    medicineTab: orderDetailInfo.medicine_total_price
      ? orderDetailInfo.medicine_total_price
      : 0,
    medicineFee: orderDetailInfo.medicine_price_for_costing
      ? orderDetailInfo.medicine_price_for_costing
      : 0,
    medicineGroupTotal: defaultMedicineGroup ? defaultMedicineGroup.price : 0,
    acupuncturePrice: orderDetailInfo.acupuncture_total_price
      ? orderDetailInfo.acupuncture_total_price
      : 0,
    treatmentPrice: orderDetailInfo.treatment_total_price
      ? orderDetailInfo.treatment_total_price
      : 0,
    miscPrice: orderDetailInfo.misc_total_price
      ? orderDetailInfo.misc_total_price
      : 0,
  });

  const [tabValue, setTabValues] = useState("0");
  const [group, setGroup] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.name : null
  );
  const [groupId, setGroupId] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.id : null
  );

  const [groupOption, setGroupOption] = useState(
    orderDetailInfo.store_medicine_headers
  );

  const [costingHeader, setCostingHeader] = React.useState(
    orderDetailInfo.store_medicine_costing_headers
  );

  const [medicineList, setMedicineList] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.store_medicine_details : []
  );

  //function

  const handleMedicineGroupInfo = (event, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? event.type === "blur"
          ? event.target.value.trim()
          : event.target.value
        : null,
    };

    setGroupOption(newArray);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValues(newTabValue);
  };

  const setPrintingLogo = () => {
    setTCMLOGO(null);
    // const organisationId = orderDetailInfo.organisation.id;

    // dispatch(getOrganisationLogo.pending(organisationId));
    //dispatch(getOrganisation.pending(organisationId));

    setPrint(new Date().valueOf());
    setAllowPrint(false);
    const submitJson = setPrintingOrganisationLogo(
      orderDetailInfo.organisation.codename,
      "organisation",
      orderDetailInfo.organisation.logo
    );
    dispatch(postCreatePresignedUrlOrganisationLogo.pending(submitJson));
  };

  const setPrintingDate = () => {
    dispatch(
      putStorePrintDate.pending({
        store_header: { id: orderDetailInfo.id },
      })
    );
  };

  const handleMedicineGroupNumInfo = (event, value) => {
    const elementsIndex = groupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
    //make a copy of group option
    let newArray = [...groupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setGroupOption(newArray);
  };

  //useMemo
  const groupOptionAll = () => {
    return groupOption;
  };

  const memoGroupOption = React.useMemo(groupOptionAll, [groupOption]);

  const selectedGroup = () => {
    return memoGroupOption.find(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroup = React.useMemo(selectedGroup, [
    memoGroupOption,
    group,
    groupId,
  ]);

  const selectedGroupIndex = () => {
    return memoGroupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroupIndex = React.useMemo(selectedGroupIndex, [
    memoGroupOption,
    group,
    groupId,
  ]);

  //useEffects
  useEffect(() => {
    prevSubmitWorkOrderStatus.current = false;
    prevSaveWorkOrderStatus.current = false;
    prevSetPrintDateOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      workOrder.isLoadingPutStorePrintDate !== prevSetPrintDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintDateOnStatus.current = workOrder.isLoadingPutStorePrintDate;

      if (
        workOrder.putStorePrintDateSuccess === true &&
        workOrder.isLoadingPutStorePrintDate === false
      ) {
        setAllowPrint(true);
        //setOrderDetailInfo(workOrder.putStorePrintDate.store_header);
      }
    }
  }, [workOrder, setOrderDetailInfo]);

  useEffect(() => {
    if (allowPrint) {
      setOrderDetailInfo(workOrder.putStorePrintDate.store_header);
    }
  }, [workOrder.putStorePrintDate, allowPrint, setOrderDetailInfo]);

  useEffect(() => {
    if (!common.medicineMeasureUnitList && !common.isLoadingMedicineMeasureUnit)
      dispatch(getMedicineMeasureUnit.pending());
  }, [
    dispatch,
    common.medicineMeasureUnitList,
    common.isLoadingMedicineMeasureUnit,
  ]);

  // useEffect(() => {
  //   if (curUser.OrganisationLogo) {
  //     setTCMLOGO(curUser.OrganisationLogo.data);
  //   } else if (curUser.error?.organisationLogoError) {
  //     setLogoError(true);
  //     if (
  //       curUser.error?.organisationLogoError === "Missing Organisation Logo"
  //     ) {
  //       setLogoErrorMsg(t("translation:Missing Organisation Logo"));
  //     } else {
  //       setLogoErrorMsg(curUser.error?.organisationLogoError);
  //     }
  //   } else {
  //     setTCMLOGO(null);
  //   }
  // }, [curUser, t]);

  return (
    <div>
      <S3OrganisationLogo
        organisationCode={orderDetailInfo.organisation.codename}
        folderPath="organisation"
        setPhotoURL={setTCMLOGO}
      />
      {/* <Collapse in={logoError && print ? true : false}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setLogoError(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {logoErrorMsg}
        </Alert>
      </Collapse> */}
      <Typography display="block" variant="h4">
        {orderDetailInfo.order_number}
      </Typography>
      <StaffInfoTable staff={orderDetailInfo} t={t} />
      <form>
        <br />
        <Panel>
          <Grid container>
            <Grid xs={12} item className={classes.userInfoField}>
              <TextField
                className={classes.userFieldColor}
                disabled
                value={orderDetailInfo.desc}
                name="order description"
                cy_data="storeOrderDescription"
                label={t("translation:Order Description")}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Panel>
        <Grid className={classes.gridRoot} container>
          <Grid item xs={12}>
            <TabContext value={tabValue}>
              <Grid item xs={12}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth">
                  <StyledTab
                    className={classes.defualtCursor}
                    value="0"
                    label={
                      <span>
                        <Typography display="block" variant="subtitle1">
                          {t("translation:MEDICINE")}
                        </Typography>

                        <Typography display="block" variant="subtitle1">
                          $ {price.medicineTab}
                        </Typography>
                      </span>
                    }></StyledTab>
                </StyledTabs>
              </Grid>

              <TabPanel className={classes.gridRoot} value="0">
                <Panel>
                  <GroupOption
                    viewOnly={true}
                    memoGroupOption={memoGroupOption}
                    groupOption={groupOption}
                    setGroupOption={setGroupOption}
                    group={group}
                    setGroup={setGroup}
                    memoSelectedGroupIndex={memoSelectedGroupIndex}
                    medicineList={medicineList}
                    setMedicineList={setMedicineList}
                    memoSelectedGroup={memoSelectedGroup}
                    setShowBrewing={setShowBrewing}
                    setShowDelivery={setShowDelivery}
                    setPrice={setPrice}
                    showDelivery={showDelivery}
                    showBrewing={showBrewing}
                    setIsRawHerb={setIsRawHerb}
                    setGroupId={setGroupId}
                    groupId={groupId}
                    order={visitOrder}
                  />
                </Panel>
                {Object.keys(medicineList).length !== 0 &&
                  common.medicineMeasureUnitList && (
                    <div>
                      <Panel>
                        <Grid container>
                          {orderDetailInfo.status_type !==
                            generalConstants.NEW_STATUS_TYPE &&
                            printPrescription && (
                              <Link
                                onClick={() => {
                                  setPrintingLogo();
                                }}
                                className={classes.boldMaroon075}>
                                <Button className={classes.boldMaroon075}>
                                  {t("translation:PRINT PRESCRIPTION")}
                                </Button>

                                {pdfFontLoaded &&
                                  TCMLogo &&
                                  print !== 0 &&
                                  common.medicineMeasureUnitList && (
                                    <PatientPrescriptionPDF
                                      storeMedicineHeader={memoSelectedGroup}
                                      storeHeader={orderDetailInfo}
                                      t={t}
                                      measureUnit={
                                        common.medicineMeasureUnitList
                                          .measure_units
                                      }
                                      TCMLogo={TCMLogo}
                                      print={print}
                                      setPrintingDate={setPrintingDate}
                                      allowPrint={allowPrint}
                                      setAllowPrint={setAllowPrint}
                                    />
                                  )}
                              </Link>
                            )}
                          <Grid item xs={12} className={classes.userInfoField}>
                            <ViewMedicineListTable
                              t={t}
                              med_details_price_rmt={
                                orderDetailInfo.med_details_price_rmt
                              }
                              med_details_price_rpvt={
                                orderDetailInfo.med_details_price_rpvt
                              }
                              data={medicineList.sort(
                                (a, b) => a.row_no - b.row_no
                              )}
                              isRawHerb={isRawHerb}
                              costingHeader={costingHeader}
                              memoSelectedGroup={memoSelectedGroup}
                              measureUnit={
                                common.medicineMeasureUnitList.measure_units
                              }
                              infoModule="STORE"
                            />
                          </Grid>

                          <GroupMedicineInfo
                            viewOnly={true}
                            memoGroupOption={memoGroupOption}
                            handleMedicineGroupNumInfo={
                              handleMedicineGroupNumInfo
                            }
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            setGroupOption={setGroupOption}
                          />
                        </Grid>
                        <GroupTotal
                          viewOnly={true}
                          handleMedicineGroupNumInfo={
                            handleMedicineGroupNumInfo
                          }
                          price={price}
                          memoSelectedGroup={memoSelectedGroup}
                          showMultiplier={false}
                        />
                      </Panel>
                      {(memoSelectedGroup.is_brewing_services ||
                        memoSelectedGroup.is_delivery_services ||
                        memoSelectedGroup.delivery_incl_prescription ||
                        memoSelectedGroup.delivery_incl_receipt ||
                        memoSelectedGroup.delivery_owned?.status_type ===
                          generalConstants.APPROVED_STATUS_TYPE) && (
                        <Panel>
                          {memoSelectedGroup.delivery_incl_prescription && (
                            <Grid
                              item
                              xs={12}
                              className={classes.userInfoField}>
                              <FormControlLabel
                                control={
                                  <StyledCheckbox
                                    checked={
                                      memoSelectedGroup.delivery_incl_prescription
                                        ? true
                                        : false
                                    }
                                    disabled
                                    name="delivery_incl_prescription"
                                    cy_data="deliveryInclPrescription"
                                  />
                                }
                                label={t(
                                  "translation:Delivery Include Prescription"
                                )}
                              />
                            </Grid>
                          )}
                          {memoSelectedGroup.delivery_incl_receipt && (
                            <Grid
                              item
                              xs={12}
                              className={classes.userInfoField}>
                              <FormControlLabel
                                control={
                                  <StyledCheckbox
                                    checked={
                                      memoSelectedGroup.delivery_incl_receipt
                                        ? true
                                        : false
                                    }
                                    disabled
                                    name="delivery_incl_receipt"
                                    cy_data="deliveryInclInvoice"
                                  />
                                }
                                label={t(
                                  "translation:Delivery Include Invoice"
                                )}
                              />
                            </Grid>
                          )}
                          {memoSelectedGroup.is_brewing_services && (
                            <ViewBrewing
                              memoSelectedGroup={memoSelectedGroup}
                            />
                          )}

                          {memoSelectedGroup.is_delivery_services && (
                            <ViewDelivery
                              memoSelectedGroup={memoSelectedGroup}
                              orderDetailInfo={orderDetailInfo}
                              setGroupOption={setGroupOption}
                              editDeliveryOrder={editDeliveryOrder}
                            />
                          )}

                          {memoSelectedGroup.delivery_owned?.status_type ===
                            generalConstants.APPROVED_STATUS_TYPE && (
                            <ViewSharedDelivery
                              memoSelectedGroup={memoSelectedGroup}
                              viemOnly={true}
                            />
                          )}
                        </Panel>
                      )}
                    </div>
                  )}
                {memoSelectedGroup?.proceed_on !== null && (
                  <Panel>
                    <ViewProceedDay memoSelectedGroup={memoSelectedGroup} />
                  </Panel>
                )}
                <ViewAllianceTotalPrice
                  memoGroupOption={memoGroupOption}
                  costingHeader={costingHeader}
                  setCostingHeader={setCostingHeader}
                  currentOrganistion={orderDetailInfo.organisation}
                  price={price}
                  price_rmt={2}
                  price_rpvt={5}
                />
              </TabPanel>
            </TabContext>
          </Grid>
          <Note groupPrice={true} />
          <RequiredNote />
          <PageOnlyBackAction setView={() => setOrderDetailView(false)} />
        </Grid>
      </form>
    </div>
  );
};

export default OrderDetailPage;
