import { createAsyncTypes } from "../_helper/Utilities";

export const GET_NATIONALITY_ASYNC = createAsyncTypes("GET_NATIONALITY");

export const GET_RACE_ASYNC = createAsyncTypes("GET_RACE");

export const GET_GENDER_ASYNC = createAsyncTypes("GET_GENDER");

export const GET_MARITAL_ASYNC = createAsyncTypes("GET_MARITAL");

export const GET_RIGHTS_ASYNC = createAsyncTypes("GET_RIGHTS");

export const GET_PREMISSION_ASYNC = createAsyncTypes("GET_PREMISSION");

export const GET_VIEWNEEDS_ASYNC = createAsyncTypes("GET_VIEWNEEDS");

export const GET_LANGUAGE_ASYNC = createAsyncTypes("GET_LANGUAGE");

export const GET_MEDICINECONSUMETIME_ASYNC = createAsyncTypes(
  "GET_MEDICINECONSUMETIME"
);

export const GET_MEDICINEMEASUREUNIT_ASYNC = createAsyncTypes(
  "GET_MEDICINEMEASUREUNIT"
);

export const GET_GENERALSTATUS_ASYNC = createAsyncTypes("GET_GENERALSTATUS");

export const GET_WATERAMOUNT_ASYNC = createAsyncTypes("GET_WATERAMOUNT");

export const GET_PAYMENTMETHOD_ASYNC = createAsyncTypes("GET_PAYMENTMETHOD");

export const GET_PAYMENTTERM_ASYNC = createAsyncTypes("GET_PAYMENTTERM");

export const GET_USERCREDITTYPE_ASYNC = createAsyncTypes("GET_USERCREDITTYPE");

export const GET_INOUTTYPE_ASYNC = createAsyncTypes("GET_INOUTTYPE");

export const GET_SYSTEMSETTINGS_ASYNC = createAsyncTypes("GET_SYSTEMSETTINGS");

export const GET_MFATYPE_ASYNC = createAsyncTypes("GET_MFATYPE");

export const GET_WORKORDERTYPE_ASYNC = createAsyncTypes("GET_WORKORDERTYPE");

export const GET_POSTALCODEDISTRICT_ASYNC = createAsyncTypes(
  "GET_POSTALCODEDISTRICT"
);
